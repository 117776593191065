import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import '../styles/RequiredCandidatesForm.css';
import InfoIcon from '../../../static/svg/InfoIcon';
import { getSamvaadiniSummaryInformation } from '../../../service/SamvadiniApiService';

function RequiredCandidatesForm({goToNextScreen, requiredCandidateCount, setRequiredCandidateCount, resetSteps, mitraReducer}) {
    const [disableSubmission, setDisableSubmission] = useState(true);
    const [noEligibleLeads, setNoEligibleLeads] = useState(false);

    const requiredCandidateCountValidation = (inputValue) => {
        if(!inputValue || !inputValue.length) {
            setRequiredCandidateCount(null);
            setDisableSubmission(true);
            return;
        }

        // Check if the input value is numeric using a simple regex
        if (/^\d*$/.test(inputValue)) {
            setRequiredCandidateCount(inputValue);


            if(parseInt(inputValue) < 5 || parseInt(inputValue) >200) {
                setDisableSubmission(true);
            } else {
                setDisableSubmission(false)
            }
        }
    }

    const onSearchTermChange = (event) => {
        const inputValue = event.target.value;
        requiredCandidateCountValidation(inputValue);

        //resetting the steps if input value is changed
        // doing this because the user can reset the number to something that is not acceptable
        resetSteps();
    }

    useEffect(() => {
        requiredCandidateCountValidation(requiredCandidateCount);
        getSummaryInfo();
    }, [])

    useEffect(() => {
        if(mitraReducer?.mitraInfo?.id) {
            getSummaryInfo();
        }
    }, [mitraReducer?.mitraInfo?.id])

    const getSummaryInfo = async()=> {

        try {
            const formData = new FormData();
            formData.append('expectedLeadCount', 6);
            formData.append('type', 'Smart Select');
            formData.append('mitraId', mitraReducer?.mitraInfo?.id)
            formData.append('pitchClient', 'default')
            formData.append('isManagerMitra', mitraReducer?.mitraInfo?.managerMitraID ? false: true)

            const preIcsInfo = await getSamvaadiniSummaryInformation(formData);

            if(preIcsInfo?.data?.status) {
                if(!preIcsInfo?.data?.leadsEligible) {
                    setNoEligibleLeads(true);
                } else {
                    setNoEligibleLeads(false);
                }
            } else {
                setNoEligibleLeads(true);
            }

        } catch(err) {
            setNoEligibleLeads(false);
            console.log(err);
        }
        
    }
    

  return (
    <>
        <div className="step-info-header">Interested Leads</div>
        <div className="step-info-sub-header">Select how many interested leads you want</div>

        <div className="upload-container shadow-highlighter">
            <div className="upload-file-main-section d-flex justify-content-between flex-wrap mob-column-reverse" style={{padding: '44px',flexDirection: 'row'}}>
                <div className='d-flex flex-column align-items-start require-candidate-input-section' style={{flex: 3}}>
                    <span className='inter-semibold-24-28 color-252526 text-align-start mob-font18'>How many interested leads do you want?</span>
                    <span className='inter-regular-12-16 color-838383 mt-12'>Choose a number from within the range of 5 to 200 only</span>
                    <Input 
                    onChange={onSearchTermChange}
                    className='samvaadini-search-candidate-new mt-24' 
                    placeholder="e.g 188" 
                    value={requiredCandidateCount}
                    />

                    {
                        noEligibleLeads ? (
                            <div className='d-flex align-items-center mt-2' style={{gap: '4px'}}>
                                <InfoIcon strokeColor='#FE4848' height={16} width={16}/>
                                <span className='inter-medium-10-20' style={{color:'#FE4848'}}>No eligible leads, please refer leads to continue.</span>
                            </div>
                        ): null
                    }
                    
                    
                    <Button 
                    onClick={()=>goToNextScreen('script')} 
                    disabled={disableSubmission || noEligibleLeads}
                    className={`btn-44h btn-width-174 mt-32 required-candidate-submit-btn ${disableSubmission || noEligibleLeads ? 'disabled-btn-v2':'primary-btn-styles-v2'}`}>
                        <span className={`inter-regular-16-24 ${disableSubmission || noEligibleLeads ? 'color-848484':'plain-white'}`}>Enter</span>
                    </Button>
                </div>
                <div className='required-candidate-image-section' style={{flex: 1}}><img className='required-candidate-image' src="/image/smart-logic.png" style={{height:'200px',width:'285px'}}/></div>
            </div>
        </div>
    </>
  )
}

export default RequiredCandidatesForm