import React, { useState, useEffect, useContext, useReducer, useRef } from 'react'
import '../../style/BulkReferralStyle.scss'
import CONSTANTS from '../../../../constants/constants';
import { Select, Divider, Button, message, DatePicker, Space, Tag } from 'antd';
import { SearchOutlined, DeleteOutlined, ArrowRightOutlined } from '@ant-design/icons';
import TabularView from '../../../common/TabularView';
import { getBulkUniquenessList, getDistinctDateForBulkFeature, getScheduledIVRList, displayBulkUniqueStatus, recruiterDashboardCandidateCount, getIvrCampaignList, getIvrResponsesForCampaign, getWhatsappLeads, bulkWhatsappUpload } from '../../../../service/bulkActionService'
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../App';
import moment from 'moment';
import { getActiveClientsForFilter, disabledDateForBulkFeature, fetchActivatedAndReferredLeads, updateLeadsBasedEvents, additionalMitraInfo, checkBronzeML } from '../../../../service/commonService'
import { getDisplayableClientName } from '../../../../utils/commonFunction';
import SideSheet from "../../../common/SideSheet";
import '../../../../css/home.scss'
import '../BulkIVR/BulkIVRStyling.scss'
import { downloadBulkWhatsappMessage } from '../../../../service/bulkActionService';
import DownloadFilePopup from '../DownloadFilePopup';

const BulkWhatsAppContainer = ({ }) => {
    const DEFAULT = 'DEFAULT';
    const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
    const [ivrLeads, setIVRLeads] = useState([])
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [bulkStatusesSelected, setBulkStatusesSelected] = useState([
        { label: 'Pending', value: false, backendValue: 'pendingStatus' },
        { label: 'Unique', value: false, backendValue: 'uniqueStatus' },
        { label: 'Not Unique', value: false, backendValue: 'nonUniqueStatus' },
        { label: 'Application Created', value: false, backendValue: 'completeApplication' },
        { label: 'Incomplete Application', value: false, backendValue: 'incompleteApplication' }
    ]);
    const [bulkIVRStatusesSelected, setBulkIVRStatusesSelected] = useState([
        { label: 'Call Connected', value: false, backendValue: 'Completed' },
        { label: 'Call Disconnected(Hangup)', value: false, backendValue: 'Hangup' },
        { label: 'IVR Scheduled(Queued)', value: false, backendValue: 'Initiated' },
        { label: 'IVR Scheduled', value: false, backendValue: 'Created' },
        { label: 'Call Disconnected(DND)', value: false, backendValue: 'DND User' },
        { label: 'Call Disconnected(Failed)', value: false, backendValue: 'Failed' },
        { label: 'No Answer', value: false, backendValue: 'No Answer' },
        { label: 'Rejected', value: false, backendValue: 'Rejected' },
        { label: 'Other', value: false, backendValue: 'Other' },
        { label: 'Busy', value: false, backendValue: 'Busy' },
    ])
    const [bulkResponseSelected, setBulkResponseSelected] = useState([])
    const statusColorMapping = {
        "Call Connected": "rgb(75 170 90)",
        "Call Disconnected(Hangup)": "rgb(168 35 35)",
        "IVR Scheduled(Queued)": "rgb(32 184 170)",
        "IVR Scheduled": '#000',
        "Call Disconnected(DND)": 'rgb(255 0 0)',
        "Call Disconnected(Failed)": 'rgb(244 116 55)',
        "No Answer": 'rgb(146 124 247)',
        "Rejected": 'rgb(251 18 18);',
        "Busy": 'red'
    };
    const history = useHistory();
    const [showDateChip, setShowDateChip] = useState(false)
    const [showCategoryFilterPopup, setCategoryShowFilterPopup] = useState(false);
    const [showStatusFilterPopup, setStatusShowFilterPopup] = useState(false);
    const [showIVRStatusFilterPopup, setIVRStatusShowFilterPopup] = useState(false);
    const [showResponseFilterPopup, setResponseShowFilterPopup] = useState(false);
    const [filterSider, setfilterSider] = useState(false);
    const [bulkIvrCampaignList, setBulkIvrCampaignList] = useState([]);
    const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prevLeads, setPrevLeads] = useState([]);
    const [IvrCampaignSelected, setIvrCampaignSelected] = useState('');
    const [clientSelected, setClientSelcted] = useState([])
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [setBulkWhatsappMessages, setWhatsappMessages] = useState([
        { label: 'Hot Leads', value: false, backendValue: 'hot_leads' },
        { label: 'Cold Leads', value: false, backendValue: 'cold_leads' },

    ]);
    const [type, setType] = useState("date");
    const [availableDateList, setAvailableDateList] = useState([]);
    const [onClearTrigger, setonClearTrigger] = useState({})
    const [bulkWhatsappSelected, setBulkWhatsappSelected] = useState([])
    const [changeEmail, setChangeEmail] = useState(false);
    const [userModifiedEmail, setUserModifiedEmail] = useState("");

    // useEffect(() => {
    //     getIvrResponsesForCampaign({ campaign: IvrCampaignSelected }).then((response) => {
    //         setBulkResponseSelected(response.data.data);
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }, [IvrCampaignSelected]);

    useEffect(() => {
        handleBulkApis()
        getDistinctDateForBulkFeature({ feature: 'Bulk Whatsapp Message' }).then((response) => {
            if (response.data.data) {
                setAvailableDateList([...response.data.data, moment().format('YYYY-MM-DD')])
            }
        }
        ).catch((error) => {
            console.log(error);
        })
    }, [])

    const mapIVRStatus = (status) => {
        let return_value;
        bulkIVRStatusesSelected.map(ele => {
            if (status == ele.backendValue) {
                return_value = ele.label
            }
        })
        return return_value
    }

    const handleBulkApis = async () => {
        setLoading(true)
        let clientArray = [], statusArray = [], IVRStatusArray = [], responseArray = [], filterArray = [];
        clientSelected.map(ele => {
            if (ele.value) {
                clientArray.push(ele.key.toLowerCase())
            }
        })
        bulkStatusesSelected.map(ele => {
            if (ele.value) {
                statusArray.push(ele.backendValue)
            }
        })
        bulkIVRStatusesSelected.map(ele => {
            if (ele.value) {
                IVRStatusArray.push(ele.label)
            }
        })
        bulkResponseSelected.map(ele => {
            if (ele.value) {
                responseArray.push(ele.backendValue)
            }
        })
        setBulkWhatsappMessages.map(ele => {
            if (ele.value) {
                filterArray.push(ele.backendValue)
            }
        })
        if (mitraReducer?.mitraInfo) {
            const data = {
                startDate: moment(startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
                client: JSON.stringify(clientArray),
                status: JSON.stringify(statusArray),
                response: JSON.stringify(responseArray),
                mitraManagerId: mitraReducer.mitraInfo.id,
                whatsappStatus: JSON.stringify(filterArray)
            }
            data.status = JSON.stringify(IVRStatusArray);
            await getWhatsappLeads(data).then((response) => {
                if (response?.data?.data) {
                    setBulkWhatsappSelected(response.data.data)
                }
                else {
                    message.error("Something went wrong, please try again.")
                }
            }).catch(e => {
                // message.error(e);
            })
            setLoading(false)
        }
    }

    const handleIvrCampaignChange = (e) => {
        setIvrCampaignSelected(e.target.value);
    }

    const onClickDownloadFile = () => {
        setShowVerifyEmailPopup(true);
    }

    const deleteChip = (name, item) => {
        if (name === 'response') {
            const currenArray = [...bulkResponseSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkResponseSelected(currenArray)
        }
        else if (name === 'IVRStatus') {
            const currenArray = [...bulkIVRStatusesSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkIVRStatusesSelected(currenArray)
        }
        else if (name === 'status') {
            const currenArray = [...bulkStatusesSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkStatusesSelected(currenArray)
        }
        else if (name === 'client') {
            const currenArray = [...clientSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    console.log(name, item)
                    ele.value = false;
                }
            })
            setClientSelcted(currenArray)
        }
        else if (name == 'Bulk_Whatsapp') {
            const currenArray = [...setBulkWhatsappMessages]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setWhatsappMessages(setBulkWhatsappMessages)
        }
    }

    const commonTag = (label, arrObj) => {
        return <div className='tag-containers'>
            <div>{label}</div>
            {label === "Date" ?
                <Space>
                    <DatePicker
                        size="small"
                        picker={type}
                        disabledDate={(current) => { return disabledDateForBulkFeature(current, availableDateList) }}
                        onChange={handleDateChange}
                        defaultValue={startDate}
                        value={startDate}
                        allowClear={false} />
                </Space> :
                <div className='tag-wrapper-container'>
                    {arrObj.map((item, index) => {
                        return <Tag className={!item.value ? 'tags' : 'tags tag-active'}
                            key={index}
                            // closable={item.value}
                            onClick={() => onClickTags(label, index)}
                        >
                            {label === "Clients" ? getDisplayableClientName(item.label) : item.label}
                        </Tag>
                    })}
                </div>
            }

        </div>
    }

    const handleDateChange = (date) => {
        if (date === null) {
            setStartDate(moment()).startOf('day');
            setEndDate(moment()).endOf('day');
        }
        else {
            setStartDate(moment(date).startOf('day'));
            setEndDate(moment(date).endOf('day'));
        }
    }

    const onClickTags = (label, index) => {
        if (label === 'Status') {
            bulkStatusesSelected[index].value = !bulkStatusesSelected[index].value;
        }
        if (label === 'Clients') {
            clientSelected[index].value = !clientSelected[index].value;
        }
        if (label === 'IVR Status') {
            bulkIVRStatusesSelected[index].value = !bulkIVRStatusesSelected[index].value;
        }
        if (label === 'Response') {
            bulkResponseSelected[index].value = !bulkResponseSelected[index].value;
        }
        if (label == 'Bulk Message') {
            setBulkWhatsappMessages[index].value = !setBulkWhatsappMessages[index].value;
        }
        forceUpdate()
    }

    const clearAll = async () => {
        await bulkIVRStatusesSelected.map(item => {
            item.value = false;
        })
        await bulkResponseSelected.map(item => {
            item.value = false;
        })
        setStartDate(moment())
        setEndDate(moment())
        setfilterSider(false)
        setonClearTrigger(!onClearTrigger);
    }

    const downloadSheet = async (email) => {
        setShowVerifyEmailPopup(true);
    }

    const onClickDownload = (email) => {
        let clientArray = [], statusArray = [], IVRStatusArray = [], responseArray = []; let filterArray = [];
        clientSelected.map(ele => {
            if (ele.value) {
                clientArray.push(ele.key.toLowerCase())
            }
        })
        bulkStatusesSelected.map(ele => {
            if (ele.value) {
                statusArray.push(ele.backendValue)
            }
        })
        bulkIVRStatusesSelected.map(ele => {
            if (ele.value) {
                IVRStatusArray.push(ele.label)
            }
        })
        bulkResponseSelected.map(ele => {
            if (ele.value) {
                responseArray.push(ele.backendValue)
            }
        })
        setBulkWhatsappMessages.map(ele => {
            if (ele.value) {
                filterArray.push(ele.backendValue)
            }
        })
        const data = {
            startDate: moment(startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
            whatsappStatus: JSON.stringify(filterArray),
            email: email,
            changeEmail: changeEmail
        }
        downloadBulkWhatsappMessage(data).then((response) => {
            setAlert({
                display: true,
                text: response.data.message,
                type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
            });
        })
            .catch((error) => {
                console.log(error);
            })

    }

    return (
        <div className='bulk-ivr-parent'>
            <TabularView selection={3}
                ivrLeads={ivrLeads}
                mitraDispatch={mitraDispatch}
                startDate={startDate} endDate={endDate}
                mitraReducer={mitraReducer}
                bulkResponseSelected={bulkResponseSelected}
                statusColorMapping={statusColorMapping}
                history={history}
                setPrevLeads={setPrevLeads}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setShowDateChip={setShowDateChip}
                setCategoryShowFilterPopup={setCategoryShowFilterPopup}
                setStatusShowFilterPopup={setStatusShowFilterPopup}
                setResponseShowFilterPopup={setResponseShowFilterPopup}
                loading={!loading}
                deleteChip={deleteChip}
                downloadSheet={downloadSheet}
                setfilterSider={setfilterSider}
                handleBulkApis={handleBulkApis}
                showBanner={false}
                setBulkWhatsappMessages={setBulkWhatsappMessages}
                bulkWhatsappSelected={bulkWhatsappSelected}
            />

            {filterSider &&
                <SideSheet submit={{ disabled: false, onClick: handleBulkApis }}
                    clearAll={{ disabled: false, onClick: clearAll }}
                    sidername={"Filter Leads"}
                    onDismiss={() => { setfilterSider(false) }}>
                    <div className='filter-home-wrapper'>
                        {commonTag("Date", null)}
                        {commonTag("Bulk Message", setBulkWhatsappMessages)}
                    </div>
                </SideSheet>
            }
            {
                showVerifyEmailPopup &&
                (
                    <DownloadFilePopup mitra={mitraReducer?.mitraInfo} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={onClickDownload} changeEmail={changeEmail} setChangeEmail={setChangeEmail} addedEmail={(inp) => setUserModifiedEmail(inp)} />
                )
            }
        </div>
    )
}

export default BulkWhatsAppContainer; 