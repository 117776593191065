import axios from 'axios';
import axiosInstance from './interceptorService';

export const getDistinctDateForBulkFeature = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getDistinctDateForBulkFeature`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};


export const getBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/listBulkUploadCandidates`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};

export const uploadBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/postBulkUniquenessData`;
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getpopularCities = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/form-io/getPremiumJobsCities`;
    return axiosInstance.get(url, { withCredentials: true });
};
export const bulkIVRUpload = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/bulkIVRUpload`;
    return axiosInstance.post(url, data, { withCredentials: true });
}
export const bulkWhatsappUpload = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/bulkWhatsappUpload`;
    return axiosInstance.post(url, data, { withCredentials: true });
}
export const getAudioFile = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getIVRCallAudio`;
    return axiosInstance.get(url, { withCredentials: true, params: data });
}
export const getIvrCampaignList = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getBulkIVRCampaignListForMitra`;
    return axiosInstance.get(url, { withCredentials: true });
}
export const getIvrResponsesForCampaign = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getIvrResponsesForCampaign`;
    return axiosInstance.get(url, { withCredentials: true, params: data });
}

export const getWhatsappLeads = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getBulkWhatsapLeads`;
    return axiosInstance.get(url, { withCredentials: true, params: data });

}

export const getScheduledIVRList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getScheduledIVRCallsByMitra`;
    return axiosInstance.get(url, { withCredentials: true, params: data });
}

export const displayBulkUniqueStatus = (status, companyName, scheduledInterviewId) => {
    if (status) {
        if (companyName.toLowerCase().indexOf('shadowfax') !== -1) {
            if (status.toLowerCase() === 'created') {
                return 'Unique'
            }
        }
        else if (status.toLowerCase() === 'uploaded') {
            return 'Unique'
        }
        if (status.toLowerCase() === 'not uploaded') {
            return 'Not Unique'
        }
    }
    if ((companyName.toLowerCase().indexOf('shadowfax') !== -1 || companyName.toLowerCase() === 'zomato') && (scheduledInterviewId)) {
        return 'Pending'
    }
    if (scheduledInterviewId) {
        return "Application Created"
    }
    else {
        return 'Incomplete Application'
    }
}

export const getClientsForBulkReferral = async (city) => {
    const data = new FormData();
    data.append('city', city);
    let url = process.env.REACT_APP_BACKEND_URL + '/getClientsAndCompaniesForBulkUpload';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const uploadReferralList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/postBulkReferralData`;
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const uploadBulkListWithoutReferral = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/saveBulkLeadWOReferral`;
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getBulkUploadDataWOReferral = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/bulkuploads/withoutReferral`;
    return axiosInstance.get(url, { withCredentials: true, params: data });
};

export const bulkListAndSummaryAPI = async (pageNo, startDate, endDate, filteredProcessStatus, filteredClients, bulkUploadUniquenessLeadEntry, cancelToken) => {
    let urlWithParams = `/bulkUploadList?startDate=${startDate}&endDate=${endDate}&pageNo=${pageNo}`
    if (bulkUploadUniquenessLeadEntry) {
        urlWithParams += `&bulkUploadUniquenessLeadEntry=${bulkUploadUniquenessLeadEntry}`
    }
    if (filteredProcessStatus && filteredProcessStatus.key) {
        urlWithParams += `&processStatus=${filteredProcessStatus.key}`
    }
    if (filteredClients && filteredClients !== "[]") {
        urlWithParams += `&clients=${filteredClients}`
    }
    // urlWithParams += bulkUploadUniquenessLeadEntry ? `&bulkUploadUniquenessLeadEntry=${bulkUploadUniquenessLeadEntry}` : ""
    let url = `${process.env.REACT_APP_BACKEND_URL}${urlWithParams}`;
    return axiosInstance.get(decodeURI(url), { withCredentials: true, cancelToken });
};

export const downloadAllBulkUploads = async (data) => {
    let urlWithParams = `/download/bulkUploadList`
    // urlWithParams += bulkUploadUniquenessLeadEntry ? `&bulkUploadUniquenessLeadEntry=${bulkUploadUniquenessLeadEntry}` : ""
    let url = `${process.env.REACT_APP_BACKEND_URL}${urlWithParams}`;
    return axiosInstance.post(decodeURI(url), data, { withCredentials: true });
};

export const recruiterDashboardCandidateCount = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/recruiterDashboardCandidateCount`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const downloadBulkIvrResponses = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/bulkIvrLeadsWithResponse`;
    return axiosInstance.get(url, { withCredentials: true, params: data });
}

export const downloadBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/listBulkUploadCandidates`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};

export const downloadBulkWhatsappMessage = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/bulkWhatsappMessage`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};
