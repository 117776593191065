import React, {useContext, useState} from 'react';
import {AppContext} from '../../App';
import './../../css/telecallerDashboard.scss';
import PageHeaderComponent from '../common/PageHeader';

const TelecallerDashboard = () => {
    const {mitraReducer } = useContext(AppContext);
	const [breadcrumb, setbreadcrumb] = useState([{link: '/recruiter-candidates', label: 'My Follow Up Leads'},{link: 'recruiter-candidates', label: 'Telecaller Dashboard'}])
  
  return (
    <div style={{}}>
        <div className='header'>
            <PageHeaderComponent breadcrumb={breadcrumb} backBtnBool={true}/>
        </div>
        {
            mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.id &&
            <>
                <div className='telecaller-dashboard-desktop'>
                    <iframe src={`${process.env.REACT_APP_TC_DASHBOARD_DESKTOP}?embed=true&logedInMitraId=${mitraReducer?.mitraInfo.id}`} style={{display: 'block', width: '100%', height: '100vh', marginLeft: '20px'}} ></iframe>
                </div>
                <div className='telecaller-dashboard-mobile'>
                    <iframe src={`${process.env.REACT_APP_TC_DASHBOARD_MOBILE}?embed=true&logedInMitraId=${mitraReducer?.mitraInfo.id}`} style={{display: 'block', width: '100%', height: '100vh'}} ></iframe>
                </div>          
            </>  
        };
    </div>
  );
};
export default TelecallerDashboard;
