import React, { useContext, useEffect, useState } from 'react'
import './HotlineLandingScreen.css'
import ShieldTick from '../../static/svg/ShieldTick'
import { Button, Divider } from 'antd'
import { getHotlineAppUrl } from '../../service/commonService'
import axios from 'axios'
import LeftArrow from '../../static/svg/LeftArrow'
import InfoIcon from '../../static/svg/InfoIcon'
import { trackEvent } from '../../service/engagementMonitoringService'
import CONSTANTS from '../../constants/constants'
import { AppContext } from '../../App'

function HotlineLandingScreen() {
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadStarted, setDownloadStarted] = useState(false);
    const [isAppDownloaded, setIsAppDownloaded] = useState(false);
    const {mitraReducer} = useContext(AppContext);

    const downloadFile = (fileUrl) => {
        axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            const percentCompleted = Math.round((current / total) * 100);
            setDownloadProgress(percentCompleted);
          }
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Hotline.apk'); // Replace with your file name
          document.body.appendChild(link);
          link.click();
          link.remove();
          setDownloadStarted(false);
          setDownloadProgress(0);
          setIsAppDownloaded(true);
        })
        .catch(error => {
            setDownloadStarted(false);
            console.error('Error downloading the file', error)
        });
      };

    const getAppUrl = () => {
        setDownloadStarted(true);
        getHotlineAppUrl()
        .then(res => {
            if(res?.data?.data?.[0]?.attributes?.url) {
                downloadFile(res?.data?.data?.[0]?.attributes?.url);
                trackEvent('hotline_app_downloaded', {url: res?.data?.data?.[0]?.attributes?.url, phoneNumber: mitraReducer?.mitraInfo.phoneNumber, version: res?.data?.data?.[0]?.attributes?.versionNumber}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            }
        }).catch(err=> {
            setDownloadStarted(false);
            console.log(err);
        })
    }

    useEffect(() => {
        trackEvent('hotline_app_download_screen_visited', { phoneNumber: mitraReducer?.mitraInfo.phoneNumber }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }, [])
    
  return (
    <div className="hotline-download-container">
        {
            isAppDownloaded ? (
                <>
                    <div className="back-btn-mobile" style={{gap:'6px'}} onClick={()=>setIsAppDownloaded(false)}>
                        <LeftArrow />
                        <span className='back-text-styles'>Back</span>
                    </div>
                    <div className='post-download-instructions-container'>
                        <div className="back-button-section" style={{gap:'6px'}} onClick={()=>setIsAppDownloaded(false)}>
                            <LeftArrow />
                            <span className='back-text-styles'>Back</span>
                        </div>

                        <span className='install-unknown-apps'>Installing Unknown apps</span>

                        <img src='/image/chrome-icon.png' className='chrome-icon-size'/>

                        <span className='chrome-text'>Chrome</span>
                        <span className='chrome-version'>116.0.8848.115</span>

                        <div className="allow-from-source-container">
                            <span className='allow-from-source'>Allow from this Source</span>
                            <img src='/image/Toggle.png' className='toggle-image' />
                        </div>

                        <div className="detail-info-container">
                            <div style={{ marginBottom: '20px' }}>
                                <InfoIcon />
                            </div>

                            <span className='unknown-source-desc'>
                                Your phone and personal data are more vulnerable to attack by unknown apps. 
                                By installing apps from this source you agree that you are responsible for 
                                any damages to your phone or loss of data may result from their use.
                            </span>
                        </div>
                    </div>
                </>
        ): (
                <>
                    <div className="hotline-upper-section">
                        <div className="hero-left-section">
                            <img src="/image/hotline-call-icon.png" className='hotline-call-icon' />
                            <span className='highlighter-text'>2x</span>
                            <span className='highlighter-text' style={{marginTop: '20px'}}>BOOST</span>
                            <span className='daily-call-attempts'>Your daily call attempts</span>
                            <span className='hotline-description'><b>Use Hotline</b> to get more FODs and Onboarding a day. 100% Safe and Secure</span>
                            <div className="encryption-highlighting-container">
                                <ShieldTick />
                                <span>End to end encrypted information</span>
                            </div>
                            {
                                downloadStarted ? (
                                    <div className='download-hotline-btn download-bg-indicator'>
                                        <span className='download-hotline-text' style={{position:'relative', zIndex: '1'}}>{downloadProgress} %</span>
                                        <div className="download-hotline-download-indicator" style={{width:`${downloadProgress}%`,transition: 'width 0.1s ease-in-out'}}></div>
                                    </div>
                                ):(
                                    <Button onClick={getAppUrl} className='download-hotline-btn'><span className='download-hotline-text'>Download Now</span></Button>
                                )
                            }
                            
                        </div>
                        <div className="hero-right-section">
                            <img src='/image/hotline-hero.webp' className='hotline-hero-img' />
                        </div>
                    </div>

                    <div className="hotline-testimonial-section">
                        <div className="testimonial-header">Testimonials</div>
                        <Divider />
                        <div className="testimonial-content">
                            <div className="testimonial">
                                <img src="/image/priya.png" style={{width:'60px',objectFit:'contain'}} />
                                <div className="name-and-comment">
                                    <span className='testimonial-name'>Priya Sharma</span>
                                    <span className='testimonial-content-text'>&quot;Hotline app ne mere onboardings ko din mein double kar diya, ab kaam bohot easy ho gaya hai. &quot;</span>
                                </div>
                            </div>

                            <div className="testimonial">
                                <img src="/image/anjali.png" style={{width:'60px',objectFit:'contain'}} />
                                <div className="name-and-comment">
                                    <span className='testimonial-name'>Ritu Nair</span>
                                    <span className='testimonial-content-text'>&quot;Hotline app ke saath, onboardings fast aur efficient ho gayi hain. Mera kaam ab aasan ho gaya hai.&quot;</span>
                                </div>
                            </div>

                            <div className="testimonial">
                                <img src="/image/sneha.png" style={{width:'60px',objectFit:'contain'}} />
                                <div className="name-and-comment">
                                    <span className='testimonial-name'>Sneha Verma</span>
                                    <span className='testimonial-content-text'>&quot;Hotline app se daily onboardings badh gayi hain. Kaam karna ab bohot simple lagta hai.&quot;</span>
                                </div>
                            </div>

                            <div className="testimonial">
                                <img src="/image/ritu.png" style={{width:'60px',objectFit:'contain'}} />
                                <div className="name-and-comment">
                                    <span className='testimonial-name'>Anjali Patel</span>
                                    <span className='testimonial-content-text'>&quot;Hotline app ne mere kaam ko streamline kiya. Ab zyada onboardings karna mushkil nahi raha.&quot;</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <img src='/image/hotline-bg.webp' className='background-picture' />
                </>
            )
        }
    </div>
  )
}

export default HotlineLandingScreen
