import React, { useContext, useEffect, useState } from 'react';
import { ArrowRightOutlined,PieChartOutlined,DownCircleOutlined,UpCircleOutlined, InfoCircleOutlined,SwapRightOutlined,CalendarOutlined, InteractionTwoTone, ProjectFilled, RocketFilled, DashboardTwoTone, DollarCircleTwoTone, RetweetOutlined, CaretRightOutlined, CaretUpOutlined, CaretDownOutlined, RightCircleTwoTone, EyeTwoTone, EyeInvisibleOutlined, ReloadOutlined, DownOutlined, DownloadOutlined, FilterOutlined, MobileOutlined } from '@ant-design/icons';
import { DatePicker, Select, Tag, Card, Skeleton, List, Typography, Button, Empty, Tooltip, Table, Modal, Input, message, Dropdown, Space, Menu, notification, Pagination, Divider } from 'antd';
import SectionLayout from '../common/SectionLayout.component';
import OverallSummary from './OverallSummary';
import { AppContext } from '../../App';
import RunRateComponent from './RunRate.Component';
import MitraAppDownloadsSummary from './MitraAppDownloadsSummary';
import { Column, Line } from '@ant-design/plots';
import ClientPerformance from './ClientPerformance';
import ReportLeaderBoard from './ReportLeaderBoard.component';
import Moment from 'react-moment';
import moment from 'moment';
import PerfDashSingleMemberCard from './PerfDashSingleMemberCard';
import SetTargetPopup from './SetTargetPopup';
import CONSTANTS from '../../constants/constants';
import { getMitraMilestoneCount, getPerformanceDashboardTabDetails } from '../../service/reportService';
import MemberLeaderboardTabContents from './MemberLeaderboardTabContents';
import TeamLeaderboardTabContents from './TeamLeaderboardTabContents';
import { trackEvent } from '../../service/engagementMonitoringService';

const { Text } = Typography;
const { Option } = Select;

function PerformanceDashboardContainer({
    typeOfLead,
    startDate,
    endDate,
    clientFilterList,
    selectedTeamMember,
    teamList,
    teamsFilterInfo,
    locationList,
    milestoneList,
    performanceDashboardData,
    gridView,
    cientPerformance,
    configColumnPlot,
    teamLeaderBoardList,
    loaded,
    topPerformerData,
    setGridView,
    performanceHistory,
    configLinePlot,
    handleViewAll,
    applyFilterCondition,
    isAdmin,
    tab,
    setFilterSider,
    downloadLoading,
    onclickDownloadPerformance,
    onFilterClick,
    openFilterSlider,
    downloadMenu,
    preventDefault,
    selectedDate,
    getDisplayableClientName,
    selectedClient,
    showDateChip,
    deleteChip,
    setBreadcrumb
}) {
    const { mitraReducer, setAlert, mitraDispatch } = useContext(AppContext);
    
    const [performanceDashboardTabs, setPerformanceDashboardTabs] = useState([
        {
            label: 'Overview',
            key: 'overview',
            isActive: true,
            roles: [CONSTANTS.roles.ADMIN, CONSTANTS.roles.TEAM_LEADER]
        },
        {
            label: 'Team Leaderboard',
            key: 'teamLeaderboard',
            isActive: false,
            roles: [CONSTANTS.roles.ADMIN]
        },
        {
            label: 'Member Leaderboard',
            key: 'memberLeaderboard',
            isActive: false,
            roles: [CONSTANTS.roles.ADMIN, CONSTANTS.roles.TEAM_LEADER]
        }
    ]);
    const [selectedPerformanceDashboardTab, setSelectedPerformanceDashboardTab] = useState('overview');
    const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}

    const onChangePerformanceDashboardTab = (tabKey) => {
        trackMoengageEvent('change-performance-dashboard-tab', {tab: tabKey});
        const updatedPerformanceDashboardTabs = [...performanceDashboardTabs];

        for (let i = 0; i < updatedPerformanceDashboardTabs.length; i++) {
            if(updatedPerformanceDashboardTabs[i].key === tabKey) {
                updatedPerformanceDashboardTabs[i].isActive = true;
                setSelectedPerformanceDashboardTab(tabKey);
                setBreadcrumb([
                    { link: `/report/${tab}`, label: 'Report' }, 
                    { link: `/report/${tab}`, label: `${tab === 'payout-dashboard' ? 'Payout' : 'Performance'} ` },
                    { label: `${updatedPerformanceDashboardTabs[i].label} ` }
                ]);
            } else {
                updatedPerformanceDashboardTabs[i].isActive = false;
            }
        }

        setPerformanceDashboardTabs([...updatedPerformanceDashboardTabs]);
    }
    

    const performanceDashboardOverallSection = () => {
        return (
            <>
                <>
                    <div className="more-filters perf-dash-filter-container">

                        <div className="filter-set-container d-flex flex-wrap justify-content-between">

                            {/* <Button style={(applyFilterCondition()) ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : null} onClick={() => setFilterSider(true)} icon={<FilterOutlined />}>Apply Filters</Button> */}
                            <div className='new-date-container' onClick={() => onFilterClick()}>
                                <Moment format="DD MMM YYYY" withTitle style={{color: '#000000D9',fontSize: '14px',lineHeight: '22px'}}>{startDate}</Moment>
                                <SwapRightOutlined />
                                <Moment format="DD MMM YYYY" withTitle style={{color: '#000000D9',fontSize: '14px',lineHeight: '22px'}}>{endDate}</Moment>
                                <CalendarOutlined />
                            </div>

                            {/* {mitraReducer?.mitraInfo?.role === 'teamMember' ? null :
                                <> */}

                                    {
                                        tab !== 'payout-dashboard' && <Button style={{ color: '#D34612', border: '1px solid #D34612', fontWeight: 700}} loading={downloadLoading}
                                            onClick={() => {
                                                onclickDownloadPerformance();
                                            }}>
                                            Download
                                        </Button>
                                    }
                                    {
                                        tab === 'payout-dashboard' && isAdmin && <div>
                                            <Dropdown overlay={downloadMenu} >
                                                <Button danger>
                                                    <Space>
                                                        Download
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    }


                                {/* </>
                                } */}

                        </div>
                        <div className='filter-bottom d-flex' style={{gap: '8px'}}>
                            <Button className='d-flex align-items-center' style={{ color: '#D34612', border: '1px solid #D34612', flex: 1, fontWeight: 700 }} onClick={() => openFilterSlider()} icon={<FilterOutlined />}>Filter</Button>
                            <div className="Chips-container" style={{flex:16,border: '1px solid #D9D9D9', padding:'2px'}}>
                                {/* {
                                    (tab === 'performance-dashboard' && startDate !== null && endDate !== null) ?
                                        <>
                                            <Tag className="single-chip perf-dashboard-chip" onClose={(e) => preventDefault(e, 'date', startDate)}> <Moment format="DD MMM YYYY" withTitle>{startDate}</Moment> </Tag>
                                            <Text style={{ marginRight: '3px' }}>to</Text>
                                            <Tag className="single-chip perf-dashboard-chip" onClose={(e) => preventDefault(e, 'date', endDate)}> <Moment format="DD MMM YYYY" withTitle>{endDate}</Moment> </Tag>
                                        </>
                                        :
                                        <>
                                            <Tag className="single-chip perf-dashboard-chip" onClose={(e) => preventDefault(e, 'date', startDate)}> <Moment format="DD MMM YYYY" withTitle>{moment(selectedDate).startOf('month')}</Moment> </Tag>
                                            <Text style={{ marginRight: '3px' }}>to</Text>
                                            <Tag className="single-chip perf-dashboard-chip" onClose={(e) => preventDefault(e, 'date', endDate)}> <Moment format="DD MMM YYYY" withTitle>{selectedDate.endOf('month')}</Moment> </Tag>
                                        </>
                                } */}
                                {
                                    clientFilterList?.map((single_category, index) => {
                                        if (single_category.value) {
                                            return <Tag key={'tagLead' + index} className="single-chip perf-dashboard-chip" closable onClose={(e) => preventDefault(e, 'client', single_category.key)}>{getDisplayableClientName(single_category.label)} </Tag>
                                        }
                                    })
                                }

                                {
                                    teamList?.map((teamMember, index) => {
                                        if (teamMember.value) {
                                            return <Tag key={'tagLead' + index} className="single-chip perf-dashboard-chip" closable onClose={(e) => preventDefault(e, 'teamMember', teamMember.label)}>{teamMember.label} </Tag>
                                        }
                                    })
                                }
                                {/* {
                                    teamsFilterInfo?.map((team, index) => {
                                        if (team.value) {
                                            return <Tag key={'tagLead' + index} className="single-chip perf-dashboard-chip" closable onClose={(e) => preventDefault(e, 'teamNames', team.label)}>{team.label} </Tag>
                                        }
                                    })
                                } */}
                                {
                                    [...new Set(locationList)]?.map((single_status, index) => {
                                        if (single_status.value) {
                                            return <Tag key={index} className="single-chip perf-dashboard-chip" closable onClose={(e) => preventDefault(e, 'location', single_status)}>{single_status.label} </Tag>
                                        }
                                    })
                                }
                                {(tab === 'performance-dashboard') &&
                                    milestoneList?.map((single_category, index) => {
                                        if (single_category.value) {
                                            return <Tag key={'tagLead' + index} className="single-chip perf-dashboard-chip" closable onClose={(e) => preventDefault(e, 'milestone', single_category.key)}>{single_category.label} </Tag>
                                        }
                                    })
                                }
                            </div>
                        </div>
                        
                    </div>

                    {
                    (selectedTeamMember !== 0 || selectedClient !== '' || showDateChip) ?
                        <div className="Chips-container" style={{ padding: "0 20px" }}>
                            {/* {
                            <Tag className="single-chip perf-dashboard-chip" closable onClose={()=>deleteChip('month')}> {moment(startDate).format("DD-MMM-YYYY")-moment(endDate).format("DD-MMM-YYYY")}-</Tag>
                        } */}
                            {
                                selectedTeamMember !== 0 ?
                                    <Tag className="single-chip perf-dashboard-chip" closable onClose={() => deleteChip('teamMember')}> {teamList[selectedTeamMember].name}</Tag>
                                    : null
                            }
                            {
                                selectedClient !== '' ?
                                    <Tag className="single-chip perf-dashboard-chip" closable onClose={() => deleteChip('client')}> {selectedClient}</Tag>
                                    : null
                            }
                        </div>
                        : null
                    }

                </>

                <div className="main-container" style={{backgroundColor: '#f5f5f5'}}>
                    {
                        typeOfLead === 'Eligible Leads' ? <div style={{ margin: '4px 24px'}}>
                            <div className="warning-message-at-risk-container">
                                <div className="warning-message-atrisk">
                                    <InfoCircleOutlined style={{ color: '#D34612', margin: '5px 10px 0px 10px' }} />
                                    This information is about leads that reached the milestone before expiring.
                                </div>
                            </div>
                        </div> : null
                    }

                    <SectionLayout themeWhite={false} icon={<DashboardTwoTone twoToneColor="#D34612" style={{display: 'flex', fontWeight: '400'}}/>}
                        title={<span style={{fontWeight: 400}}>OVERALL {mitraReducer?.mitraInfo?.role !== 'admin' && 'TEAM'} SUMMARY</span>}>
                        <OverallSummary mitraDispatch={() => mitraDispatch({
                            type: 'reportParams', value: {
                                startDate, endDate,
                                clientList: clientFilterList,
                                selectedTeamMember,
                                teamList,
                                typeOfLead,
                                locationList,
                                selectedMilestoneList: Object.keys(milestoneList).filter(key => milestoneList[key].value)?.map(key => milestoneList[key].label),
                            }
                        })} performanceDashboardData={performanceDashboardData} />

                    </SectionLayout>

                    <SectionLayout themeWhite={false} icon={<MobileOutlined twoToneColor="#D34612" style={{display: 'flex'}}/>} title={<span style={{fontWeight: 400}}>VAHAN APP DOWNLOAD SUMMARY</span>}>
                        <MitraAppDownloadsSummary mitraDispatch={() => mitraDispatch({
                            type: 'reportParams', value: {
                                startDate, endDate,
                                clientList: clientFilterList,
                                selectedTeamMember,
                                teamList,
                                typeOfLead,
                                locationList,
                                selectedMilestoneList: Object.keys(milestoneList).filter(key => milestoneList[key].value)?.map(key => milestoneList[key].label),
                            }
                        })} performanceDashboardData={performanceDashboardData} />
                    </SectionLayout>

                    <SectionLayout gridView={gridView} switchName={<span style={{fontWeight: 400}}>Graph View</span>} onChangeGrid={() => { setGridView(!gridView) }} icon={<ProjectFilled style={{display: 'flex'}}/>} title={<span style={{fontWeight: 400}}>CLIENT WISE PERFORMANCE</span>}>
                        {
                            gridView && (cientPerformance?.data ?
                                cientPerformance?.data?.length > 0 ?
                                    <Column {...configColumnPlot} />
                                    : <Empty />
                                :
                                <Skeleton active />)
                        }
                        {
                            !gridView && (cientPerformance?.arrayFormat ?
                                (cientPerformance?.arrayFormat?.length > 0 ?
                                    <ClientPerformance mitraDispatch={(client) => mitraDispatch({
                                        type: 'reportParams', value: {
                                            startDate, endDate, clientList: function () {
                                                clientFilterList.map(item => {
                                                    if (item.label.toLowerCase() === client.toLowerCase()) {
                                                        item.value = true
                                                    } else {
                                                        item.value = false
                                                    }
                                                })
                                                return clientFilterList;
                                            },
                                            selectedTeamMember, teamList,
                                            typeOfLead,
                                            locationList,
                                            selectedMilestoneList: Object.keys(milestoneList).filter(key => milestoneList[key].value)?.map(key => milestoneList[key].label),
                                        }
                                    })} cientPerformance={cientPerformance} />
                                    :
                                    <Empty />)
                                : <Skeleton active />)
                        }
                    </SectionLayout>
                    {/* <SectionLayout themeWhite={false} icon={<RocketFilled style={{display: 'flex'}}/>} title={"Team Leaderboard"}>
                        {
                            teamLeaderBoardList.length ?
                                teamLeaderBoardList.length > 0 ?
                                    <ReportLeaderBoard teamLeaderBoardList={teamLeaderBoardList} loading={loaded} topPerformerData={topPerformerData} startDate={startDate} endDate={endDate} handleViewAll={handleViewAll} />
                                    :
                                    <Empty />
                                :
                                <Skeleton active />
                        }
                    </SectionLayout> */}
                    <SectionLayout icon={<InteractionTwoTone twoToneColor="#D34612" style={{display: 'flex'}}/>} title={<span style={{fontWeight: 400}}>PERFORMANCE HISTORY</span>}>
                        {
                            performanceHistory ?
                                performanceHistory?.data?.values.length > 0 ?
                                    <Line {...configLinePlot} />
                                    :
                                    <Empty />
                                :
                                <Skeleton active />
                        }
                    </SectionLayout>
                </div>
            </>
        )
    }

  return (
    <>
        <RunRateComponent />

        <div className='tab-layout-performance-dashboard tab-layout-performance-dashboard-desktop'>
            {
                mitraReducer?.mitraInfo?.role === 'teamMember' ?
                <div className='overview-tab'>
                    OVERVIEW
                </div>
                :
                performanceDashboardTabs.map((item) => (
                    <div 
                    key={item.key} 
                    style={{userSelect:'none', display: item.roles.indexOf(mitraReducer?.mitraInfo?.role) === -1 ? 'none':''}}
                    className={`${item.isActive ? 'individual-tab active-tab': 'individual-tab'}`}
                    onClick={()=>onChangePerformanceDashboardTab(item.key)}
                    >
                        {
                            mitraReducer?.mitraInfo?.role === 'teamLeader' && item.label === 'Member Leaderboard' ?
                            <span>Leaderboard</span>
                            :
                            <>{item.label}</>
                        }
                    </div>
                ))
            }
        </div>

        <div className='show-only-mobile jp-tabs-container-mobile'>
            <div className='jp-tabs d-flex' style={{margin: '36px 24px 12px'}}>
                {
                    mitraReducer?.mitraInfo?.role === 'teamMember' ?
                    <div className='overview-tab'>
                        OVERVIEW
                    </div>
                    :
                    <Select
                        placeholder="Select View"
                        style={{ width: '75%' }}
                        onChange={(jobType) => { onChangePerformanceDashboardTab(jobType) }}
                        value={selectedPerformanceDashboardTab}
                    >
                    {
                        mitraReducer?.mitraInfo?.role === 'admin' ?
                        <>
                        {
                        performanceDashboardTabs.map((item) => (
                            <Option value={item.key} key={item.key}>{item.label}</Option>
                        ))
                        }
                        </> :
                        <>
                            <Option value='overview' key='overview'>Overview</Option>
                            <Option value='memberLeaderboard' key='memberLeaderboard'>Leaderboard</Option>
                        </>
                    }
                    </Select>
                }
            </div>
        </div>

        {
            selectedPerformanceDashboardTab === 'overview' ? performanceDashboardOverallSection(): null
        }

        {
            selectedPerformanceDashboardTab === 'teamLeaderboard' ? <TeamLeaderboardTabContents />: null
        }

        {
            selectedPerformanceDashboardTab === 'memberLeaderboard' ? <MemberLeaderboardTabContents />: null
        }
        
    </>
  )
}

export default PerformanceDashboardContainer