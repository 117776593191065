import React, { useState, useEffect } from "react";
import { Modal, Button, Avatar, Typography, Divider, Tag} from 'antd';
import { UserOutlined, CustomerServiceTwoTone, InboxOutlined, SendOutlined, FileAddOutlined, FileFilled, CustomerServiceOutlined } from '@ant-design/icons';
import '../../css/chat.scss';
import * as ticketService from '../../service/ticketService.js';
import moment from "moment";

const { Text} = Typography;

const Chat = ({ freshdeskData, ticket, getConversation, chatModal, chatModalCancel }) => {

    const [text, setText] = useState('');
    const [attachments, setAttachment] = useState([]);

    const handleSend = async () => {
        if (text.trim() !== '') {
            // const response = await ticketService.sendChat(ticket, text, attachments);
            // if (response.status === 201) {
            //     setText('');
            //     setAttachment([]);
            //     getConversation();
            // }
            ticketService.sendChat(ticket, text, attachments).then(res => {
                   setText('');
                setAttachment([]);
                getConversation(ticket); 
            }).catch(err=>{
                console.log(err);
            })
        }
    };

    const returnStatusTags = (record) => {
        if (record.status === 'Open') {
           return <Tag style={{background: '#85A5FF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Open</Tag>
        }
        if (record.status === 'Closed') {
            return <Tag style={{background: '#BFBFBF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Closed</Tag>
        }
        if (record.status === 'Pending') {
            return <Tag style={{background: '#BFBFBF', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Pending</Tag>
        }
        if (record.status === 'Resolved') {
            return <Tag style={{background: '#52C41A', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Resolved</Tag>
        }
        if (record.status === 'Waiting on Vahan Leader') {
            return <Tag style={{background: '#F74931', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Waiting on Vahan Leader</Tag>
        }
        if (record.status === 'Waiting on Client') {
            return <Tag style={{background: '#FAAD14', borderRadius:'10px',color:'#fff',fontSize:'12px',lineHeight:'20px',fontFamily:'Nunito Sans',fontWeight:'700'}}>Waiting on Client</Tag>
        }
    };

    const scrollToBottom = () => {
        const chatContainer = document.getElementById('chat-container');
        if(chatContainer)
            chatContainer.scrollTop = chatContainer.scrollHeight;
    };

    useEffect(() => {
        scrollToBottom();
    }, [freshdeskData]);

    return (
        <Modal
            className="chat-modal-style"
            style={{ top: 20 }}
            title={
            <div className="ticket-modal-header-container">
                <Text className="ticket-title">Conversation History</Text>
                <div className="ticket-summary">
                    <div className="ticket-summary-content">Issue Type: <b>{ticket.issueType}</b></div>
                    <div style={{fontSize:'1rem',display:'flex',gap:'8px'}}>Client Name: <b>{ticket.clientName}</b></div>
                    <div style={{fontSize:'1rem',display:'flex',gap:'8px'}}>Status: <b>{returnStatusTags(ticket)}</b></div>
                </div>
            </div>
        }
            width={800}
            visible={chatModal}
            onCancel={chatModalCancel}
            footer={null}
        >
            <div className="container">
                <div className="chat-container" id="chat-container">
                    <div className="main-ticket-container">
                    <div className='help-sider-conversation-history-container-chatbox'>
                    {
                        freshdeskData.length === 0 ? <div className="no-chat" style={{ gap: '15px', fontSize: '20px', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <InboxOutlined style={{ fontSize: '30px' }} />
                            No Chat History</div> :

                            freshdeskData.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                    <div className='single-message-container'>
                                        <div className='message-header'>
                                            <div 
                                            className={ticket.requester_id === item.user_id ? 'message-header-icon mitra-leader': 'message-header-icon support'}
                                            >
                                                {ticket.requester_id === item.user_id ? 'M':'S'}
                                            </div>
                                            <div className='message-header-text'>
                                                <Text className='client-name-text'>
                                                {ticket.requester_id === item.user_id ? 'Vahan Leader':'Support Team'}
                                                </Text>
                                                <Text className='date-text'>
                                                    {moment(item.created_at).format("DD MMM YYYY hh:mm A")}
                                                    
                                                </Text>
                                            </div>
                                        </div>
                                        <div className='message-description'>
                                            <Text>
                                                <div dangerouslySetInnerHTML={{ __html: item.body }} />
                                            </Text><br/>
                                            {
                                            item.attachments.length > 0 ? <Text>Attachments:</Text>: null  
                                            }
                                            {
                                                item.attachments.length > 0 ? (
                                                    
                                                    item.attachments.map((attachment, index)=> {
                                                        return (
                                                            <div key={attachment.id}>
                                                                <a 
                                                                href={attachment.attachment_url} 
                                                                style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}
                                                                rel="noreferrer" 
                                                                target="_blank">
                                                                    {attachment.name}
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <Divider />
                                    </div>
                                )
                            })
                    }
                    </div>
                    </div>
                </div>
                <div className="image-preview">
                    {
                        attachments.length > 0 ? <Text>Attachments:</Text>:null
                    }
                    {
                        attachments.length > 0
                            ? (
                                attachments.map((attachment, index) => {
                                    return (
                                        <div key={index}>{attachment.name}</div>
                                    )
                                })
                            ) : null
                    }
                </div>
                <div className="input-container">
                    <div className="chat-input-container">
                        <input type="text" placeholder="Type here..." onChange={(e) => setText(e.target.value)} value={text} />
                        <input type="file" style={{ display: "none" }} id="file" multiple accept="*/*" onChange={(e) => {
                            attachments.length > 0 ? setAttachment([...attachments, ...e.target.files]) : setAttachment([...e.target.files])
                        }
                        }
                        />
                        <label htmlFor="file">
                            <img style={{height:'30px', cursor: 'pointer'}} src='/image/attachment-icon.svg'/>
                        </label>
                    </div>
                    <div className="send">
                        <Button
                            shape="round"
                            icon={<SendOutlined />}
                            style={{background: '#52C41A', border:'none'}}
                            onClick={() => {
                                handleSend()}}
                        >
                            Send
                        </Button>
                    </div>
                </div>
                
            </div>

        </Modal>
    );
};

export default Chat
