// import {
//     TeamOutlined, TableOutlined, DashboardOutlined, BookOutlined, IssuesCloseOutlined, PercentageOutlined, LogoutOutlined, MenuOutlined, QuestionCircleOutlined, MenuFoldOutlined, SettingOutlined, MenuUnfoldOutlined, PieChartOutlined, DollarCircleOutlined, GroupOutlined,
//     UsergroupAddOutlined, HomeOutlined, HddOutlined, LeftOutlined, RightOutlined, BellOutlined, CheckOutlined, CloseOutlined, PhoneOutlined
// } from '@ant-design/icons';
import {PhoneFilled,UserOutlined, MessageOutlined, FireOutlined, PictureOutlined, ProjectOutlined, DashboardOutlined, BookOutlined, IssuesCloseOutlined,PercentageOutlined, LogoutOutlined, MenuOutlined, QuestionCircleOutlined, MenuFoldOutlined, SettingOutlined, MenuUnfoldOutlined, PieChartOutlined, DollarCircleOutlined, GroupOutlined,
    UsergroupAddOutlined, HomeOutlined, HddOutlined,LeftOutlined,RightOutlined,BellOutlined, CheckOutlined, CloseOutlined, PhoneOutlined} from '@ant-design/icons';
import React from 'react';
import FileAddIcon from '../static/svg/FileAddIcon';
import AdvFilterIcon from '../static/svg/AdvFilterIcon';
import ThunderIcon from '../static/svg/ThunderIcon';

const CONSTANTS = {
    roles: {
        ADMIN: 'admin',
        TEAM_LEADER: 'teamLeader',
        TEAM_MEMBER: 'teamMember'
    },
    SALARY_FILTER_TAGS: [
        {
            "key": "0-10000",
            "label": "0-10000",
            "value": false
        },
        {
            "key": "10000-25000",
            "label": "10000-25000",
            "value": false
        },
        {
            "key": "25000-50000",
            "label": "25000-50000",
            "value": false
        },
        {
            "key": "50000-100000",
            "label": "50000-100000",
            "value": false
        },

    ],
    INBOX_FILTER_TAGS: [
        {
            "id": 0,
            "name": 'All Messages'
        },
        {
            "id": 1,
            "name": 'Incentives'
        },
        {
            "id": 3,
            "name": 'Payouts'
        },
        {
            "id": 4,
            "name": 'Client Process Update'
        },
        {
            "id": 5,
            "name": 'New Client Launch'
        },
        {
            "id": 6,
            "name": 'Client Demand Update'
        },
        {
            "id": 7,
            "name": 'New Feature Launch'
        }
    ],
    HELP_FILTER_TAGS: [
        {
            "id": 7,
            "name": 'All Tickets',
            "statusId": null
        },
        {
            "id": 0,
            "name": 'Open',
            "statusId": 2
        },
        {
            "id": 1,
            "name": 'Pending',
            "statusId": 3
        },
        {
            "id": 3,
            "name": 'Resolved',
            "statusId": 4
        },
        {
            "id": 4,
            "name": 'Closed',
            "statusId": 5
        },
        {
            "id": 5,
            "name": 'Waiting for your reply',
            "statusId": 6
        },
        {
            "id": 6,
            "name": 'Raised to Client',
            "statusId": 7
        }
    ],
    ENGAGEMENT_TYPES: {
        BLITZLLAMA: 'BLITZLLAMA',
        MOENGAGE: 'MOENGAGE',
        ALL_ENGAGEMENTS: 'ALL_ENGAGEMENTS'
    },
    VALUE_PROPOSITION: {
        "uber": [
            {
                id: 0,
                value: "Upfront destination information in advance.",
            },
            {
                id: 1,
                value: "Long Pick up Fee.",
            },
            {
                id: 2,
                value: "Night Time pricing.",
            },
            {
                id: 3,
                value: "Surge pricing.",
            },
            {
                id: 4,
                value: "City wise joining bonus.",
            },
            {
                id: 5,
                value: "Peak hours and high demand areas.",
            },
            {
                id: 6,
                value: "Zero registration fee.",
            },
            {
                id: 7,
                value: "Accidental Insurance upto 5L (For Full time drivers).",
            },
            {
                id: 8,
                value: "Daily Payment."
            }
        ],
        "sfx": [
            {
                id: 0,
                value: "D/L is not a mandatory document.",
            },
            {
                id: 1,
                value: "Daily Slab Based MG (Minimum Guarantee) earning + Weekly Incentive Milestone.",
            },
            {
                id: 2,
                value: "Accidental Insurance upto 8L + Accidental hospitalization cover up to 2L for a minimum of 24h hospital admission cases.",
            },
            {
                id: 3,
                value: "Zero upfront fees.",
            },
            {
                id: 4,
                value: "Own cost effective EV at Rs 150 rental per day ( Rs 2500 one time deposit).",
            },
            {
                id: 5,
                value: "Joining Bonus.",
            }
        ],
        "rapido": [
            {
                id: 0,
                value: "Demand across T-1 ,T-2 and T-3 Cities. ",
            },
            {
                id: 1,
                value: "Food delivery and Parcel delivery options are available apart from Bike taxi.",
            },
            {
                id: 2,
                value: "No Onboarding fees.",
            },
            {
                id: 3,
                value: "100% Remote onboarding.",
            },
            {
                id: 4,
                value: "Real time activations.",
            },
            {
                id: 5,
                value: "Earnings of upto Rs.30,000 per month for the riders working full time and upto Rs.18,000 per month for part time riders. ",
            },
            {
                id: 6,
                value: "Earnings can be transferred from Rapido wallet to your bank account at your convenience.",
            },
            {
                id: 7,
                value: "Surge during peak hours.",
            },
            {
                id: 8,
                value: "High demand areas visible on the home screen,helps riders to get maximum trips in respective areas.",
            },
            {
                id: 9,
                value: "Accidental coverage and medical benefits covered up to Rs 5 Lakh for Captain & family.",
            },
        ],
        "zomato": [
            {
                id: 0,
                value: "Monthly earning potential upto 35,000.",
            },
            {
                id: 1,
                value: "Flexible timings via the gig based ecosystem.",
            },
            {
                id: 2,
                value: "Ability to book gigs upto 3 days in advance.",
            },
            {
                id: 3,
                value: "Following Insurances",
                hasChild: [
                    {
                        id: 'a',
                        value: "5K OPD for you and your family.",
                    },
                    {
                        id: 'b',
                        value: "1 Lakh for hospitalization for you only.",
                    },
                    {
                        id: 'c',
                        value: "10 Lacs for accidental death for you only.",
                    },
                    {
                        id: 'd',
                        value: "Donation for delivery rider’s kids education, upto 50k annually, for riders who have been with them for 5 years.",
                    },
                ]
            },
            {
                id: 4,
                value: "Joining bonus amounts after hitting a certain milestone.",
            },
            {
                id: 5,
                value: "PAN India demand for Vahans to contribute.",
            },
            {
                id: 6,
                value: "100% remote onboarding.",
            },
            {
                id: 7,
                value: "No need to go to a hub to collect t-shirt bag. It gets delivered to riders doorstep.",
            },
            {
                id: 8,
                value: "Stars marked next to a few gig slots helping riders identify high demand time and locations.",
            },
        ],
        "zepto": [
            {
                id: 0,
                value: "Short distance delivery."
            },
            {
                id: 1,
                value: "Proper sitting areas at the store. Riders have washroom accessibility,drinking water etc."
            },
            {
                id: 2,
                value: "High demand: 20-40 orders/day."
            },
            {
                id: 3,
                value: "Insurance coverage upto Rs.2 Lac."
            },
            {
                id: 4,
                value: "Attractive weekly and monthly incentives along with minimum guarantee."
            },
            {
                id: 5,
                value: "Earnings upto 30-40k/month."
            },
            {
                id: 6,
                value: "No waiting time at stores."
            },
            {
                id: 7,
                value: "Zepto provides it’s own cycle free of cost."
            },
        ],
        "amflex": [
            {
                id: 0,
                value: "No Upfront/OB fee. Free Bag+Tshirt."
            },
            {
                id: 1,
                value: "Courier delivery with multiple orders on the same route."
            },
            {
                id: 2,
                value: "Payment is made on Every Wednesday per week."
            },
            {
                id: 3,
                value: "Earning Potential: Amazon Flex candidates earn on a per hour basis. Depending upon the city and time, they can earn anywhere between 120 to 140 Rs* per hour."
            },
            {
                id: 4,
                value: "Flexibility in work timings as the slot booking is done by candidates themselves."
            },
            {
                id: 5,
                value: "Candidates can work for a maximum of 54 hours per week, hence better personal life balance."
            },
            {
                id: 6,
                value: "No Mandatory Login Hours - Very useful to manage your time in doing other activities like studying, playing a sport, learning a new skill etc."
            },
            {
                id: 7,
                value: "Work available a week ahead so that slots can be planned and blocked."
            },
            {
                id: 8,
                value: "Insurance Benefits upto Rs. 5,00,000."
            },
        ],
        "swiggy": [
            {
                id: 0,
                value: "Flexibility of work and time - Join Swiggy as: Swiggy Full Time Job, Swiggy Part-Time Job or Swiggy Temporary Job."
            },
            {
                id: 1,
                value: "Flexibility in choosing shift timings."
            },
            {
                id: 2,
                value: "Minimum income guarantee. Earn as much as you can which also includes attractive incentives."
            },
            {
                id: 3,
                value: "No qualification asked - The best part about this job is that anyone can join it & no education is required except basic knowledge in English."
            },
            {
                id: 4,
                value: "Weekly Payout: Their salary is given on a weekly basis."
            },
            {
                id: 5,
                value: "Immediate Joining - The joining process of Swiggy for DE is very simple and easy to complete."
            },
            {
                id: 6,
                value: "Growth & Promotion opportunities - Swiggy provides different growth opportunities to partners."
            },
            {
                id: 7,
                value: "Insurance Benefits for DE and their family members."
            },
        ],
        "grab": [
            {
                id: 0,
                value: "Ability to earn and redeem money daily (For Rider)"
            },
            {
                id: 1,
                value: "Opportunity to work with the biggest E-comm company Flipkart (For Rider)"
            },
            {
                id: 2,
                value: "Help with escalations/activations real time (For Rider)"
            },
            {
                id: 3,
                value: "Earn up to 2k in incentives just on the number of days and order numbers no bar here (For Vahan Leaders)"
            },
            {
                id: 4,
                value: "Direct support from our AMs for every real time escalation/activation (For Vahan Leaders)"
            },
            {
                id: 5,
                value: "Complete support on data cadence on a daily basis (For Vahan Leaders)"
            },
            {
                id: 6,
                value: "Support for Vahan exclusively (For Vahan Leaders)"
            }
        ],
        "uber_3pl": [],
        "rapido_3pl": [
            {
                id: 0,
                value: "Earning Details and Benefits:",
                hasChild: [
                    {
                        id: 'a',
                        value: "Earn upto Rs 30,000 per month.",
                    },
                    {
                        id: 'b',
                        value: "Zero Registration or Onboarding Fee.",
                    },
                    {
                        id: 'c',
                        value: "Accidental coverage and medical benefits covered up to Rs 5 Lakh for Captain.",
                    },
                    {
                        id: 'd',
                        value: "Get onboarded from your home, no need to visit Rapido Office.",
                    },
                    {
                        id: 'e',
                        value: "Earnings can be transferred from Vahan wallet to your bank account at your convenience.",
                    }
                ]
            },
            {
                id: 1,
                value: "Joining Requirements:",
                hasChild: [
                    {
                        id: 'a',
                        value: "Educational Requirements - None.",
                    },
                    {
                        id: 'b',
                        value: "Need your own vehicle and phone.",
                    },
                    {
                        id: 'c',
                        value: "Vehicles Allowed - Bike (Geared or Ungeared), E-Bike.",
                    },
                    {
                        id: 'd',
                        value: "Documents - Aadhar, PAN, DL, RC.",
                    },
                ]
            },
            {
                id: 2,
                value: "Timings:",
                hasChild: [
                    {
                        id: 'a',
                        value: "Flexible Timings.",
                    },
                ]
            },
        ],
        "dunzo": [
            {
                id: 0,
                value: "No upfront onboarding fees.",
            },
            {
                id: 1,
                value: "Earnings of upto Rs.35,000 per month for the riders working full time and upto Rs.20,000 per month for part time riders.",
            },
            {
                id: 2,
                value: "Surge during peak hours.",
            },
            {
                id: 3,
                value: "Flexible working hours.",
            },
            {
                id: 4,
                value: "Joining Bonus and Weekly incentives.",
            },
            {
                id: 5,
                value: "Onboarding fee of Rs.1200 will be deducted in 2 installments in the first 2 weeks of earnings.",
            },
        ],
        "delhivery_3pl": [
            {
                id: 0,
                value: "Flexibility of work and time - Full time and part time options available Two shifts available : 8AM - 1PM and 1PM to 6PM (Flexibility to choose)",
            },
            {
                id: 1,
                value: "Short distance delivery",
            },
            {
                id: 2,
                value: "High Demand : Deliver upto 50-70 shipments per day",
            },
            {
                id: 3,
                value: "Pick multiple packets in one go",
            },
            {
                id: 4,
                value: "Earning potential - Upto 40k",
            },
            {
                id: 5,
                value: "No waiting time at stores",
            },
            {
                id: 6,
                value: "Accidental insurance of 3 lacs and medical insurance of 1 lac",
            },
            {
                id: 7,
                value: "Easy onboarding process",
            }
        ],
    },
    CANDIDATE_POST_REFERRAL_STEPS: [
        {
            message: 'Share App Download Link with the lead.',
            clients: ['uber', 'rapido', 'dunzo', 'zomato', 'swiggy']
        },
        {
            message: 'Share ID & Password setup link with the lead.',
            clients: ['uber']
        },
        {
            message: 'Share list of nearest OB centre with the lead.',
            clients: ['dunzo', 'swiggy']
        },
        {
            message: 'Ask lead to upload missing/rejected documents.',
            clients: ['uber', 'rapido', 'dunzo', 'zomato', 'swiggy']
        },
        {
            message: 'Ask lead to pay OB fee.',
            clients: ['zomato', 'swiggy']
        },
        {
            message: 'Download the Vahan App : https://mitraapp.page.link/download-ma',
            clients: ['zepto_3pl'],
            hasClickableUrl: true
        },
        {
            message: 'Ask rider to upload documents - Profile Picture, Aadhar card front, PAN card, Driving license front, bank account passbook/cheque',
            clients: ['zepto_3pl']
        },
        {
            message: 'Ask rider to download Oust app and complete training : https://play.google.com/store/apps/details?id=com.oustme.oustapp',
            clients: ['zepto_3pl'],
            hasClickableUrl: true
        },
        {
            message: 'Ask Unique candidate to download the Vahan App : http://dwnld.vahan.co/',
            clients: ['rapido_3pl', 'bigbasket_3pl'],
            hasClickableUrl: true
        },
        {
            message: 'Ask rider to upload documents - Profile Picture, Aadhar card front, PAN card, Driving license front, bank, account passbook/cheque, RC front, RC back',
            clients: ['rapido_3pl', 'bigbasket_3pl']
        },
        {
            message: 'Please ask the candidate to download the Vahan App : https://mitraapp.page.link/download-ma',
            clients: ['uber', 'uber_3pl', 'rapido', 'rapido', 'zomato', 'dunzo', 'swiggy', 'zepto', 'zepto_3pl', 'zomato', 'blinkit', 'flipkart'],
        },
        {
            message: "Please fill out the provided form if you haven't already. https://360.idfy.com/signup/37646013-0184-42e9-8998-45ef9cf606c5/18e0cf22-81ea-40b3-88a1-b656086d5928",
            clients: ['dominos'],
            hasClickableUrl: true
        }
    ],
    CALL_TAGS: {
        CONNECTED_TAGS: [
            {
                id: 5,
                value: 'Interested',
                isChecked: false,
                enableCallback: true
            },
            {
                id: 6,
                value: 'Not Interested',
                isChecked: false,
                enableCallback: false
            },
            {
                id: 0,
                value: 'Already Working',
                isChecked: false,
                enableCallback: false
            },
            {
                id: 1,
                value: 'Not Started',
                isChecked: false,
                enableCallback: true
            },
            {
                id: 2,
                value: 'Personal Issue',
                isChecked: false,
                enableCallback: true
            },
            {
                id: 3,
                value: 'App Issue',
                isChecked: false,
                enableCallback: true
            },
            {
                id: 7,
                value: 'Not Eligible',
                isChecked: false,
                enableCallback: false
            }
        ],
        NOT_CONNECTED_TAGS: [
            {
                id: 0,
                value: 'Ringing but not receiving',
                isChecked: false,
                enableCallback: true
            },
            {
                id: 1,
                value: 'Switch-off',
                isChecked: false,
                enableCallback: true
            },
            {
                id: 2,
                value: 'Busy',
                isChecked: false,
                enableCallback: true
            },
            {
                id: 3,
                value: 'Out of service',
                isChecked: false
            }
        ]
    },
    CALL_NOTE: [
        {
            label: 'Connected',
            key: 'connected',
            isSelected: true,
            icon: <CheckOutlined style={{ fontSize: '12px' }} />,
            activeClass: 'connected-active',
        },
        {
            label: 'Not Connected',
            key: 'notConnected',
            isSelected: false,
            icon: <CloseOutlined style={{ fontSize: '12px' }} />,
            activeClass: 'not-connected-active',
        }
    ],

    RECRUITER_DASHBOARD_CALL_STATUS_TAGS: [
        {
            key: 'connected',
            label: 'Connected',
            value: false
        },
        {
            key: 'notConnected',
            label: 'Not Connected',
            value: false
        },
        {
            key: 'alreadyWorking',
            label: 'Already Working',
            value: false
        },
        {
            key: 'notStarted',
            label: 'Not Started',
            value: false
        },
        {
            key: 'personalIssue',
            label: 'Personal Issue',
            value: false
        },
        {
            key: 'appIssue',
            label: 'App Issue',
            value: false
        },
        {
            key: 'callMeLater',
            label: 'Call Me Later',
            value: false
        },
        {
            key: 'ringingButNotReceiving',
            label: 'Ringing but not receiving',
            value: false
        },
        {
            key: 'switchOff',
            label: 'Switch-off',
            value: false
        },
        {
            key: 'busy',
            label: 'Busy',
            value: false
        },
        {
            key: 'outOfService',
            label: 'Out of service',
            value: false
        },
        {
            key: 'interested',
            label: 'Interested',
            value: false
        },
        {
            key: 'notInterested',
            label: 'Not Interested',
            value: false
        },
        {
            key: 'notEligible',
            label: 'Not Eligible',
            value: false
        }
    ],
    COLUMNS_FOR_CONVERTED_LEADS: [
        {
            title: 'Candidate Name',
            dataIndex: 'candidateName',
            key: 'candidateName',
        },
        {
            title: 'Candidate Phone Number',
            dataIndex: 'candidatePhoneNumber',
            key: 'candidatePhoneNumber',
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
        },
        {
            title: 'Referral Date',
            dataIndex: 'referralDate',
            key: 'referralDate',
        },
        {
            title: 'First Date of work,',
            dataIndex: 'first_date_of_work',
            key: 'first_date_of_work',
        },
        {
            title: '10th Order date',
            dataIndex: 'tenth_date_of_work',
            key: 'tenth_date_of_work',
        },
        {
            title: '25th Order date',
            dataIndex: 'twentyFifth_date_of_work',
            key: 'twentyFifth_date_of_work',
        },
        {
            title: 'Candidate Lifetime Orders/trips',
            dataIndex: 'tripCount',
            key: 'tripCount',
        }
    ],
    RAPIDO_FORMIO_DOC_MAPPING: {
        'DL': [
            'drivingLicense',
            'drivingLicenseBack'
        ],
        'PAN': 'panCard',
        'RC': [
            'vehicleRc',
            'vehicleRcBack',
            'vehicleNumber'
        ],
        'Profile Photo': 'passportPhoto'
    },
    UBER_FORMIO_DOC_MAPPING: {
        'DL Image': ['drivingLicense', 'drivingLicenseBack'],
        'PAN Image': 'panCard',
        'Aadhaar Image': ['aadhaarCard', 'aadhaarCardBack'],
        'Bank Passbook or Cancelled Cheque Image': 'bankPassbookOrCancelledCheque',
        'RC Image': 'vehicleRc',
        'Selfie': 'userSelfie'
    },
    ZEPTO_3PL_DOC_MISSING_ARRAY: [
        'userSelfie',
        'drivingLicense',
        'vehicleRc',
    ],
    UBER_DL_CHECKER_CITIES: [
        'hyderabad',
        'mumbai',
        'guwahati',
        'ahmedabad',
        'kolkata',
        'pune',
        'bangalore',
        'bengaluru',
        'mysore',
        'chandigarh',
        'jaipur',
        'raipur',
        'chennai',
        'madurai',
        'trichy',
        'coimbatore',
        'salem',
        'delhi',
        'new delhi',
        'gurgaon',
        'noida',
        'ghaziabad',
        'faridabad',
        'kochi',
        'thiruvananthapuram',
        'thrissur',
        'kozhikode',
        'bhopal',
        'indore',
        'bhubaneswar'
    ],
    RECRUITER_DASHBOARD_CALL_TYPE_TAGS: [
        {
            key: 'incoming',
            label: 'Incoming',
            value: false
        },
        {
            key: 'outgoing',
            label: 'Outgoing',
            value: false
        },
    ],
    RECRUITER_DASHBOARD_MILESTONE_TAGS: [
        {
            key: 'activated',
            label: 'Activated',
            value: false
        },
        {
            key: 'completed1stTrip',
            label: 'F1 ~ First Trip',
            value: false
        },
        {
            key: 'completed10thTrip',
            label: 'F10 ~ First 10 Trips',
            value: false
        },
    ],
    RECRUITER_DASHBOARD_CANDIDATE_STATUS_TAGS: [
        {
            key: 'followUpAgain',
            label: 'Follow Up Again',
            value: false
        },
    ],
    FD_TOKEN: 'R1pJSXdhczMyMVV4RThvUG56Olg=',

    HOME_PAGE_QUICK_LINKS: [
        {currentMenu: 'LEADS', key: 'hot_leads', heading: 'Hot Leads', link: '/hot-leads-candidates', icon: <FireOutlined style={{color: '#D34612', fontSize: '22px'}} />, visible:'allUsers'},
        {currentMenu: 'LEADS', key: 'recruiter_candidates', heading: 'Leads to follow up', link: '/recruiter-candidates', icon: <UserOutlined style={{color: '#D34612', fontSize: '22px'}} />, visible:'allUsers'},
        {currentMenu: 'JOB_DEMANDS', key: 'client_list_with_demand', heading: 'Job Posters', link: '/job-demands/job-requirements', icon: <PictureOutlined style={{fontSize: '22px', color: '#D34612'}} />,visible:'allUsers'},
        {currentMenu: 'PERFORMANCE_DASH', key: 'performance-dashboard', heading: 'Team Leaderboard', link: '/report/performance-dashboard', icon: <ProjectOutlined style={{color: '#D34612', rotate: '180deg', fontSize: '22px'}} />,visible:'managers'},
        {currentMenu: 'TEAM_PERFORMANCE', key: 'team-performance-dashboard', heading: 'Member Leaderboard', link: '/report/performance-dashboard', icon: <ProjectOutlined style={{color: '#D34612', rotate: '180deg', fontSize: '22px'}} />,visible:'teamMembers'},
        {currentMenu: 'HELP', key: 'help_and_support', heading: 'Help', link: '/help', icon: <IssuesCloseOutlined style={{color: '#D34612', fontSize: '22px'}} />,visible:'allUsers'}
    ],

    //     { currentMenu: 'LEADS', key: 'candidate_list', heading: 'Candidate List', link: '/recruiter-candidates', icon: <TeamOutlined style={{ fontSize: '30px' }} />, visible: 'allUsers' },
    //     { currentMenu: 'JOB_DEMANDS', key: 'client_list_with_demand', heading: 'Client List with Demand', link: '/job-demands/job-requirements', icon: <TableOutlined style={{ fontSize: '30px' }} />, visible: 'allUsers' },
    //     { currentMenu: 'LEADS', key: 'action_needed', heading: 'Action Needed', link: '/leads/action-needed-candidates', icon: <TeamOutlined style={{ fontSize: '30px' }} />, visible: 'teamMembers' },
    //     { currentMenu: 'INCENTIVES', key: 'incentives', heading: 'Incentives', link: '/incentives', icon: <PercentageOutlined style={{ fontSize: '30px' }} />, visible: 'managers' },
    //     { currentMenu: 'PERFORMANCE_DASH', key: 'performance_dashboard', heading: 'Performance Dashboard', link: '/report/performance-dashboard', icon: <DashboardOutlined style={{ fontSize: '30px' }} />, visible: 'allUsers' },
    //     { currentMenu: 'TRAINING_HUB', key: 'training_section', heading: 'Training Section', link: '/training-hub', icon: <BookOutlined style={{ fontSize: '30px' }} />, visible: 'allUsers' },
    //     { currentMenu: 'HELP', key: 'help_and_support', heading: 'Help & Support', link: '/help', icon: <IssuesCloseOutlined style={{ fontSize: '30px' }} />, visible: 'allUsers' }
    // ],
    REFER_AND_EARN_TNC: [
        {
            id: 0,
            value: 'Payout will not be made for any fraud trips.'
        },
        {
            id: 1,
            value: 'Your friend should complete 5 valid FTs in his/her first month of registration.'
        },
        {
            id: 2,
            value: 'Vahan has all the rights to decide on payouts going to the Vahan Leader on the referral program.'
        }
    ],
    CLIENT_AND_JOB_CATEGORY_MAPPING: {
        'uber': 'Driver',
        'uber_3pl': 'Driver',
        'rapido': 'Driver',
        'rapido_3pl': 'Driver',
        'shadowfax': 'Delivery',
        'zomato': 'Delivery',
        'dunzo': 'Delivery',
        'swiggy': 'Delivery',
        'zepto': 'Delivery',
        'zepto_3pl': 'Delivery',
        'grab': 'Delivery',
    },
    NAVIGATION_TABS: {
        'HOME_PAGE': {
            key: 'home',
            label: 'Home',
            link: '/home',
            icon: <HomeOutlined />,
            id: 'home-icon',
            isMobileView: true,
            mobileId: 'home-footer-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'LEADS': {
            key: 'leads',
            label: 'Candidates',
            link: '/recruiter-candidates',
            optionalLink: '/leads/all-candidates',
            icon: <UsergroupAddOutlined />,
            id: 'candidates-icon',
            isMobileView: true,
            mobileId: 'candidates-footer-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'SAMVADINI': {
            key: 'samvadini',
            label: 'AI Hot Leads',
            link: '/samvadini-v2',
            optionalLink: '/samvadini',
            icon: <PhoneOutlined />,
            id: 'call-icon',
            isMobileView: false,
            mobileId: 'candidates-footer-icon',
            roles: ["admin",'teamLeader', 'teamMember']
        },
        'JOB_DEMANDS': {
            key: 'job-demands',
            label: 'Jobs',
            link: '/job-demands/job-requirements',
            icon: <GroupOutlined />,
            id: 'demand-hub-icon',
            isMobileView: true,
            mobileId: 'demand-hub-footer-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'PERFORMANCE_DASH': {
            key: 'performance-dashboard',
            label: 'Performance',
            link: '/report/performance-dashboard',
            isMobileView: true,
            icon: <PieChartOutlined />,
            mobileId: 'report-footer-icon',
            mobileLabel: 'Report',
            roles: ["admin"]
        },
        'PAYOUT_DASH': {
            key: 'payout-dashboard',
            label: 'Payout',
            link: '/report/payout-dashboard',
            isVisible: false,
            roles: ["admin"]
        },
        'TEAM_PERFORMANCE': {
            key: 'team-performance-dashboard',
            label: 'Team Performance',
            link: '/report/performance-dashboard',
            isMobileView: true,
            icon: <PieChartOutlined />,
            mobileId: 'report-footer-icon',
            mobileLabel: 'Team Performance',
            roles: ['teamLeader', 'teamMember']
        },
        'INCENTIVES': {
            key: 'incentives',
            label: 'Incentives',
            link: '/incentives',
            icon: <PercentageOutlined />,
            id: 'incentive-hub-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'HOTLINE': {
            key: 'hotlineDownload',
            label: 'Get Hotline App',
            link: '/download-hotline-app',
            icon: <PhoneFilled />,
            id: 'call-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'NOTIFICATION': {
            key: 'notification',
            label: 'Inbox',
            link: '/notification',
            icon: <BellOutlined />,
            id: 'notification-footer-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'VAHAN_CONVERTED_USERS': {
            key: 'vahan-converted-users',
            label: 'Vahan Converted Users',
            link: '/converted-leads',
            icon: <UsergroupAddOutlined />,
            id: 'candidates-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'CLIENT_UPDATES': {
            key: 'client-updates',
            label: 'Client Updates',
            link: process.env.REACT_APP_CLIENT_UPDATES_LINK,
            icon: <HddOutlined />,
            id: 'client-update-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'V_CARE': {
            key: 'v-care',
            label: 'V Care',
            link: '',
            icon: <DollarCircleOutlined />,
            id: 'v-care-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'TRAINING_HUB': {
            key: 'training-hub',
            label: 'Training',
            link: '/training-hub',
            icon: <BookOutlined />,
            id: 'training-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'REFER_AND_EARN': {
            key: 'refer-and-earn',
            label: 'Refer & Earn',
            link: '/refer-and-earn',
            icon: <DollarCircleOutlined />,
            id: 'refer-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'ACCOUNT': {
            key: 'account',
            label: 'Account',
            link: '/account',
            icon: <SettingOutlined />,
            id: 'account-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'HELP': {
            key: 'help',
            label: 'Help',
            link: '/help',
            icon: <IssuesCloseOutlined />,
            id: 'help-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'FAQ': {
            key: 'profile',
            label: 'FAQs',
            link: process.env.REACT_APP_FAQ_LINK,
            icon: <QuestionCircleOutlined />,
            id: 'faqs-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'LOGOUT': {
            key: 'logout',
            label: 'Logout',
            link: '',
            icon: <LogoutOutlined />,
            id: 'logout-icon',
            isVisible: false,
            roles: ["admin", 'teamLeader', 'teamMember']
        },
        'MORE': {
            key: 'more',
            label: 'More',
            link: '/more',
            icon: <MenuOutlined />,
            id: 'more-footer-icon',
            isVisible: false,
            isMobileView: true,
            mobileId: 'more-footer-icon',
            roles: ["admin", 'teamLeader', 'teamMember']
        }
    },
    RAISE_ISSUE_TYPES: [
        'Client Activation Issue',
        'Document Issue',
        'Data Missing from the trackers',
        'Rider login Issue',
        'Rider Payout Issue',
        'Vahan Payout Issue',
        'Orders/Trips related',
        'Vahan App Issue',
        'Client App Issue',
        'BGC Issue',
        'Slot/Vertical Issue',
        'Location/Store/Shift Change',
        'Account Manager related',
        'Others'
    ],
    FRESHDESK_GROUP_ID: {
        "MITRA_LEADER_ESCALATION": 84000268346,
    },
    REFRESH_DOCUMENT_STATUS_MENU: [
        {
            key: 'rapido',
            label: 'Rapido ( Last 24 hours )'
        },
        {
            key: 'rapido_3pl',
            label: 'Rapido_3PL ( Last 24 hours )'
        }
    ],
    SIGNUP_PAGE_LOGO_CLIENTS: [
        "Dunzo",
        "Grab",
        "Rapido",
        "Swiggy",
        "Uber",
        "Zepto_3PL",
        "Zomato",
        "Delhivery_3PL"
    ],
    LOU_TOURS: {
        voiceNote: '028108098972'
    },
    APPLICATION_CREATION_STAFFING_CLIENTS: [
        'bigbasket_3pl',
        'rapido_3pl',
        'delhivery_3pl',
        'zomato_3pl',
    ],
    statusToMilestoneMaping: {
        "referral_date_si": "Completed referrals",
        "activation_date": "Activations",
        "first_date_of_work": "F1 ~ First Trip",
        "10th_order_date": "F10 ~ First 10 Trips",
        "15th_order_date": "F15 ~ First 15 Trips",
        "20th_order_date": "F20 ~ First 20 Trips",
        "25th_order_date": "F25 ~ First 25 Trips",
        "30th_order_date": "F30 ~ First 30 Trips",
        "35th_order_date": "F35 ~ First 35 Trips",
        "40th_order_date": "F40 ~ First 40 Trips",
        "45th_order_date": "F45 ~ First 45 Trips",
        "50th_order_date": "F50 ~ First 50 Trips",
        "60th_order_date": "F60 ~ First 60 Trips",
        "75th_order_date": "F75 ~ First 75 Trips",
        "80th_order_date": "F80 ~ First 80 Trips",
        "100th_order_date": "F100 ~ First 100 Trips",
        "200th_order_date": "F200 ~ First 200 Trips",
        "mitra_app_downloads_before_onboarding": "Pre Onboarding",
        "mitra_app_downloads_after_onboarding": "Post Onboarding",
        "Partially Approved": "Partially Approved",
        "Rejected": "Rejected",
        "Approved within 3 days from FT": "Approved within 3 days from FT",
        "Approved at FT Count": 'Approved at FT Count'
    },
    zeptoMandatoryDocToElementMapping: {
        'bankPassbookOrCancelledCheque': '2d9b11b9-43a6-4fc1-b025-3f1f3c7085b8',
        'aadhaarCard': '11f733df-554e-48f1-8b2f-d0ef868d20f3',
        'panCard': 'd3ef8e38-5f43-44b2-b75b-4105a155eae7',
        'userSelfie': 'dd1b1952-8f0e-4d40-8ee4-81fdc1063db0',
        'drivingLicense': '7bfc0c27-6eb2-4347-89f5-516b9dcd1a5f'
    },
    crmExceptionMitras: [
        'd8d6f51e-83ff-45a2-863f-acc633b638c2',
        '2dbfebb5-0756-40c9-a41f-86b49858a084',
        '9dbb09bb-a198-42fc-a923-3666ca4fe8d4',
        '5884c908-572b-4887-ab95-574c718d32c9',
        '36e3e751-1b3c-4abf-8551-ee250055c015'  // naman's account id
    ],
    zomatoAdditionalFormEligibleMlIds: [
        'd91b67cf-5dab-4031-9600-50e9f5ed56e7', //gaurav's ml id
        '9dbb09bb-a198-42fc-a923-3666ca4fe8d4',
        'c220dcf1-a262-48aa-8ff3-05218a74138e'
    ],
    blinkitAdditionalFormEligibleMlIds: [
        'b20059ce-9346-4a6e-926c-a0f0a4d6f396', // Sagar's VL ID
        '102642ad-9799-4801-83d5-4dfc1e0eb0f2', // Mantosh Kumar Email Id
        'ffe2744a-1187-4203-bd7a-b33e341f4f5b',
        'd3426156-86fe-4385-a365-47cb8a03454f',
        '7e0a2078-4c4d-4d0e-b1b3-29d8b1f5f739',
        '68cc32d1-5409-4ab6-8cc6-5197140193ba',
        '6f4fe38c-3a28-4cd4-8d2c-2bd7f352335f',
        'ddff4faa-0e8e-4d1d-b6d6-50094ffd5c19',
        'eeb333f1-7f5a-4730-9c8c-bf02c5ade949', // Manuraj VL ID followed by his team
        'c74cb0c0-3ded-4ec1-bd4e-38cccb4aadaa',
        '0d8bf5b7-89e7-4e93-bd5a-cc32e86d0bf1',
        'd1be050e-0b52-4958-adb1-ce7e8b1ba9f6',
        'd0db85be-08b8-4ca0-bdde-76e3dd034818',
        '8b09654a-7692-46ad-aeb6-49f65ca4d05a',
        '5843fd08-7902-4e55-a2f2-4f4eadb9e1e0',
        '4c6e3229-e4b2-4c28-b2b2-c9a3f36e72d1',
        '4dfa1751-d402-4ada-adbe-f589afd27446',
        'd4593f7b-f845-4132-b9d0-0f3118ce4ccd', // Rohit Pandey ML id
    ],
    expectionMitrasForCandidatesPageReDirection: [
        '8fa5708a-ea7e-45be-8601-7ed22399213a' // My Smart Buy - 9902316557
    ],
    highIntentEligibleMitras: [
        'd91b67cf-5dab-4031-9600-50e9f5ed56e7', //gaurav's ml id
        'd91b67cf-5dab-4031-9600-50e9f5ed56e7',
        'fd00f61f-ca9c-49e3-86cb-98eaeb4aef67',
        '78297f9f-e46e-47c9-8695-a12a8ba79a1b',
        '49e2d5d9-3a87-4a98-abcc-06b676724e7e',
        '30fb74d5-1e5a-4564-aaf5-2ed86a3974eb',
        '72d24774-41aa-4158-9792-6988a092ba4d',
        'cde5b739-63f0-4d91-8dd6-1a28c43fc500',
        'c220dcf1-a262-48aa-8ff3-05218a74138e',
        'ece04ff9-234d-46d1-9a78-6ae2da52df47',
        '92206b52-3c8c-4adf-993c-ec9a1fd6224a',
        '28879ead-bcce-494b-a8ab-6a7d962baf42',
        '3904f9e3-764d-4d25-b5ca-c48a3ef0aa08',
        '27ba626f-701e-49be-9823-67f9e883db17',
        'df24719b-b6dd-4dd0-a0b0-124bc61a23f5',
        '5884c908-572b-4887-ab95-574c718d32c9',
        '0c376055-144c-443b-89d7-3f09d7956100'
    ],
    swiggyAdditionalFormEligibleMlIds: [
        'b20059ce-9346-4a6e-926c-a0f0a4d6f396', // Sagar's ML Id
        "d8d6f51e-83ff-45a2-863f-acc633b638c2", // N Praveen's ML Id followed by his team
        "e4e72b85-eafd-439a-af89-b1db994aed23",
        "52b9441e-2edc-48a3-8a79-0cf571293fa6",
        "a24347fe-3664-434d-a315-6a0172912422",
        "174ca7e1-612d-473c-a6ba-df5a16082f34",
        "2f2d685f-9d73-43f4-ba71-be3625b2f1df",
        "20ce2019-2a56-478a-8f2e-053c43f426f7",
        "a1fcb5e7-7cb9-42f3-82bb-4c1771ed82e6",
        "fe31757a-2e6f-4909-ba9d-f13c11372a39",
        "7c48ac69-fbcb-4309-81f1-cb2536c7ad91",
        "5eb2ee22-4dec-4229-ba3c-073f394f2e0b",
        "e6c0e4f0-f593-4fc4-a774-53ee65745f68",
        "10edfaef-e0c2-4fbd-9d6c-07d68403adab",
        "bb221b39-edfe-4e77-8ca7-354a82f1c1ce",
        "e84c40d7-5a08-4231-bf29-4ba457c88095",
        "fc318d8e-e861-4687-b400-ce9ead21fa0b",
        "caf4e13f-a2cb-4e63-a69d-ba56c4654d13",
        "5532a33b-1e40-4093-b10d-fd2765c38e7d",
        "90c71006-829c-4f07-af48-4dc8cfa2ee3e",
        "0fb97a9f-ca08-4adf-9376-ad9c17691b14",
        "5966f2eb-e118-493f-b07f-08a7118cb0d1",
        "13360fe3-aa17-4f2c-b472-46be6fb45c84",
        "139b098d-74f5-4aa0-907c-9f374cc1ef36",
        "d18e0c85-d487-4d0c-a8a3-03327f9a8184",
        "48299ac7-1ff8-47a0-8624-f11ea44a5365",
        "b191ec18-32b0-4d35-ae44-9e501c26fe9f",
        "cc4dc083-6927-4d2c-9838-b1fd33ee6f5f",
        "cc228b66-567c-473d-b818-def18c6ad57b",
        "a77c6010-a213-4020-9dab-1ceb380f8575",
        "6a51aa58-0220-4fef-a171-edc9d636e099",
        "eaafd70f-fbea-4c84-bfcb-156e01207d67",
        "c36ef9f7-869f-4b30-bd79-02c2ace20c4d",
        "8f90f7dd-cf32-427b-8a12-c5182ce925d5",
        "5c39be53-3381-4ac0-bf12-529fb8c83295",
        "23089400-b440-4a5e-b05e-283b51c3c8dd",
        "5a8a91d4-c1de-4c09-a959-ebdb264d6855",
        "51d82373-cf19-4999-ae4f-2814f367f90c",
        "672dba29-5947-4f32-9e0e-9134fa72c0e4",
        "f37cd6ee-8c4d-46e0-a639-f69f5fd8425a",
        "0e4d14ed-a968-4078-953e-afa1b2edf712",
        "6ba92bed-ff80-4083-8197-200e8c7a9b7c",
        "efb51e7c-d2ae-49cc-93ae-222b36a9fe0d",
        "2f76aa44-1631-46c0-ab59-4ab8cacc007b",
        "2bebdd0f-39ea-497a-b4fa-d3fe7dcd73de", // Tekhunt's id followed by his team
        "ee879829-323e-4dfd-890f-f24f2edbd331",
        "a32389de-4f49-4b90-afe1-e5d128c07cfd",
        "66c27f3b-6a16-4a01-a519-cc5571b44563",
        "2ab4930a-4e1d-437f-ad9c-6dff744d06f8",
        "a6f1e876-f4a7-4b58-a7ff-89de3917b636",

        "92206b52-3c8c-4adf-993c-ec9a1fd6224a", // Sure staffing services id followed by his team
        "dc46d4e5-682f-46e5-89be-b702426a793e",
        "14326483-723f-4e08-b6e1-c0f79e07c482",
        "dd36b013-c588-4c0e-a27a-0ddae02e93ce",
        "d80ca2fb-65c0-4ac1-a1df-9bc317bb6d73",
        "b9573bb6-a933-4e1d-93e4-eca859bec081",
        "53260c6f-96ac-4c6c-8987-125d6d5e22c1",
        "2d5e5a9e-37b6-4913-a056-6c3ef2b427a9",
        "bf9ffd40-e0fd-49d5-b5c3-0b7ad4a47f9d",
        "09a2cb1b-75fc-4df7-8890-1762bb68f1c9",

        "9dbb09bb-a198-42fc-a923-3666ca4fe8d4", // Talent scope id followed by his team
        "5475cedc-29ef-4ee1-9f93-e12bf1a15980",
        "6825f7ce-002b-4e1b-9862-5749d419978d",
        "040e1abd-da17-4c06-93d5-e7fde4ddbc84",
        "35ff2a05-a36d-4228-b4da-9f7edab4b0a6",
        "30cb8098-24d6-4c95-a09c-c2e2766ebc01",
        "eed4761f-8328-49ab-a213-30652b2528d4",
        "6c4e21f6-3433-48e0-8cc9-c3d48b03780e",
        "20907e97-dbdd-4e55-aa97-ccab09458bc1",
        "e86ed7ad-63a9-464b-8b00-873ee6a0a954",
        "5bd3bf71-f1d5-4259-8908-0add97b9e5d7",
        "a60dca2e-ebc5-43b0-a576-de758ce432fc",
        "dc817c8c-8831-4b7d-bbf4-a9904abbf55a",
        "fa1836eb-af90-448c-95ed-45cc732282d5",
        "1d4e6f5c-e3b5-4f18-8cf4-5a91278554fe",
        "e1a0a1c6-ae2e-4e67-8c56-73323f6d3986",
        "1148eb19-349a-4843-8ae0-fc9fad93e36f",
        "2559bd4f-58e5-4eb9-9bb9-ccdf86ca4941",
        "7c4a7ced-b77e-4326-9455-153f42fc689b",
        "06f9b500-28d7-493a-95ea-ba3ddf86804a",
        "545d58cc-bf7c-4349-94bb-6650152d3f35",
        "271d627c-8fa1-45c7-96fa-3125d4129005"

    ],
    samvadiniCallingEligibleIds: [
        '36e3e751-1b3c-4abf-8551-ee250055c015', // Naman's ID
        'b20059ce-9346-4a6e-926c-a0f0a4d6f396', // Sagar's ML Id
        'df24719b-b6dd-4dd0-a0b0-124bc61a23f5', // VMC ML Id,
        // '9dbb09bb-a198-42fc-a923-3666ca4fe8d4', // Talent scope's VL Id
        '78297f9f-e46e-47c9-8695-a12a8ba79a1b', // Carro Meta Sol PL
        'd1557b99-f1c2-4251-bac4-90003b78adb7', // Ionik Unlimited ML Id
        '8fa5708a-ea7e-45be-8601-7ed22399213a', //My Smart Buy
        'd8d6f51e-83ff-45a2-863f-acc633b638c2', //N Praveen Kumar
        '49e2d5d9-3a87-4a98-abcc-06b676724e7e', //Ever Staffing Services pvt ltd
        '92206b52-3c8c-4adf-993c-ec9a1fd6224a', //SURE STAFFING SERVICES
        'b51c2ed8-f046-4242-b11c-e1c60b1288bd', // Jainendra Agrawal
        '9e361a1b-fbec-449a-a83e-44679fc1539e', // Austin Allen Assure BPO LLP
        '2dbfebb5-0756-40c9-a41f-86b49858a084', // Hemant (Rishits call on 11th Jul'24)
        'f538ec93-9c04-4239-9566-e1d4bc6e5fdf', // MANISH K
        'c4a9f396-1c69-46b6-ad85-1d373c6b1a7d', // Direct Channel
        'e075bb65-90f1-40e0-a37b-13fcb3257f0e', // Prakhar Katiyar
        'eeb333f1-7f5a-4730-9c8c-bf02c5ade949' // Manuraj
    ],
    hotlineDownloadApplicableIds: [
        'b20059ce-9346-4a6e-926c-a0f0a4d6f396', // Sagar's ML Id
        '2dbfebb5-0756-40c9-a41f-86b49858a084', // Hemant (Rishits call on 11th Jul'24)
        'f538ec93-9c04-4239-9566-e1d4bc6e5fdf', // MANISH K
        '92206b52-3c8c-4adf-993c-ec9a1fd6224a', //SURE STAFFING SERVICES
        'd8d6f51e-83ff-45a2-863f-acc633b638c2', //N Praveen Kumar
        'df24719b-b6dd-4dd0-a0b0-124bc61a23f5', // VMC ML Id,
        '8fa5708a-ea7e-45be-8601-7ed22399213a', //My Smart Buy
        '78297f9f-e46e-47c9-8695-a12a8ba79a1b', // Carro Meta Sol PL
        'ece04ff9-234d-46d1-9a78-6ae2da52df47', //Sameer Pratap Singh
        '9e361a1b-fbec-449a-a83e-44679fc1539e', // Austin Allen Assure BPO LLP
        '62d98651-e693-4d0f-acda-ad379fdde7f2', //Qelite
        '2df1e053-d779-4a4a-8615-d89bf95e0b94', //Indian Group
        'c862353d-dc63-4d86-a0d0-203ce8ef5515', //Piyush Vijay
        'c36a82af-87d5-4e35-85e0-72c595007cb6', //Hariom Nagar
        'c4a9f396-1c69-46b6-ad85-1d373c6b1a7d', // Direct Channel
        '58e3b8f3-c3ac-4d23-b1cb-d82381d58dae', //Anand N
        'a6614aef-0412-4c4c-bb48-b056eceefb10', //Elite4U
        '72d24774-41aa-4158-9792-6988a092ba4d', //JKS
    ],
    aiFilteringEligibleLeads: [
        'b20059ce-9346-4a6e-926c-a0f0a4d6f396',
    ],
    swiggyTshirtSizeOptionFallbackResponse: {
        "item_id": "55968a61-8908-42f3-9982-592c056ac092",
        "item_name": "T-Shirt",
        "item_title": "T-Shirt",
        "image_link": "https://roadrunner-cdn.swiggy.com/images/rider_profile_view.webp",
        "price_details": [
            {
                "size": "S",
                "price": 280,
                "discounted_price": 0
            },
            {
                "size": "M",
                "price": 280,
                "discounted_price": 0
            },
            {
                "size": "L",
                "price": 280,
                "discounted_price": 0
            },
            {
                "size": "XL",
                "price": 280,
                "discounted_price": 0
            },
            {
                "size": "XXL",
                "price": 280,
                "discounted_price": 0
            },
            {
                "size": "XXXL",
                "price": 280,
                "discounted_price": 0
            }
        ],
        "is_editable": false,
        "qty": 0,
        "discount_percentage": 100
    },

    BULK_REFERRAL_ACCESS: "bulkReferralAccess",
    DOWNLOAD_DATA_ACCESS: "downloadDataAccess",
    VIEW_DATA_ACCESS: "viewDataAccess",
    BRANDING_ACCESS: "brandingAccess",
    HOT_LINE_WAITING_TIME: "HotLineWaitingTime",
    NON_CUSTOM_DOMAINS: ["localhost", "mitra-leader.vahan.co", "stg.mitra.vahan.co"],
    SAMVAADINI_SESSION_CREATION_OPTIONS: [
        {
            id: 0,
            image: '/image/ai-smart-logic.png',
            isHighlighted: true,
            header: 'Smart Logic',
            subHeader: 'Only two clicks to start filtering leads quickly',
            buttonText: 'Get Started',
            onButtonClick: (history)=>history.push('/samvadini-smart-logic'),
            showFlag: true,
            flagLabel: 'Recommended',
            flagBgColor: '#9664FF',
            icon: <ThunderIcon />
        },
        {
            id: 1,
            image: '/image/adv-filter.png',
            isHighlighted: false,
            header: 'Advance Filter',
            subHeader: 'Use filters to quickly select leads you want',
            buttonText: 'Get Started',
            onButtonClick: (history)=>history.push('/samvadini-advance-filter'),
            showFlag: true,
            flagLabel: 'New',
            flagBgColor: '#45CA2F',
            icon: <AdvFilterIcon />
        },
        {
            id: 2,
            image: '/image/file-upload-samvaadini.png',
            isHighlighted: false,
            header: 'File Upload',
            subHeader: 'Upload your Excel and filter your leads using AI',
            buttonText: 'Get Started',
            onButtonClick: (history)=>history.push('/samvadini-file-upload'),
            showFlag: false,
            icon: <FileAddIcon />
        }
    ]
};

export default CONSTANTS;
