import React from 'react'
import '../../style/BulkReferralStyle.scss'
import { ArrowRightOutlined, CheckCircleOutlined, UserOutlined, ClockCircleOutlined, ExclamationCircleOutlined, DownloadOutlined, ForwardFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../service/engagementMonitoringService';
import CONSTANTS from '../../../../constants/constants';

const BulkReferralSummary = ({
    summaryData = [
        {
            "bulkUploadId": "4c7b6ea1-5fbd-4bbc-b7fe-05520a5bed86",
            "uploadedBy": "f8c0c823-ea3b-4af3-911d-6e8eece50e9b",
            "totalLeads": 13,
            "uploadedURL": "https://uploads-a.vahan.co/bulk upload (1)_1709529003221.xlsx",
            "errorReportUrl": "https://uploads-a.vahan.co/development/bulk_referral_response_sheet_2024-03-04T05:10:10.647Z.xlsx",
            "processStatus": "Created",
            "bulkUploadUniquenessLeadId": "cb177eab-8867-440c-8fc0-9b9528724980",
            "metaData": {
                "companyName": "zomato",
                "jobLocation": "Bellandur & Sarjapur road"
            },
            "city": "Bengaluru",
            "uploadedAt": "2024-03-04T05:10:03.221Z",
            "processedLeads": "13",
            "nonUnique": "0",
            "unique": "0",
            "failedCount": "6"
        }
    ]
, isAiFilteringEnabled}) => {

    const history = useHistory();

    const downloadCandidateStatusClicked = () => {
        trackEvent('bulk_referral_download_candidate_status_from_summary_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        const link = document.createElement('a');
        link.href = summaryData.errorReportUrl;
        link.download = 'leadsReferredStatus.xlsx'; // Specify the desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className='br-step-parent br-summary-parent d-flex justify-content-between'>
            <div className='br-summary-content-container d-flex justify-content-center align-items-center'>
                <div className='br-summary-content d-flex'>
                    <CheckCircleOutlined className='br-summary-processed-icon' />
                    <div className='br-summary-processed-txt'>
                        Your file has been processed
                    </div>
                </div>

                <div className='br-summary-info'>
                    <div className='br-summary-total-container br-summary-section d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <UserOutlined className='br-summary-total-icon d-flex' />
                            <div className='br-summary-total-txt br-summary-txt'>
                                Total Candidates
                            </div>
                        </div>

                        <div className='br-summary-processed-number br-summary-number-txt'>
                            {summaryData && summaryData.length > 0 && summaryData[0].totalLeads ? summaryData[0].totalLeads : ""}
                        </div>
                    </div>

                    <div className='br-summary-processing-container br-summary-section d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <ClockCircleOutlined className='br-summary-processing-icon' />
                            <div className='br-summary-processing-txt-parent d-flex br-summary-txt'>
                                <div className='br-summary-processing-txt'>
                                    Processing Candidates for Referral
                                </div>
                                <div className='br-summary-processing-sub-txt'>
                                    (takes about 2 hours for uniqueness check)
                                </div>
                            </div>
                        </div>

                        <div className='br-summary-processing-number br-summary-number-txt'>
                            {summaryData && summaryData.length > 0 && summaryData[0].processedLeads ? summaryData[0].processedLeads : ""}
                        </div>
                    </div>

                    <div className='br-summary-error-container br-summary-section d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <ExclamationCircleOutlined className='br-summary-error-icon d-flex' />
                            <div className='br-summary-error-txt br-summary-txt'>
                                Errors
                            </div>
                        </div>

                        <div className='br-summary-error-number br-summary-number-txt'>
                            {summaryData && summaryData.length > 0 && summaryData[0].failedCount ? summaryData[0].failedCount : ""}
                        </div>
                    </div>
                </div>
            </div>

            {
                isAiFilteringEnabled ? (
                    <>
                <ForwardFilled style={{color: '#FF712D80',transform:'rotate(90deg)', fontSize: '48px'}}/>

                <div className='ai-filteration-indicator'>
                    {/* <img src='/image/ai-bot.png' style={{height: '32px', width: '40px'}} alt='ai-bot'/> */}
                    <span className='regular16-24 ai-filteration-text'>AI Filtering will start once candidates have been referred</span>
                </div>
            </>
                ): null
            }
            

            <div className='br-summary-btn-container d-flex align-items-center'>

                <Button 
                style={{border: '1px solid #D34612'}}
                className='br-summary-goto-btn br-summary-btn-section d-flex align-items-center justify-content-center' 
                onClick={() => downloadCandidateStatusClicked()}>
                    <DownloadOutlined className='br-summary-btn-img-orange' />
                    <div className='br-summary-goto-btn-txt'>
                        Download Candidate Status
                    </div>
                </Button>

                <Button className='br-summary-download-btn br-summary-btn-section d-flex align-items-center justify-content-center' onClick={() => history.push('/bulk-actions')}>
                    <div className='br-summary-download-btn-txt'>
                        Go To Bulk Referrals
                    </div>
                    <ArrowRightOutlined className='br-summary-btn-img-white' />
                </Button>
            </div>

        </div>
    )
}

export default BulkReferralSummary; 