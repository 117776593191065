import axios from 'axios';

// Add a request interceptor 
var axiosInstance = axios.create();

let  cancelTokenSources  = {};

// Function to cancel any previous request of the same type
const  cancelPreviousRequest  = (key) => {
    if (cancelTokenSources[key]) {
        cancelTokenSources[key].cancel(`Operation canceled due to new request. Cancelled API: ${key}`);
        delete  cancelTokenSources[key];
    }
};


axiosInstance.interceptors.request.use(function async(config) {
    config.url = window.location.href.includes('gigshare') ? config.url.replace(process.env.REACT_APP_BACKEND_URL, process.env.REACT_APP_GIGSHARE_BACKEND_URL) : config.url; 
    const url = config.url;

    if(!url.includes('/swiggy/'))  { // disabling it for swiggy APIs
            cancelPreviousRequest(url);
            const source = axios.CancelToken.source();

            config.cancelToken = source.token;
            cancelTokenSources[url] = source;
        }

    return config
}, function (error) {
   // Do something with request error 
   return Promise.reject(error);
});

export default axiosInstance;
