import axios from "axios";
import axiosInstance from './interceptorService';

export const postBulkIntentDataForSamvadiniCalls = (data) => {

    const URL = `${process.env.REACT_APP_BACKEND_URL}/postBulkIntentCallData`;

    const formData = new FormData();
    formData.append('expectedLeadCount', data.expectedLeadCount);
    formData.append('sessionDuration', data.sessionDuration);
    formData.append('mitraId', data.mitraId);
    formData.append('type', data.type);
    formData.append('isManagerMitra', data.isManagerMitra);
    formData.append('files', data.files || JSON.stringify([]));

    return axiosInstance.post(URL, formData, { withCredentials: true });
};

export const getUploadedListOfFiles = ({mitraId}) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/intentCallingSessionList?mitraId=${mitraId}`, {withCredentials: true});
};

export const getSessionDetails = ({intentCallingSessionId}) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/intentCallingSessionDetails?intentCallingSessionId=${intentCallingSessionId}`, {withCredentials: true});
};

export const downloadInterestedLeads = ({intentCallingSessionId}) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/downloadIntentCallingSessionLeadDetails`, {intentCallingSessionId}, {withCredentials: true});
};

export const downloadInterestedLeadsByType = (payload) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/downloadIntentCallingSessionLeadDetails`, payload, {withCredentials: true});
};

export const getSessionList = (payload) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/ai-leads/session-list?sessionType=${payload?.type}&limit=${payload?.limit}&offset=${payload?.offset}`, {withCredentials: true});
};

export const getSessionsLandingScreenSummary = () => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/ai-leads/landingPage`, {withCredentials: true});
};

export const getSamvaadiniSummaryInformation = (formData) => {
    const URL = `${process.env.REACT_APP_BACKEND_URL}/preIntentCallSessionCreationInfo`;
    return axiosInstance.post(URL, formData, { withCredentials: true });
}

export const postSamvaadiniSession = (formData) => {

    const URL = `${process.env.REACT_APP_BACKEND_URL}/postBulkIntentCallData`;

    return axiosInstance.post(URL, formData, { withCredentials: true });
};

export const paginatedSessionDetails = ({intentCallingSessionId, limit, offset, type}) => {
    const url = process.env.REACT_APP_BACKEND_URL + `/ai-leads/sessionLeads?intentCallingSessionId=${intentCallingSessionId}&limit=${limit}&offset=${offset}&type=${type}`
    return axiosInstance.get(url, {withCredentials: true});
};

export const statisticCardInformation = (intentCallingSessionId) => {
    let url;

    if(intentCallingSessionId) {
        url = process.env.REACT_APP_BACKEND_URL + `/ai-leads/summary?intentCallingSessionId=${intentCallingSessionId}`;
    } else {
        url = process.env.REACT_APP_BACKEND_URL + `/ai-leads/summary`;
    }
    
    return axiosInstance.get(url, {withCredentials: true});
};