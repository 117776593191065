import React, { useState, useRef, useEffect, useContext, useReducer } from 'react';
import PreReferredLead from '../common/candidateApplication/PreReferredLead';
// import CandidateProfilePopupV2 from './candidateProfilePopupV2Components'
import { useHistory } from 'react-router-dom';
import moment from "moment";
import useGAEventsTracker from '../../service/useGSEventsTracker';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { Card, DatePicker, Button, Tag, Space, Typography, Skeleton, Pagination, Empty, Modal, Result, Select } from 'antd';
import { AppContext } from '../../App';
import { disabledDateLessThan25daysForHotLeads, } from './../../service/commonService'
import { LockOutlined, SearchOutlined, FireOutlined, FilterOutlined, WhatsAppOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { showHotLeadsPopUpBackend, displayexpiryDateChangedBanner, getPreReferredLeadData } from './../../service/leadService'
import SideSheet from "../common/SideSheet"
import axios from 'axios';
import PageHeaderComponent from '../common/PageHeader';
import '../../css/lead.scss';
import { useLocation } from "react-router-dom";
import WhatsappTemplateModal from '../whatsappTemplateModal/WhatsappTemplateModal';
import { getViewDetailsData } from './../../service/leadService';
import CandidateProfileWOReferral from './candidateProfilePopupV2Components/CandidateProfileWOReferral';
import * as queryString from 'query-string';
import NewLoader from '../common/NewLoader/NewLoader';
import SearchComponent from '../common/globalSearch';
import '../../css/globalStyles.scss';
import HotLeadsModal from '../leads/HotLeadsModal';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const PreReferralLeadsPage = () => {
	const location = useLocation();
	const [pathNameCandidates, setPathNameCandidates] = useState(window.location.href.indexOf("candidates") > -1)
	const stateData = JSON.parse(location.state ? location.state : '{}');
	const history = useHistory();
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	// const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AllCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	// const CriticalCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const { mitraReducer, setAlert, setSpin } = useContext(AppContext);
	const [leads, setLeads] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);
	const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [filterSider, setfilterSider] = useState(false);
	const [clickRangePicker, setClickRangePicker] = useState(false);
	const [breadcrumb, setbreadcrumb] = useState(pathNameCandidates ? [{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/hot-leads-candidates', label: 'Pre-Referral Leads' }] : [{ link: '/', label: 'Home' }, { link: '/hot-leads-candidates', label: 'Hot Leads' }])
	const [clientSelected, setClientSelcted] = useState([])
	const [statusesSelected, setStatusesSelected] = useState([]);
	const dateFormat = 'YYYY/MM/DD';
	const dateFormatList = ['DD-MM-YYYY', 'DD/MM/YY', 'DD/MM/YYYY', 'DD-MM-YY'];
	const [filterApplied, setFilterApplied] = useState(true)
	const [totalApplicationCount, setTotalApplicationCount] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(50)
	const [downloadLoading, setDownloadLoading] = useState(false)
	const [currentTab, setCurrentab] = useState('priority1')
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [candidatePostReferralSteps, setCandidatePostReferralSteps] = useState([]);
	const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
	const [leadDetails, setLeadDetails] = useState([]);
	const trackMoengageEvent = (event, obj) => {
		trackEvent(event, obj || {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
	const [showBanner, setShowBanner] = useState(false)
	const [trackUpdatedExpiryDate, setTrackUpdatedExpiryDate] = useState(false)
	const [tagsSelected, setTagsSelected] = useState([...CONSTANTS.RECRUITER_DASHBOARD_CALL_STATUS_TAGS]);
	const [searchText, setSearchText] = useState('');
	const [hasDownloadAccess, setHasDownloadAccess] = useState(false);
	const candidateCardRef = useRef(null);
	const [mitraEligibleForHotLeads, setMitraEligibleForHotLeads] = useState(false);
	const [lockHotLeads, setLockHotLeads] = useState(false);
	const [showHotLeadsModal, setShowHotLeadsModal] = useState(false);

	const handleScroll = () => {
		let leadsWrapperDiv = document.getElementsByClassName('leads-wrapper')[0];
		let backToTopBtn = document.getElementById("back-to-top");
		let y = leadsWrapperDiv.scrollTop;
		if (y >= 1500) {
			backToTopBtn.className = "cta showCta"
		} else {
			backToTopBtn.className = "cta hideCta"
		}
	};

	useEffect(() => {
		if (mitraReducer && mitraReducer.allowedFeatures) {
			setHasDownloadAccess(mitraReducer?.allowedFeatures[CONSTANTS.DOWNLOAD_DATA_ACCESS]?.isEnabledOverall)
		}
	}, [mitraReducer])

	useEffect(() => {
		if (mitraReducer?.mitraInfo) {
			setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
			setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
			const data = {
				mitraId: mitraReducer?.mitraInfo?.id
			}
			showHotLeadsPopUpBackend(data).then(result => {
				if (result && result.data && result.data.response) {
					if (result?.data?.response?.show && result?.data?.response?.eligibility) {
						setShowHotLeadsModal(result.data.response.show);
					}
					if (result.data.response.eligibility) {
						setMitraEligibleForHotLeads(result.data.response.eligibility);
						setLockHotLeads(result.data.response.locked);
					}
				}
			})
		}
	});

	useEffect(() => {
		console.log("called useEffect [mitra, filterApplied]");
		let element = document.getElementsByClassName('leads-wrapper')[0];
		element.addEventListener('scroll', handleScroll, { passive: true });
		if (mitra?.id && filterApplied) {
			setLoaded(false)
			let callStatusTagList = [];
				if(tagsSelected) {
					tagsSelected.map(tag => {
						if (tag.value) {
							if (tag.key === 'connected' || tag.key === 'notConnected') callStatusTagList.push(tag.key)
							else callStatusTagList.push(tag.label)
						}
					});
				}
				let data = {
					startDate: startDate,
					endDate: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
					offset: currentPage * pageLimit - pageLimit,
					limit: pageLimit,
					callStatusTagList: JSON.stringify(callStatusTagList),
				}
				console.log("currentTab before calling get Candidates Data API:>>", currentTab);
				const t0 = performance.now();
				getPreReferredLeadData(data).then((result) => {
					if (location.state) {
						openNextStepsPopUp()
					}
					setLoaded(true)
					setLeads(result.data.result);
					// if(result.data.count && Number(result.data.count) > 0){
					setTotalApplicationCount(Number(result.data.count))
					// }
					setFilterApplied(false)
					const t1 = performance.now();
					console.log(`Api getCandidatesData call took ${t1 - t0} milliseconds.`);
					trackEvent('candidates_page_load_time', {
						candidates_page_load_time: (t1 - t0)
					}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				})
			
			if (currentTab === 'all-candidates' && !trackUpdatedExpiryDate) {
				displayexpiryDateChangedBanner({ startDate: startDate, endDate: endDate }).then(response => {
					setShowBanner(response.data.showBanner)
				})
			}

	}
	}, [mitra, filterApplied])

	useEffect(() => {
		const params = queryString.parse(window.location.search);
		if (params?.startDate && params?.endDate) {
			handleDateRange([moment(params.startDate), moment(params.endDate)]);
		}
	}, []);

	const openNextStepsPopUp = () => {
		setTimeout(() => {
			let serialNo = 0;
			const steps = CONSTANTS.CANDIDATE_POST_REFERRAL_STEPS.map(ele => {
				if (ele?.clients?.includes(stateData?.clientPreference?.toLowerCase())) {
					serialNo++;
					if (!ele.hasClickableUrl) {
						return <h5 style={{ textAlign: 'left' }} key={'point' + serialNo}> {serialNo} {ele.message}</h5>;
					} else {
						// eslint-disable-next-line
						var urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
						let urls = ele.message.match(urlRegex)
						let msgBreakup = [];
						let msg = ele.message;
						if(urls) {
							urls.forEach((url) => {
								let index = msg.indexOf(url)
								msgBreakup.push(msg.slice(0, index));
								msgBreakup.push(msg.slice(index, index + url.length));
								msg = msg.slice(index + url.length, msg.length);
							})
						}
						msgBreakup.push(msg);
						let main = <span>
							{
								msgBreakup.map((element, index) => {
									if (urls.includes(element)) {
										return <a key={index} href={element} target='_blank' rel="noreferrer">{element}</a>;
									} else {
										return element;
									}
								})
							}
						</span>
						return <h5 style={{ textAlign: 'left' }} key={'point' + serialNo}> {serialNo}. {main} </h5>;
					}
				}
			})
			setCandidatePostReferralSteps(steps)
			setIsModalOpen(true)
		}, 500);
	}
	const deleteChip = (name, item) => {
		if (name === 'call_status') {
			let newCategory = [...tagsSelected]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setTagsSelected(newCategory)
		}
		setFilterApplied(true)
	}

	const handleDownload = (url) => {
		trackEvent('zomatoHotLeadsFileDownloadClicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		const link = document.createElement('a');
		link.href = url;
		link.download = 'preReferredLeadsStatus.xlsx'; // Specify the desired filename
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const handleSheet = async () => {
		if (leads.length > 0) {
			setDownloadLoading(true)
			let callStatusTagList = [];
			tagsSelected.map(tag => {
				if (tag.value) {
					if (tag.key === 'connected' || tag.key === 'notConnected') callStatusTagList.push(tag.key)
					else callStatusTagList.push(tag.label)
				}
			});
			const data = {
				offset: currentPage * pageLimit - pageLimit,
				limit: pageLimit,
				download: true,
				startDate: startDate,
				endDate: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
				callStatusTagList: JSON.stringify(callStatusTagList)
			}
			getPreReferredLeadData(data).then((response) => {
				if (response?.data?.message) {
					setAlert({
						display: true,
						text: response.data.message,
						type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
					});
				}
				if(response?.data?.url) {
					handleDownload(response?.data?.url);
				}
				setDownloadLoading(false)
			})
		}
		else {
			setAlert({
				display: true,
				text: 'No data Present',
				type: 'error'
			});
		}
	}

	const whatsAppMessageModalCancel = () => {
		setWhatsAppMessageModal(false);
	};
	const whatsappModalOpen = () => {
		const lead = leads.find(lead => lead.phoneNumber == stateData.phoneNumber)
		setSelectedLead(lead)
		if (lead?.recommendationHistoriesId || lead?.scheduledInterviewId) {
			let data = {
				recommendationHistoryIds: lead.recommendationHistoriesId,
				scheduledInterviewId: lead?.scheduledInterviewId,
			};
			getViewDetailsData(data).then((result) => {
				setLeadDetails(result.data);
				setWhatsAppMessageModal(true)

			});
		}
	}
	const scrollToTop = () => {
		trackEvent('Click_on_go_to_top_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		const leadsWrapper = document.getElementsByClassName("leads-wrapper");
		leadsWrapper[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}
	const handlePicker = () => {
		setClickRangePicker(!clickRangePicker);
	}

	const handleDateRange = (dates) => {
		setClickRangePicker(!clickRangePicker);
		if (dates === null) {
			setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
			setEndDate(moment());
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

	const clickHotLeadsModal = () => {
		const data = {
			mitraId: mitraReducer?.mitraInfo?.id,
			clicked: true
		}
		showHotLeadsPopUpBackend(data).then(result => {
			if (result && result.data && result.data.response) {
				if (result?.data?.response?.show && result?.data?.response?.eligibility) {
					if (result?.data?.response?.show) {
						trackEvent('hot-leads-popup-shown', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
					}
					setShowHotLeadsModal(result.data.response.show);
				}
				if (result.data.response.eligibility) {
					setMitraEligibleForHotLeads(result.data.response.eligibility);
					setLockHotLeads(result.data.response.locked);
				}
			}
		})
	}

	const clearAll = () => {
		clientSelected.map(item => {
			item.value = false;
		})
		statusesSelected.map(item => {
			item.value = false;
		})
		setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
		setEndDate(moment());
		setFilterApplied(true)
	}


	const onClickTags = (label, index) => {
		if (label === 'Call Status') {
			tagsSelected[index].value = !tagsSelected[index].value;
		}
		forceUpdate();
	}

	const preventDefault = (e, label, item) => {
		e.preventDefault()
		deleteChip(label, item)
		console.log('Clicked! But prevent default.');
	}

	const submitFilter = () => {
		setFilterApplied(true)
	}

	const commonTag = (label, arrObj) => {
		return <div className='tag-containers'>
			<div>{label}</div>
			{label === "Date Range" ?
				<Space>
					<RangePicker
						onClick={handlePicker}
						open={clickRangePicker}
						defaultValue={[moment(startDate, dateFormatList[0]), moment(endDate, dateFormatList[0])]}
						value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						format={dateFormatList}
						onChange={handleDateRange}
						ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
							'This Month': [moment().startOf('month'), moment().endOf('month')],
							'Last 7 days': [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],
							'Last 10 days': [moment().startOf('day').subtract(9, 'days'), moment().endOf('day')],
						}}
						disabledDate={disabledDateLessThan25daysForHotLeads}
						allowClear={false}
						className="range-picker-leads"
					/>
				</Space> :
				<div className='tag-wrapper-container'>
					{arrObj.map((item, index) => {
						return <Tag className={!item.value ? 'tags' : 'tags tag-active'}
							key={index}
							onClick={() => { onClickTags(label, index) }}
						>
							{item.label || item.name}
						</Tag>
					})}
				</div>
			}

		</div>
	}
	const onFilterClick = () => {
		setfilterSider(true)
		setClickRangePicker(true)
	};
	const openFilterSlider = () => {
		setfilterSider(true);
		setClickRangePicker(false);
	};

	const handlePageChangeSelection = (value) => {
		trackEvent('candidates_page_tab_switch', { tabName: value }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

		if (value === 'all') {
			history.push({ pathname: '/leads/all-candidates' })
		} else if (value === 'actionRequired') {
			history.push({ pathname: '/leads/action-needed-candidates' })
		} else if (value === 'leadsToFollowUp') {
			history.push({ pathname: '/recruiter-candidates' })
		} else if (value === 'hotLeads') {
			if (lockHotLeads) {
				clickHotLeadsModal();
			} else {
				history.push({ pathname: '/hot-leads-candidates' })
			}
		} else if (value === 'preReferralLeads') {
			history.push({ pathname: '/pre-referral-leads-candidates' })
		}
	}

	const getUpdatedExpiryApplications = () => {
		trackEvent('view_expiry_list_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		setShowBanner(false)
		setTrackUpdatedExpiryDate(true)
		setFilterApplied(true)
	}


	const searchCandidate = (e) => {
		setSearchText(e)
		setFilterApplied(true)
		setCurrentPage(1)
	}

	return (
		<>
			<HotLeadsModal
				showModal={showHotLeadsModal}
				setShowHotLeadsModal={setShowHotLeadsModal}
				mitraId={mitraReducer?.mitraInfo?.id}
			></HotLeadsModal>
			<div className="LeadPage mainContentZ">
				<div className="header">
					<PageHeaderComponent breadcrumb={breadcrumb} />
				</div>
				<div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row' }}>
					<div className='page-filter-options show-only-desktop'>
						{mitraEligibleForHotLeads ?
							<Button onClick={(e) => handlePageChangeSelection('hotLeads')} style={{ display: 'flex', alignItems: 'center', borderRight: 'none', borderRadius: 0 }}>
								{lockHotLeads ? <LockOutlined /> : <FireOutlined />}Hot Leads
							</Button> : null}
						<Button onClick={(e) => handlePageChangeSelection('preReferralLeads')} style={{ borderRadius: 0 }} type="primary" ghost>
							Pre-Referral Leads
						</Button>
						<Button onClick={(e) => handlePageChangeSelection('leadsToFollowUp')} style={{ borderRadius: 0, borderLeft: 'none' }}>
							Leads to Follow Up With
						</Button>
						<Button onClick={(e) => handlePageChangeSelection('all')} style={{ borderRadius: 0 }}>
							All
						</Button>
					</div>

					<div className= 'show-only-desktop' style={{ width: '40%', alignItems: 'flex-end', margin: '2%', display: 'flex', justifyContent: 'right' }}>
						<SearchComponent placeholder={"Search Candidates"} searchItem={searchCandidate} />
					</div>

					<Select className='show-only-mobile hot-leads-select'
						style={{ margin: '2%', height: 'fit-content', width: '60%', textAlign: 'left', color: '#D34612' }}
						onChange={handlePageChangeSelection} defaultValue="preReferralLeads">
						<Select.Option style={{ textAlign: 'left' }} value="leadsToFollowUp">Leads to Follow Up With</Select.Option>
						<Select.Option style={{ textAlign: 'left', color: '#D34612', backgroundColor: '#FFF' }} value="preReferralLeads">Pre-Referral Leads</Select.Option>
						{mitraEligibleForHotLeads ?
							<Select.Option className="hot-leads-select" style={{ textAlign: 'left' }} value="hotLeads">{lockHotLeads ? <LockOutlined style={{ marginRight: '2%' }} /> : <FireOutlined style={{ marginRight: '2%' }} />}Hot Leads</Select.Option>
						: null}
						<Select.Option style={{ textAlign: 'left' }} value="all">All</Select.Option>
					</Select>
					<div className='show-only-mobile-search'>
						<Button onClick={() => {
							history.push("/search-page")
						}} style={{ color: '#000000D9', borderColor: '#00000040' }} icon={<SearchOutlined />} danger ghost size={'medium'} ></Button>
					</div>
				</div>
				<div className="more-filters">
				<div className='d-flex justify-content-between'>
					<Space style={{ marginTop: '1%' }}>
						<RangePicker
							open={false}
							defaultValue={[moment(startDate, dateFormatList[0]), moment(endDate, dateFormatList[0])]}
							value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
							format={dateFormatList}
							onClick={onFilterClick}
							allowClear={false}
							className="range-picker-leads"
						/>
					</Space>
					<div className='bo-pagination show-only-desktop' style={{marginTop: 12}}>
                        {totalApplicationCount > 0 &&
                            <Pagination
                                total={totalApplicationCount > 0 && totalApplicationCount}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                defaultPageSize={10}
                                defaultCurrent={currentPage ? currentPage : 1}
                                responsive={true}
                                showLessItems={true}
                                onChange={(page, pagesize) => { setCurrentPage(page) }}
                                showSizeChanger={false}
                            />
                        }
                    </div>
					</div>
					<div className='filter-bottom d-flex' style={{gap: '8px', margin: '12px 0'}}>
						<Button className='d-flex align-items-center' style={{ color: '#D34612', border: '1px solid #D34612', flex: 1, fontWeight: 700 }} onClick={() => openFilterSlider()} icon={<FilterOutlined />}>Filter</Button>
						<div className="Chips-container" style={{flex:16,border: '1px solid #D9D9D9', padding:'2px', background: '#FFF'}}>
						{
							tagsSelected?.map((single_tag, index) => {
								if (single_tag.value) {
									return <Tag key={index} className="single-chip perf-dashboard-chip" closable onClose={(e) => preventDefault(e, 'call_status', single_tag)}>{single_tag.label} </Tag>
								}
							})
						}
						</div>
					</div>
				</div>

				{
					showBanner ?
						<div style={{ margin: '20px' }}>
							<div className="warning-message-at-risk-container">
								<div className="warning-message-atrisk" >
									<InfoCircleOutlined style={{ color: '#D34612', margin: '13px 10px' }} />
									<div className='flex-desktop-row-mobile-column'>
										<Text style={{ margin: '10px 0px' }}>Expiry dates for some of your candidates have changed due to updated time-gating conditions.</Text>
										<Text className='view-list-updated-expiry' onClick={() => getUpdatedExpiryApplications()}>
											<Button type="primary" style={{ margin: '5px 0px' }}>View list</Button>
										</Text>
									</div>

									{/* <CloseOutlined onClick={() => {setShowBanner(false)}} style={{margin: '5px 10px 0px auto'}} /> */}
								</div>
							</div>
						</div>
						: null
				}

				<Modal style={{ padding: '40px' }} key="informationModal" visible={isModalOpen} open={isModalOpen} onCancel={() => { setIsModalOpen(false); location.state = null; }} footer={[]}>
					<Result
						status="success"
						extra={[
							<h6 key="leadStatus" style={{ color: 'green' }}> Your lead has been referred.</h6>,
							<h3 style={{ textAlign: 'left' }} key="infoModalTextHeader">Complete the	 steps below to get your lead activated.</h3>,

							...candidatePostReferralSteps,
							<br key="break" />,
							<Button style={{
								background: '#53c31b',
								color: '#ffff',
								borderRadius: '5px'
							}} onClick={() => { whatsappModalOpen(); }} key="whtsappMessageButton">Message On <WhatsAppOutlined style={{ fontSize: '20px' }} /></Button>,
						]}
					/>
				</Modal>
				{
					whatsAppMessageModal ? <WhatsappTemplateModal
						lead={selectedLead}
						leadDetails={leadDetails}
						whatsAppMessageModal={whatsAppMessageModal}
						whatsAppMessageModalCancel={whatsAppMessageModalCancel}
					></WhatsappTemplateModal> : null
				}

				<div className='ba-uploads-refresh-download-section d-flex justify-content-between align-items-center'>
					<div className='ba-total-uploads-txt regular14-22' style={{margin: '16px 24px 2px'}}>
						{totalApplicationCount} uploads
					</div>
					<div className='ba-listing-above-btns d-flex'>
						{
							hasDownloadAccess ?
								<div style={{ width: '100%', textAlign: 'end', marginRight: '20px' }}>
									<Button style={{marginTop: 14}} className='vl-secondary-btn' loading={downloadLoading} onClick={() => { handleSheet(); AllCandidatesGAEventsTracker("DownloadAllCandidatesData"); }}>
										<span style={{color: '#D34612'}} className='bold14-22'>Download File</span>
									</Button>
								</div>
								:
								null
						}

					</div>
				</div>

				<div className="leads-wrapper">

					{loaded ? leads.length === 0 ? (
						<div style={{ margin: '20px auto', height: '68vh' }}>
							<Empty
								description={
									<span>
										<button onClick={() => history.push('/add-single-candidate')} style={{ textDecoration: 'underline', color: 'red', fontWeight: '600' }}>Refer Candidates</button> to see data here.
									</span>
								} />
						</div>
					) : (
						leads.map((lead, index) => (
							<Card
								className='card-view-candidate-without-referral'
								ref={candidateCardRef}
								hoverable>
								<PreReferredLead
									key={index}
									lead={lead}
									setSpin={setSpin}
									setShowDetail={setShowDetail}
									setSelectedLead={setSelectedLead}
									mitra={mitra}
								/>
							</Card>
						))
					) : (
						<div style={{ height: "65vh", width: "100%" }}>
							<NewLoader comingFromPage={'leadsPage'} />
						</div>
					)}
					<br />
					<div className="mobile-pagination" style={{}}>
						{
							totalApplicationCount > 0 ?
								<Pagination
									total={totalApplicationCount > 0 && totalApplicationCount}
									showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
									defaultPageSize={pageLimit}
									defaultCurrent={currentPage}
									responsive={true}
									showLessItems={true}
									onChange={(page) => { setCurrentPage(page); setFilterApplied(true) }}
									showSizeChanger={false}
								/>
								: null
						}
					</div>
					<br />
					<a id="back-to-top" className="cta hideCta" onClick={() => scrollToTop()}>↑</a>
					<br />
				</div>
				<div className="bottom-detail-wrapper">
					{
						showDetail && (
							<CandidateProfileWOReferral
								visible={showDetail}
								setShowDetail={setShowDetail}
								lead={selectedLead}
							>
							</CandidateProfileWOReferral>)
					}
				</div>
			</div>
			{filterSider &&
				<SideSheet submit={{
					disabled: false
					, onClick: submitFilter
				}}
					clearAll={{ disabled: false, onClick: clearAll }}
					sidername={"Filter Candidates"}
					onDismiss={() => { setfilterSider(false) }}>
					<div className='filter-home-wrapper'>
						{commonTag("Date Range", null)}
						{commonTag("Call Status", tagsSelected)}
					</div>
				</SideSheet>
			}
		</>

	);
};

export default PreReferralLeadsPage;
