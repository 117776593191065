import * as React from "react";
const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={14}
    viewBox="0 0 15 14"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1233_2896)">
      <path
        d="M12.8105 12.0419L2.25879 1.49023M12.8105 1.49023L2.25879 12.042"
        stroke="#252526"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1233_2896">
        <rect
          width={14.069}
          height={14.069}
          fill="white"
          transform="translate(0.5 -0.268555)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default CrossIcon;
