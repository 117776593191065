import React, { useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import {CopyOutlined, UserOutlined,ArrowLeftOutlined, PlusOutlined, AppstoreAddOutlined, PhoneOutlined, CloudDownloadOutlined,SlidersOutlined} from '@ant-design/icons'
import './styles/LeadListOfASession.css';
import { Avatar, Button, Divider, Empty, notification, Popover, Skeleton } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import InterestedCandidateCard from './components/InterestedCandidateCard';
import ProcessedCandidateCard from './components/ProcessedCandidateCard';
import StatisticCard from './components/StatisticCard';
import DownloadIcon from '../../static/svg/DownloadIcon';
import FilterIcon from '../../static/svg/FilterIcon';
import FoldingHands from '../../static/images/folding-hands.gif';
import LeftArrow from '../../static/svg/LeftArrow';
import { downloadInterestedLeads, downloadInterestedLeadsByType, getSessionDetails, paginatedSessionDetails, statisticCardInformation } from '../../service/SamvadiniApiService';
import moment from 'moment';
import PopoverDropdown from './components/PopoverDropdown';
import SessionListSkeleton from './skeleton/SessionListSkeleton';
import HumanCrossIcon from '../../static/svg/HumanCrossIcon';
import DownArrow from '../../static/svg/DownArrow';
import UpArrow from '../../static/svg/UpArrow';
import HumanSearchIcon from '../../static/svg/HumanSearchIcon';
import HumanCrossNonCircleIcon from '../../static/svg/HumanCrossNonCircleIcon';
import PhoneTickIcon from '../../static/svg/PhoneTickIcon';
import PhoneCrossIcon from '../../static/svg/PhoneCrossIcon';
import InfoIcon from '../../static/svg/InfoIcon';
import ScrollToTopButton from './components/ScrollToTopButton';
import animationData from '../../static/lottie/samvadini-loader.json'
import Lottie from 'react-lottie';
import NewCustomPagination from './components/NewCustomPagination';
import StatisticCardSkeleton from './skeleton/StatisticCardSkeleton';

function LeadListOfASession() {
    const scrollContainerRef = useRef(null);

    const totalItemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const history = useHistory();
    const [activeSessionDetailsTab, setActiveSessionDetailsTab] = useState('currentSession'); // 'currentSession' | 'pastSession'
    const [activeCandidateDetailsTab, setActiveCandidateDetailsTab] = useState('interested'); // 'interested' | 'all'
    const [searchTerm, setSearchTerm] = useState('');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const hideTabs = params.get('hideTabs');
    const sessionDetails = params.get('sessionDetails') && JSON.parse(params.get('sessionDetails'));
    const [completeResponsePayload, setCompleteResponsePayload] = useState({});

    const [showIneligibleLeadsDetails, setShowIneligibleLeadsDetails] = useState(false);
    const dropdownRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [statisticCardLoading, setStatisticCardLoading] = useState(false);

    const [allCandidatesPaginationLoader, setAllCandidatesPaginationLoader] = useState(false);

    const [sessionSummaryData, setSessionSummaryData] = useState({});

    const [interestedLeads, setInterestedLeads] = useState([]);
    const [allLeads, setAllLeads] = useState([]);

    const [inEligibleLeadsInfo, setInEligibleLeadsInfo] = useState({});
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    let pollingInterval;

    const [sessionInformation, setSessionInformation] = useState({});
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState([
        { id: 0, name: 'Client', isActive: false },
        { id: 1, name: 'City',isActive: false },
        { id: 2, name: 'Referral Date', isActive: false }
    ]);

    const [statisticCardData, setStatisticCardData] = useState([]);

    const goBack = () => {
        if(hideTabs) {
            history.push('/samvadini-session-list');
        } else {
            history.push('/samvadini-v2');
        }
    }

    const setStatisticCardInformation = (response) => {
        const statistic = [
            {
                id: 0,
                label: 'Leads Uploaded',
                count: 0,
                imageUrl: '/image/add-properties.png'
            },
            {
                id: 1,
                label: 'Connected',
                count: 0,
                imageUrl: '/image/call-ai.png'
            },
            {
                id: 2,
                label: 'Interested',
                count: 0,
                imageUrl: '/image/done-ai.png'
            }
        ]

        if(response?.data?.summaryData?.summary?.leadsUploaded) {
            statistic[0].count = response?.data?.summaryData?.summary?.leadsUploaded;
        }

        if(response?.data?.summaryData?.summary?.totalCallConnected) {
            statistic[1].count = response?.data?.summaryData?.summary?.totalCallConnected;
            statistic[1].percentage = ((parseInt(response?.data?.summaryData?.summary?.totalCallConnected)/parseInt(response?.data?.summaryData?.summary?.leadsUploaded))*100).toFixed(2);
        }

        if(response?.data?.summaryData?.summary?.totalInterestedLeads) {
            statistic[2].count = response?.data?.summaryData?.summary?.totalInterestedLeads;
            statistic[2].percentage = ((parseInt(response?.data?.summaryData?.summary?.totalInterestedLeads)/parseInt(response?.data?.summaryData?.summary?.totalCallConnected))*100).toFixed(2);
        }

        setStatisticCardData([...statistic]);
    }

    const clearPollingInterval = () => {
        if(pollingInterval) {
            clearInterval(pollingInterval);
        }
    }

    const getLeadListOfASession = async() => {
        setAllCandidatesPaginationLoader(true);
        try {

            const offset = (currentPage-1)*totalItemsPerPage;

            const payload = {
                intentCallingSessionId: sessionInformation.intentCallingSessionId,
                type: activeCandidateDetailsTab,
                limit: totalItemsPerPage,
                offset: offset
            }

            const response = await paginatedSessionDetails(payload);

            if(activeCandidateDetailsTab === 'all') {
                if(response?.data?.allLeads?.length) {
                    setAllLeads([...response?.data?.allLeads]);
                }

                if(response?.data?.totalLeads) {
                    setTotalItems(response?.data?.totalLeads);
                }
                
                setInterestedLeads([]);
            } else {
                if(response?.data?.interestedLeads?.length) {
                    setInterestedLeads([...response?.data?.interestedLeads]);
                }
                setAllLeads([]);
            }

            if(response?.data?.isSessionCompleted) {
                clearPollingInterval();
            }

            setCompleteResponsePayload(response?.data);

            setLoading(false);
            setAllCandidatesPaginationLoader(false);
        } catch(err) {
            setLoading(false);
            setAllCandidatesPaginationLoader(false);
            console.log(err);
        }
    }

    useEffect(() => {
        if(sessionDetails?.intentCallingSessionId) {
            setSessionInformation(sessionDetails);
        }
    }, [sessionDetails?.intentCallingSessionId]);

    const startPolling = () => {
        getLeadListOfASession();
        getSessionDetailSummary();
        pollingInterval = setInterval(() => {
            getLeadListOfASession();
            getSessionDetailSummary();
        }, 30000);
    }

    const getSessionDetailSummary = () => {
        if(sessionInformation?.intentCallingSessionId) {
            setStatisticCardLoading(true);

            statisticCardInformation(sessionInformation?.intentCallingSessionId).
            then(response=> {
                setStatisticCardInformation(response);
                setSessionSummaryData(response?.data?.summaryData || {});
                setInEligibleLeadsInfo(response?.data?.summaryData?.summary?.rejectionReasons || {});

                if(response?.data?.summaryData?.summary?.isSessionCompleted) {
                    clearPollingInterval();
                }

                setStatisticCardLoading(false);
            }).catch(err=> {
                setStatisticCardLoading(false);
                console.log(err);
            })
        }
    }
    
    useEffect(() => {
        console.log(sessionInformation, 'session info');
      if(sessionInformation?.intentCallingSessionId) {
        startPolling();
      }

      return () => {
        if(pollingInterval) {
          clearInterval(pollingInterval);
        }
      }
    }, [sessionInformation?.intentCallingSessionId])

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowIneligibleLeadsDetails(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const downloadExcel = () => {
        downloadInterestedLeadsByType({intentCallingSessionId: sessionInformation?.intentCallingSessionId, type: activeCandidateDetailsTab})
        .then(res=> {
            if(res?.data?.downloadUrl) {
                window.open(res.data.downloadUrl, '_blank')
            } else {
                notification['error']({
                    message: 'File download response error, please try again later.'
                })
            }
        }).catch(e=> {
            notification['error']({
                message: 'File download error, please try again later.'
            })
        })
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
      if(activeCandidateDetailsTab === 'all') {
        clearPollingInterval();
      }

      if(sessionInformation?.intentCallingSessionId) {
        getLeadListOfASession();
      }

    }, [activeCandidateDetailsTab, currentPage])
    

    // if(loading) {
    //     return (
    //         <SessionListSkeleton />
    //     )
    // }

    const toggleIneligibleSection = () => {
        console.log(showIneligibleLeadsDetails);
        setShowIneligibleLeadsDetails(prevState=>!prevState);
    }

  return (
    <>
    <div className='gray-main-container'  ref={scrollContainerRef}>
        {/* <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/> */}

        {
            searchTerm ? null : (
                <>
                <div className="back-navigation d-flex align-items-center" onClick={goBack}>
                    <LeftArrow />
                    <span className='back-text'>Back</span>
                </div>

                <span className='lead-list-hero-text d-flex'>
                    Leads Dashboard
                    <img src={FoldingHands} style={{width: '34px', height:'34px', marginLeft: '12px', marginTop: '-12px'}} />
                </span>

                {
                    hideTabs ? null: (
                        <div className="tab-and-btn-container d-flex justify-content-between">
                            <div className="session-tab-container">
                                <div 
                                className={`single-tab ${activeSessionDetailsTab === 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>setActiveSessionDetailsTab('currentSession')}
                                > 
                                    {
                                        activeSessionDetailsTab === 'currentSession' ? <div className="active-dot"></div> : null
                                    }
                                    
                                    Current Session
                                </div>

                                <div 
                                className={`single-tab ${activeSessionDetailsTab != 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>history.push('/samvadini-session-list')}
                                >
                                    Past Sessions
                                </div>
                            </div>

                            {/* <Button className='create-session-btn'><span className='create-session-btn-text'><PlusOutlined /> Create New Session</span></Button> */}
                        </div>
                    )
                }


                <div className="session-brief-description">
                    <div className="session-desc">
                        <div className="session-desc-individual-item">
                            <span className='session-desc-individual-item-header'>Date Created</span>
                            <span className='session-desc-individual-item-content'>
                                {sessionSummaryData?.summary?.createdAt ? moment(sessionSummaryData?.summary?.createdAt).format("DD/MM/YYYY"): null}
                            </span>
                        </div>
                        <div className="session-desc-individual-item">
                            <span className='session-desc-individual-item-header'>Time</span>
                            <span className='session-desc-individual-item-content'>
                                {sessionSummaryData?.summary?.createdAt ? moment(sessionSummaryData?.summary?.createdAt).format("hh:mm a"): null}
                            </span>
                        </div>
                        <div className="session-desc-individual-item">
                            <span className='session-desc-individual-item-header'>Status</span>
                            <span className='session-desc-individual-item-content'>{sessionSummaryData?.summary?.status === 'Completed' ? 'Done': 'Session in progress'}</span>
                        </div>
                    </div>
                    <div className="session-type-container">{sessionSummaryData?.summary?.type?.includes('Smart') ? 'Smart Logic': sessionSummaryData?.summary?.type}</div>
                </div>

                <Divider style={{width:'93%',margin: '16px auto', minWidth: '93%'}}/>

                {/* TILE CONTAINER */}
                <div className="card-tile-container margin-horizontal-32">
                    
                    {
                        statisticCardLoading ? <StatisticCardSkeleton />:
                        statisticCardData.map((item, idx) => {
                            return <StatisticCard key={idx} value={item}/>
                        })
                    }
                </div>

                <div className="ineligible-dropdown-container" onClick={toggleIneligibleSection} ref={dropdownRef}>
                    <div className='d-flex align-items-center' style={{gap:'6px'}}>
                        <HumanCrossIcon />
                        <span className='color-252526 inter-semibold-16-24'>{inEligibleLeadsInfo?.total || 0} Ineligible leads</span>
                    </div>

                    {
                        inEligibleLeadsInfo?.total ? (
                            <div className='d-flex align-items-center' style={{gap:'6px', cursor:'pointer'}}>
                                <span className='single-option-text color-252526 inter-medium-14-20'>View Details</span>
                                {
                                    showIneligibleLeadsDetails ? <UpArrow />: <DownArrow />
                                }
                            </div>
                        ): null
                    }
                    

                    {
                        showIneligibleLeadsDetails && inEligibleLeadsInfo?.total ? (
                            <div className='ineligible-leads-detail-section d-flex flex-column'>
                                {
                                    inEligibleLeadsInfo?.nonUnique ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <HumanSearchIcon />
                                                <span className='inter-medium-12-18'>Non unique leads</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.nonUnique} leads</span>
                                        </div>
                                    ): null
                                }

                                {
                                    inEligibleLeadsInfo?.notReferredByYou ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <HumanCrossNonCircleIcon />
                                                <span className='inter-medium-12-18'>Non referred by you</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.notReferredByYou} leads</span>
                                        </div>
                                    ): null
                                }
                                
                                {
                                    inEligibleLeadsInfo?.alreadyCalled ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <PhoneTickIcon />
                                                <span className='inter-medium-12-18'>Already called by you</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.alreadyCalled} leads</span>
                                        </div>
                                    ): null
                                }
                                
                                
                                {
                                    inEligibleLeadsInfo?.incorrectPhoneNumber ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <PhoneCrossIcon />
                                                <span className='inter-medium-12-18'>Incorrect numbers</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.incorrectPhoneNumber} leads</span>
                                        </div>
                                    ): null
                                }

                                {
                                    inEligibleLeadsInfo?.other ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <InfoIcon height={14} width={14} strokeColor='#000'/>
                                                <span className='inter-medium-12-18'>Others</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.other} leads</span>
                                        </div>
                                    ): null
                                }
                                
                                
                            </div>
                        ): null
                    }

                    
                </div>

                <div className="filtered-candidate-card-container margin-horizontal-32">

                    {/* TABS SECTION */}
                    <div className="filtered-candidate-top-section d-flex justify-content-between align-items-center">
                        <div className="filtered-candidate-tab-container d-flex">
                            <div 
                            className={`filtered-candidate-single-tab ${activeCandidateDetailsTab === 'interested' ? 'filtered-candidate-active-tab': null}`}
                            onClick={()=>setActiveCandidateDetailsTab('interested')}
                            >
                                Interested
                            </div>
                            <div 
                            className={`filtered-candidate-single-tab ${activeCandidateDetailsTab != 'interested' ? 'filtered-candidate-active-tab': null}`}
                            onClick={()=>setActiveCandidateDetailsTab('all')}
                            >
                                All
                            </div>
                        </div>
                        <div className="filtered-candidate-options d-flex">
                            <div className="single-option-container d-flex align-items-center" onClick={downloadExcel}>
                                <DownloadIcon />
                                <span className='single-option-text'>Download</span>
                            </div>

                            {/* <PopoverDropdown
                            content={filterData}
                            visible={showFilter}
                            setVisible={setShowFilter}
                            onSelect={(item)=>onFilter(item)}
                            >
                                <div className="single-option-container d-flex align-items-center">
                                    <FilterIcon />
                                    <span className='single-option-text'>Filter</span>
                                </div>
                            </PopoverDropdown> */}
                            
                        </div>
                    </div>

                    <div className="session-list-pagination-container d-flex justify-content-end mt-16">
                        {
                            totalItems && activeCandidateDetailsTab === 'all' ? (
                                <NewCustomPagination
                                    totalItems={totalItems}
                                    totalItemsPerPage={totalItemsPerPage}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            ): null
                        }
                    
                    </div>

                    {
                        activeCandidateDetailsTab === 'interested' ? (
                            <div className="filtered-candidate-cards d-flex align-items-center">
                                
                                {
                                    allCandidatesPaginationLoader ? (
                                    <>
                                        <Skeleton active />
                                    </>
                                    ):
                                    interestedLeads.length ? interestedLeads.map((item,idx) => {
                                        return <InterestedCandidateCard key={idx} indexVal={idx} candidate={item}/>
                                    }): (
                                        <div className='d-flex align-items-center justify-content-center w-100 flex-column'>
                                            {
                                                sessionSummaryData?.summary?.isSessionCompleted ? (<Empty description=""/>): <Lottie
                                                options={defaultOptions}
                                                height={300}
                                                width={300}
                                                speed={1}
                                                />
                                            }
                                            <span className='inter-medium-16-24'>
                                                    {
                                                        sessionSummaryData?.summary?.isSessionCompleted ? 'No Interested Candidates Found!': 'Calling In Progress. Candidates will appear in a few minutes '
                                                    }
                                            </span>
                                        </div>
                                    )
                                }
                            
                            </div>
                        ): (
                            <div className="filtered-candidate-cards d-flex">
                                {
                                    allCandidatesPaginationLoader ? (
                                        <>
                                            <Skeleton active />
                                        </>
                                        ):allLeads.length ? allLeads.map((item,idx) => {
                                        return <ProcessedCandidateCard key={idx} indexVal={idx} candidate={item}/>
                                    }): <div className='d-flex align-items-center justify-content-center w-100'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                          Sorry! No result found
                                        </span>
                                      }/></div>
                                }
                            </div>
                        )
                    }

                </div>
                </>
            )
        }

        

    </div>
    <ScrollToTopButton scrollContainerRef={scrollContainerRef}/>
    </>
    
  )
}

export default LeadListOfASession