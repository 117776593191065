import axios from "axios";
import axiosInstance from './interceptorService';

export const triggerSwiggyOtp = ({scheduledInterviewId, phoneNumber}) => {

    const payload = {
        "arg": {
            "clientName": "swiggy",
            "scheduledInterviewId": scheduledInterviewId
        },
        "user": {
            "phoneNumber": phoneNumber
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/swiggy/sendOtp`
    return axiosInstance.post(url, payload, {withCredentials: true});
}

export const submitSwiggyOtp = ({otp, phoneNumber}) => {

    const payload = {
        phoneNumber,
        otp
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/swiggy/verifyOtp`
    return axiosInstance.post(url, payload, {withCredentials: true});
}

export const updateSwiggyData = (data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/swiggy/updateSwiggyData`
    return axiosInstance.post(url, data, {withCredentials: true});
}

export const getSwiggyProfileData = (data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/swiggy/getSwiggyProfileData`
    return axiosInstance.post(url, data, {withCredentials: true});
}

export const getSwiggySubmissionDetails = ({phoneNumber, source='mitra-leader'}) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/swiggy/getAllSubmissionDetails?phoneNumber=${phoneNumber}&source=${source}`, {withCredentials: true});
}
