import React from "react";
import {Space,Typography,Switch} from 'antd';
import { BarChartOutlined} from '@ant-design/icons';
import '../../css/sectionLayout.scss';

const { Text } = Typography;

const SectionLayout = (props) => {
    return (
        <>
            <div align="start" style={props.themeWhite ? {backgroundColor:'#ffffff'} : props?.style ? {...props.style}: null} className='section-card-layout'>
                <Space className="layout-header">
                    <Space>
                        {props.icon}
                        <Text strong>{props.title}</Text>
                    </Space>
                    {props.onChangeGrid && 
                    <>
                        <div className="switch-view" style={props.gridView ? {backgroundColor:'#FFEAE0'} :null}>
                            <BarChartOutlined style={{color:'#D34612'}} />
                            <Text className="siwtch-name">{props.switchName}</Text>
                            <Switch defaultChecked={props.gridView} onChange={props.onChangeGrid} />
                        </div> 
                    </>}
                </Space>
                <div className="layout-content">
                    {props.children}
                </div>
            </div>
        </>
        
    )
}

export default SectionLayout