import React, {useState, useEffect, useContext} from 'react';
import { CalendarOutlined, LineChartOutlined, DownOutlined, PieChartOutlined } from '@ant-design/icons';
import { Switch, Segmented, Skeleton, Dropdown, Menu, Space, Button, Divider } from 'antd';
import '../../css/runRate.scss';
import moment from 'moment';
import { Line } from '@ant-design/plots';
import { getPerformanceDashboardTabDetails, getRunRateCardData, getFilterListForTargetFeature } from './../../service/reportService';
import {AppContext} from '../../App';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import SetTargetPopup from './SetTargetPopup';

const RunRateComponent = () => {
    const {mitraReducer, setAlert, mitraDispatch} = useContext(AppContext);
    const [isGraphVsible, setIsGraphVisible] = useState(false);
    const [showTargetPopup, setShowTargetPopup] = useState(false);
    const [dailyGraphData, setDailyGraphData] = useState();
    const [cumulativeGraphData, setCumulativeGraphData] = useState();
    const [loading, setLoading] = useState(false);
    const [graphViewType, setGraphViewType] = useState('Daily');
	const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
    
    const [teamList, setTeamList] = useState([]);

    const [displayRunRateSection, setDisplayRunRateSection] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedTeamId, setSelectedTeamId] = useState(mitraReducer?.mitraInfo?.id);
    const [teamSelectionOptions, setTeamSelectionOptions] = useState([]);
    const [monthSelectionOptions, setMonthSelectionOptions] = useState([]);
    const initialFTs = [
        {
            title: "FT's completed this month",
            value: 0,
            color: '#d34712'
        },
        {
            title: "Projected FT's for this month",
            value: 0,
            color: '#d34712'
        },
        {
            title: "Target FT's per month",
            value: 0,
            color: '#1990ff'
        },
        {
            title: "Current Average FT's per day",
            value: 0,
            color: '#d34712'
        },
        {
            title: "Required FT's per day",
            value: 0,
            color: '#1990ff'
        }
    ];
    const [cardData, setCardData] = useState(initialFTs);
    const [monthlyTargetFTs, setMonthlyTargetFTs] = useState(0);
    
    const [selectedTeam, setSelectedTeam] = useState();
    const [selectedMonth, setSelectedMonth] = useState();
    const getRunRatesCardData = (payload) => {
        getRunRateCardData(payload).then((res) => {
            if(res.data.status) {
                setLoading(false);
                setDisplayRunRateSection(true);
                setDailyGraphData(res.data.data.dateWiseRates);
                generateDailyGraphData(res.data.data.dateWiseRates, res.data.data.requiredRates.commitment);
                generateCumulativeGraphData(res.data.data.dateWiseRates, res.data.data.targets, res.data.data.totalFTDoneTillNow);
                setMonthlyTargetFTs(res?.data?.data?.targets);
                const cardsData = [
                    {
                        title: "FT's completed this month",
                        value: res?.data?.data?.totalFTDoneTillNow,
                        color: '#d34712'
                    },
                    {
                        title: "Projected FT's for this month",
                        value: res?.data?.data?.projected,
                        color: '#d34712'
                    },
                    {
                        title: "Target FT's per month",
                        value: res?.data?.data?.targets,
                        color: '#1990ff'
                    },
                    {
                        title: "Current Average FT's per day",
                        value: res?.data?.data?.currentRate,
                        color: '#d34712'
                    },
                    {
                        title: "Required FT's per day",
                        value: res?.data?.data?.requiredRates?.commitment,
                        color: '#1990ff'
                    }
                ]
                setCardData(cardsData);
            } else {
                setMonthlyTargetFTs(0);
                setCardData(initialFTs);
            }
        });
    }

    useEffect(() => {
        const data = {
            mitraId: mitraReducer?.mitraInfo?.id,
            startDate: startDate,
            endDate: endDate
        }
        if(mitraReducer?.mitraInfo?.id && startDate && endDate) {
            getRunRatesCardData(data);
        }
    }, [mitraReducer?.mitraInfo?.id, startDate, endDate]);

    useEffect(() => {
        getFilterListForTargetFeature().then((res) => {
            if(res?.data?.status) {
                if(res?.data?.data) {
                    setMonthSelectionOptions(res.data.data.monthFilterList);
                    setTeamSelectionOptions(res.data.data.showFiltersList);
                    setSelectedMonth(res.data.data?.monthFilterList[0]?.label)
                    setSelectedTeam(res.data.data?.showFiltersList[0]?.label)
                    setStartDate(res.data.data?.monthFilterList[0]?.startDate)
                    setEndDate(res.data.data?.monthFilterList[0]?.endDate)
                }
            }
        });
    }, []);

    const onChangeRunRateView = (checked) => {
		trackMoengageEvent('run-rate-daily-graph-viewed');
        setIsGraphVisible(checked);
    }

    const onChangeGraphView = (value) => {
		trackMoengageEvent('run-rate-cumulative-graph-viewed');
        setGraphViewType(value);
    }

    const configLinePlot = {
        data: graphViewType === 'Daily' ? dailyGraphData : cumulativeGraphData,
        xField: 'date',
        yField: 'value',
        xAxis: {
            title: { text: 'Date' },
        },
        yAxis: {
            title: { text: graphViewType === 'Daily' ? 'Number of FTs each day' : 'Total number of FTs' },
        },
        seriesField: 'category',
        style: {backgroundColor: "#fff"},
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
        smooth: true,
        color: ['#52abff', '#D34612']
    };

    const generateDailyGraphData = (dataArray, requiredFtPerDay) => {
        const daysInMonth = moment().daysInMonth();
        const currentDate = moment().format('DD');
        const DaysRemaining = daysInMonth - currentDate;
        for(let day = 1; day <= DaysRemaining; day++) {
            const newObj = {
                date: moment().date(parseInt(currentDate)+day).format('DD'),
                value: requiredFtPerDay,
                category: 'Required FTs on this day'
            }
            dataArray.push(newObj);
        }
        setDailyGraphData(dataArray);
    }

    const generateCumulativeGraphData = (dataArray, targetFt, totalFt) => {
        let overallValue = 0;
        let ftCountValue = 0;
        const cumulativeArray = []
        const daysInMonth = moment().daysInMonth();
        const currentDate = moment().format('DD');
        dataArray.map((data, index) => {
            if(data.category === 'Actual FTs on this day') {
                ftCountValue += data.value;
                const newObj = {
                    date: data.date,
                    value: ftCountValue,
                    category: 'Actual FTs till this day'
                }
                cumulativeArray.push(newObj);
            } 
            else if(data.category === 'Required FTs on this day') {
                const newObj = {
                    date: data.date,
                    value: targetFt,
                    category: 'Required FTs for this month'
                }
                cumulativeArray.push(newObj);
            }
        })

        if((cumulativeArray.length/2) < daysInMonth) {
            for(let i = (cumulativeArray.length/2); i < daysInMonth; i++) {
                ftCountValue += Math.ceil(totalFt/currentDate);
                const newObj = {
                    date: moment().date(i+1).format('DD'),
                    value: ftCountValue,
                    category: 'Actual FTs till this day'
                }
                cumulativeArray.push(newObj);
                const newObj2 = {
                    date: moment().date(i+1).format('DD'),
                    value: targetFt,
                    category: 'Required FTs for this month'
                }
                cumulativeArray.push(newObj2);
            }
        }
        setCumulativeGraphData(cumulativeArray);
    }


    const fetchAllTeams = () => {
        let payload;
        if(selectedTeam === 'Overall') {
            trackMoengageEvent('overall-set-target-run-rate-cards');
            setShowTargetPopup(true);
            setTeamList(teamSelectionOptions);
        } else {
            trackMoengageEvent('selected-team-set-target-run-rate-cards', {selectedTeam: selectedTeam});
            payload = {
                tab: 'teamLeader',
                team: selectedTeam,
                startDate: startDate,
                endDate: endDate
            }
            getPerformanceDashboardTabDetails(payload)
            .then(res=> {
                if(res?.data?.status) {
                    setShowTargetPopup(true);
                    if(selectedTeam === 'Overall') {
                        setTeamList(res?.data?.data);
                    } else {
                        setTeamList(res?.data?.data[0]?.teamList);
                    }
                }
            }).catch(err=> {
                console.log(err);
            })
        }
    }

    const onDropdownChange = (e, type) => {
        if(type === 'month') {
            trackMoengageEvent('month-filter-changed-run-rate-cards', {month: e.label});
            setStartDate(e.startDate);
            setEndDate(e.endDate);
            setSelectedMonth(e.label);
            const data = {
                mitraId: selectedTeamId,
                startDate: e.startDate,
                endDate: e.endDate
            }
            getRunRatesCardData(data);
        } else if(type === 'team') {
            trackMoengageEvent('team-filter-changed-run-rate-cards', {team: e.label});
            setSelectedTeam(e.label);
            setSelectedTeamId(e.teamId ? e.teamId : mitraReducer?.mitraInfo?.id);
            const data = {
                mitraId: e.label === 'Overall' ? mitraReducer?.mitraInfo?.id : e.teamId,
                startDate: startDate,
                endDate: endDate
            }
            getRunRatesCardData(data);
        }
    }

    const monthMenu = (
        <Menu>
            {
                monthSelectionOptions.map((month, idx) => {
                    return (
                        <>
                        <Menu.Item onClick={()=>onDropdownChange(month,'month')} key={month} style={{ padding: '10px 20px' }}>{month.label}</Menu.Item>
                        {
                           monthSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    const teamMenu = (
        <Menu>
            {
                teamSelectionOptions.map((team, idx) => {
                    return (
                        <>
                        <Menu.Item onClick={()=>onDropdownChange(team,'team')} key={team.key ? team.key : team.teamId} style={{ padding: '10px 20px' }}>{team.label}</Menu.Item>
                        {
                           teamSelectionOptions.length - 1 === idx ? null: <Divider style={{margin: '0px'}}/>
                        }
                        </>
                    )
                })
            }
        </Menu>
    );

    return (
        <>
        <SetTargetPopup
            show={showTargetPopup}
            setShow={setShowTargetPopup}
            selectedMonth={selectedMonth}
            monthlyTargetFTs={monthlyTargetFTs}
            teamList={teamList}
            startDate={startDate}
            endDate={endDate}
            overallTeamTarget={selectedTeam === 'Overall' ? true : false}
        />
        {
            displayRunRateSection ?
                <div className='run-rate-component-container'>
                    <div className='run-rate-header'>
                        <div className='run-rate-header-left'>
                            {/* <CalendarOutlined className='overview-icon' />
                            <span className='run-rate-title'>{moment().format("MMM YYYY")} Overview</span> */}
                                <span>Show </span>

                                {
                                    mitraReducer?.mitraInfo?.role === CONSTANTS.roles.ADMIN ? (
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={teamMenu}
                                        >
                                            <div style={{cursor: 'pointer'}}>
                                            <Space style={{color: '#D34612', fontWeight: '700'}}>
                                                {selectedTeam}
                                                <DownOutlined className='d-flex'/>
                                            </Space>
                                            </div>
                                            
                                        </Dropdown>
                                    ):
                                    mitraReducer?.mitraInfo?.role === CONSTANTS.roles.TEAM_LEADER ? (
                                        <span style={{fontWeight: 700}}>{selectedTeam}</span>
                                    ): null
                                }
                                
                                <span>performance in</span>
    
                                <Dropdown
                                    trigger={['click']}
                                    overlay={monthMenu}
                                >
                                    <div style={{cursor: 'pointer'}}>
                                    <Space style={{color: '#D34612', fontWeight: '700'}}>
                                        {selectedMonth}
                                        <DownOutlined className='d-flex'/>
                                    </Space>
                                    </div>
                                    
                                </Dropdown>
                        </div>
                        <div className='run-rate-header-right'>
                            <LineChartOutlined className='graph-view-icon' />
                            <span className='run-rate-title'>Graph View</span>
                            <Switch onChange={onChangeRunRateView}/>
                            {(mitraReducer?.mitraInfo?.role === CONSTANTS.roles.ADMIN ||
                            mitraReducer?.mitraInfo?.role === CONSTANTS.roles.TEAM_LEADER) &&
                                <Button
                                onClick={()=>fetchAllTeams()}
                                style={{
                                    alignItems: 'center', display: 'flex', marginLeft: '12px',
                                    width: '140px',border: '1px solid #D34612',color: '#D34612',
                                    justifyContent: 'center', fontWeight:'700', fontSize: '14px',
                                    lineHeight: '22px', background: 'rgb(245, 245, 245)'
                                    }}>
                                    Set Targets
                                </Button>
                            }
                        </div>
                    </div>
                    {
                        !isGraphVsible ? 
                            <div className='run-rate-card-container flex-row flex-wrap'>
                                {
                                    cardData ? cardData.length && cardData.map((data, index) => 
                                        index  === 0 ? (
                                         
                                        <div key={index} className={`run-rate-card`} style={monthlyTargetFTs === 0 ? {width: '32.5%'} : {width: '19%'}}>
                                            <div className='run-rate-card-value' style={{color: data.color}}>{data.value}</div>
                                            <div className='run-rate-card-title'>{data.title}</div>
                                        </div>

                                    ): (
                                        monthlyTargetFTs === 0 && ((data.title).includes('Target FT') || (data.title).includes('Required FT')) ? null :
                                        <div key={index} className={`run-rate-card mobile-run-rate-card`} style={monthlyTargetFTs === 0 ? {width: '32.5%'} : {width: '19%'}}>
                                            <div className='run-rate-card-value' style={{color: data.color}}>{data.value ? data.value : 0}</div>
                                            <div className='run-rate-card-title'>{data.title}</div>
                                        </div>
                                    )
                                )
                                    : <Skeleton active />
                                }
                            </div>
                            :
                            <div className='run-rate-graph-container'>
                                <div className='graph-card-continer'>
                                    <div className='graph-data'>
                                    <Segmented className='graph-view-segmented'
                                        options={['Daily', 'Cumulative']}
                                        onChange={onChangeGraphView}
                                    />
                                        <Line {...configLinePlot} /> 
                                    </div>
                                    <div className='card-data align-items-center'>
                                        {
                                            cardData.map((data, index) => {
                                                if(index === 0) {
                                                    return (
                                                        <div key={index} className='run-rate-card-big'>
                                                            <div className='run-rate-card-value' style={{color: data.color}}>{data.value}</div>
                                                            <div className='run-rate-card-title'>{data.title}</div>
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    monthlyTargetFTs === 0 && ((data.title).includes('Target FT') || (data.title).includes('Required FT')) ? null :
                                                    <div key={index} className='run-rate-card-small'>
                                                        <div className='run-rate-card-value' style={{color: data.color}}>{data.value ? data.value : 0}</div>
                                                        <div className='run-rate-card-title'>{data.title}</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            : null
        }
        </>
        
    );
};

export default RunRateComponent;
