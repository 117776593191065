import React, { useState, useEffect, useReducer, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import PageHeaderComponent from '../../common/PageHeader';
import { Divider, Tag, Button, DatePicker, Pagination, Anchor, Dropdown, Menu, Skeleton, Empty, message, Popover } from 'antd';
import { UsergroupAddOutlined, PhoneOutlined, WhatsAppOutlined, FilterOutlined, CloseOutlined, DownOutlined, RedoOutlined, DownloadOutlined } from '@ant-design/icons';
import '../style/BulkActionsContainerStyle.scss'
import moment from 'moment';
import BulkActionsListing from './BulkActionsListing';
import BulkActionsListingMobile from './BulkActionsListingMobile';
import { getActiveClientsForFilter } from '../../../service/commonService'
import SideSheet from "../../common/SideSheet"
import { getDisplayableClientName } from '../../../utils/commonFunction'
import { bulkListAndSummaryAPI, downloadAllBulkUploads } from '../../../service/bulkActionService'
import axios from 'axios';
import BulkActionSidesheet from '../../bulk-action/Bulk-action-sidesheet.component'
import BulkIVRContainer from './BulkIVR/BulkIVRContainer';
import BulkUploadsContainer from './BulkUploadsContainer';
import BulkWhatsAppContainer from './BulkWhatsApp/BulkWhatsAppContainer';
import { AppContext } from '../../../App';
import VerifyEmailPopup from '../../common/VerifyEmailPopup.component';
import DownloadFilePopup from './DownloadFilePopup';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';
import { getUploadedListOfFiles } from '../../../service/SamvadiniApiService';

const BulkActionsContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const routeParams = history.location.route;
    const queryParams = new URLSearchParams(location.search);
    const [bulkReferralsListingData, setBulkReferralsListingData] = useState(
        {
            "status": true,
            "message": "",
            "data": [
                {
                    "bulkUploadId": "",
                    "uploadedBy": "",
                    "totalLeads": 0,
                    "uploadedURL": "",
                    "errorReportUrl": "",
                    "processStatus": "",
                    "bulkUploadUniquenessLeadId": "",
                    "metaData": {
                        "companyName": "",
                        "jobLocation": "",
                        "category": ""
                    },
                    "city": "",
                    "uploadedAt": "",
                    "processedLeads": 0,
                    "nonUnique": 0,
                    "unique": 0,
                    "failedCount": 0
                }
            ],
            "totalPages": 2
        }
    )
    const [bulkReferralKeyValue, setBulkReferralKeyValue] = useState({
        key: "bulk-referrals",
        value: "Bulk Referral"
    })

    const [bulkUploadsKeyValue, setBulkUploadsKeyValue] = useState({
        key: "bulk-uploads",
        value: "Bulk Uploads"
    })

    const [bulkWhatsappKeyValue, setBulkWhatsappKeyValue] = useState({
        key: "bulk-whatsapp",
        value: "Bulk WhatsApp"
    })

    const [clientsFilterKeyValue, setClientsFilterKeyValue] = useState({
        key: "clients",
        value: "Clients"
    })

    const [processStatusFilterKeyValue, setProcessStatusFilterKeyValue] = useState({
        key: "process-status",
        value: "Process Status"
    })
    const [loadingListData, setLoadingListData] = useState(false);
    const [currentTab, setCurrentTab] = useState({ key: 'bulk-referrals', value: "Bulk Referrals" })
    const [breadcrumb, setBreadcrumb] = useState([{ link: '/', label: 'Home' }, { link: '', label: "Bulk Actions" }]);
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD-MMMM YYYY';
    const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
    const [endDate, setEndDate] = useState(moment());

    const [totalBulkReferralsCount, setTotalBulkReferralsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState();

    const [filterSider, setfilterSider] = useState(false);

    const [clientFilterList, setClientFilterList] = useState([])
    const [allowAIFilter, setAllowAIFilter] = useState(false);

    const [processStatusFilterList, setProcessStatusFilterList] = useState([
        {
            key: "queued",
            value: "Queued"
        },
        {
            key: "processing",
            value: "Processing"
        },
        {
            key: "processed",
            value: "Processed"
        }
    ])
    const [processStatusFiltered, setProcessStatusFiltered] = useState({})
    const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
    const [showOtherActionsSidesheet, setShowOtherActionsSideSheet] = useState(false);
    const [otherActionsSidesheetHeader, setOtherActionsSidesheetHeader] = useState('')
    const [otherActionsSidesheetKey, setOtherActionsSidesheetKey] = useState('')
    const [showBulkReferralListing, setShowBulkReferralListing] = useState(true);
    const [showBulkUploadListing, setShowBulkUploadListing] = useState(false);
    const [showIVRListing, setShowIVRListing] = useState(false);
    const [showWhatsAppListing, setShowWhatsAppListing] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const [showAddEmailPopup, setShowAddEmailPopup] = useState(false);
    const [userModifiedEmail, setUserModifiedEmail] = useState("");
    const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
    const [showProcessingHeader, setShowProcessingHeader] = useState(false);
    const [disableDatesBefore, setDisableDatesBefore] = useState(moment());
    const [hasDownloadAccess, setHasDownloadAccess] = useState(false);


    const [filtersList, setFiltersList] = useState({
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        processStatus: "",
        clients: [],
    })

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const menu = (
        <Menu>
            <Menu.Item key="bulkReferrals" onClick={() => changeListingPage(bulkReferralKeyValue.key)}>Bulk Referral</Menu.Item>
            <Menu.Item key="bulkUpload" onClick={() => changeListingPage(bulkUploadsKeyValue.key)}>Bulk Uploads</Menu.Item>
            {mitraReducer?.mitraInfo?.role == "admin" ?
                <Menu.Item key="bulkWhatsapp" onClick={() => changeListingPage(bulkWhatsappKeyValue.key)}>Bulk WhatsApp Messages</Menu.Item>
            : null}

        </Menu>
    );

    useEffect(() => {
        // getBulkReferralListingData()
        getClientFilter()
    }, [])

    useEffect(() => {
        if(routeParams === 'bulkUploadSavedPage') {
            setLoadingListData(false);
            setShowBulkUploadListing(true);
            setShowWhatsAppListing(false);
            setShowBulkReferralListing(false);
        }
    }, [routeParams])

    useEffect(() => {
        // To check if startDate and endDate filter is already selected
        if (queryParams.get('startDate') && queryParams.get('endDate') && (moment(queryParams.get('startDate')) !== moment().subtract(7, "days").format("YYYY-MM-DD") && moment(queryParams.get('endDate')) !== moment().format("YYYY-MM-DD"))) {
            let startDateStr = moment(queryParams.get('startDate'))
            let endDateStr = moment(queryParams.get('endDate'))
            setStartDate(startDateStr)
            setEndDate(endDateStr)
            setDateRangeFilter(startDateStr, endDateStr)
        }
        getBulkReferralListingData()
    }, [currentPage]);

    const checkForOngoingSessions = async() => {
        if (!mitraReducer?.mitraInfo?.id) return;
        try{
            const payload = {
                mitraId: mitraReducer?.mitraInfo?.id
            }

            const response = await getUploadedListOfFiles(payload);

            if(response?.data?.sessions?.length && response?.data?.allowToCreateNewSession === true) {
                setAllowAIFilter(true);
            } else {
                setAllowAIFilter(false);
            }
        } catch (e) {
            setAllowAIFilter(false);
        }
    }

    useEffect(() => {
        if (mitraReducer && mitraReducer.allowedFeatures) {
            setHasDownloadAccess(mitraReducer?.allowedFeatures[CONSTANTS.DOWNLOAD_DATA_ACCESS]?.isEnabledOverall)
            let viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
            if (viewAccessRestricted) {
                setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
            }
        }

        checkForOngoingSessions();
    }, [mitraReducer])

    const getClientFilter = () => {
        getActiveClientsForFilter().then(data => {
            setClientFilterList(data.data.activeClients)
        })
    }

    const getBulkReferralListingData = () => {
        // Set loader
        setLoadingListData(true)
        if (cancelToken) {
            cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
        }
        const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
        setCancelToken(newCancelToken); // Set the new cancel token

        try {
            // Make API Call with all filters, only releveant ones get added to the URL
            bulkListAndSummaryAPI(currentPage ? currentPage - 1 : 0, filtersList.startDate, filtersList.endDate, filtersList.processStatus, JSON.stringify(filtersList.clients), null, newCancelToken.token).then((listRes) => {
                if (listRes && listRes.data && listRes.data.data) {
                    setLoadingListData(false)
                    setBulkReferralsListingData(listRes.data.data)
                    setTotalBulkReferralsCount(listRes.data.totalCount)
                    checkIfAnyReferralIsProcessing(listRes.data.data)
                }
                else {
                    setLoadingListData(false)
                    setBulkReferralsListingData([])
                    message.error("Something went wrong, please try again later", 4)
                }
            })
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.log('Request err', error);
                setLoadingListData(false)
            }
        }
    }

    const checkIfAnyReferralIsProcessing = (listData) => {
        if (listData && listData.length > 0) {
            for (let i = 0; i < listData.length; i++) {
                if (listData[i].processStatus == "processing") {
                    setShowProcessingHeader(true);
                    break;
                }
                else {
                    setShowProcessingHeader(false);
                }
            }
        }
    }

    const content = (
        <div>
            {
                allowAIFilter ? <p className='regular12-20'>Find Hot leads amongst your referred candidates using AI!</p>:<p className='regular12-20'>Another AI Filtering session is already live</p>
            }
        </div>
      );

    const navigationCheck = () => {
        if(allowAIFilter) {
            redirectToBulkActionPage('bulk-referral', {state:{"enableAIFiltering":true}})
        }
    }

    const returnBulkSectionCardForDesktop = (whichSection, cardTitle, cardSubTitle) => {
        if(
            whichSection.key === bulkReferralKeyValue.key && 
            CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id)
        ) {
            // this is new popup for AI Filtering
            return (
                <>
                    <Popover 
                    content={content} 
                    title={<div className='bold14-22'>{allowAIFilter ? 'AI Hot Leads': 'Not Available'}</div>} 
                    // visible={true} 
                    trigger={'hover'}
                    className='ai-filtering-popup'
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placement='bottom'>
                        <div 
                        className={`d-flex bulk-section-card align-items-center bulk-section-card-ai-filtering`}
                        style={{opacity: allowAIFilter ? 1: 0.6, cursor: allowAIFilter ? 'pointer': 'not-allowed' }}
                        onClick={
                            () => { navigationCheck()}
                            }
                            >
                                <div className='shine'></div>
                                <div className='shine-small-width'></div>
                            
                                <UsergroupAddOutlined className='bulk-section-card-img white-img' />
                            
                                <div className='d-flex flex-dir-col align-items-start'>
                                    <div className={`bulk-referral-card-heading bulk-referral-white-txt d-flex`}>
                                        {cardTitle} with AI Hot Leads
                                        <div className='new-tag'>New</div>
                                    </div>
                                    <div className={`bulk-section-card-subtitle bulk-section-card-subtitle-white`}>
                                        Refer candidates and filter by AI filtering
                                    </div>
                                </div>
                        </div>
                    </Popover>

                    <div 
                        className={`d-flex bulk-section-card align-items-center`}
                        onClick={
                            () => { whichSection.key == bulkReferralKeyValue.key ? redirectToBulkActionPage('bulk-referral') : changeTab(whichSection.key, whichSection.value) }
                            }
                            >
                            {
                                whichSection.key == bulkReferralKeyValue.key ?
                                    <UsergroupAddOutlined className='bulk-section-card-img orange-img' />
                                    // : whichSection.key == bulkIVRKeyValue.key ?
                                    //     <PhoneOutlined className='bulk-section-card-img non-orange-img' />
                                        :
                                        <WhatsAppOutlined className='bulk-section-card-img non-orange-img' />
                            }
                            <div className='d-flex flex-dir-col align-items-start'>
                                <div className={`bulk-referral-card-heading ${whichSection.key == bulkReferralKeyValue.key ? "bulk-referral-orange-txt" : "bulk-referral-black-txt"}`}>
                                    {cardTitle}
                                </div>
                                <div className={`bulk-section-card-subtitle`}>
                                    {cardSubTitle}
                                </div>
                            </div>
                        </div>
                </>
            )
        } else {
            return (
                <div 
                className={`d-flex bulk-section-card align-items-center`}
                onClick={
                    () => { whichSection.key == bulkReferralKeyValue.key ? redirectToBulkActionPage('bulk-referral') : changeTab(whichSection.key, whichSection.value) }
                    }
                    >
                    {
                        whichSection.key == bulkReferralKeyValue.key ?
                            <UsergroupAddOutlined className='bulk-section-card-img orange-img' />
                            // : whichSection.key == bulkIVRKeyValue.key ?
                            //     <PhoneOutlined className='bulk-section-card-img non-orange-img' />
                                :
                                <WhatsAppOutlined className='bulk-section-card-img non-orange-img' />
                    }
                    <div className='d-flex flex-dir-col align-items-start'>
                        <div className={`bulk-referral-card-heading ${whichSection.key == bulkReferralKeyValue.key ? "bulk-referral-orange-txt" : "bulk-referral-black-txt"}`}>
                            {cardTitle}
                        </div>
                        <div className={`bulk-section-card-subtitle`}>
                            {cardSubTitle}
                        </div>
                    </div>
                </div>
            )
        }
        

    }

    const returnBulkSectionCardForMobile = (whichSection, cardTitle, cardSubTitle) => {
        if(
            whichSection.key === bulkReferralKeyValue.key && 
            CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id)
        ) {
            return(
                <>
                    <Popover 
                        content={content} 
                        title={<div className='bold14-22'>{allowAIFilter ? 'AI Hot Leads': 'Not Available'}</div>} 
                        // visible={true} 
                        trigger={'click'}
                        className='ai-filtering-popup'
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placement='bottom'>
                        <div 
                        className={`d-flex bulk-section-card align-items-center bulk-section-card-ai-filtering`}
                        onClick={() => { navigationCheck()}}
                        style={{opacity: allowAIFilter ? 1: 0.6, cursor: allowAIFilter ? 'pointer': 'not-allowed' }}
                        >
                            <div className='shine'></div>
                            <UsergroupAddOutlined className='bulk-section-card-img white-img' />

                            <div className='d-flex flex-dir-col align-items-start'>
                                <div className={`bulk-referral-card-heading bulk-referral-white-txt d-flex`}>
                                    {cardTitle} with AI Hot Leads
                                    <div className='new-tag'>New</div>
                                </div>
                                <div className={`bulk-section-card-subtitle bulk-section-card-subtitle-white`}>
                                    Refer candidates and filter by AI filtering
                                </div>
                            </div>
                        </div>
                    </Popover>
                    <div 
                    className={`d-flex bulk-section-card align-items-center`}
                    onClick={() => { whichSection.key == bulkReferralKeyValue.key ? redirectToBulkActionPage('bulk-referral') : changeTab(whichSection.key, whichSection.value) }}
                    >
                        {
                            whichSection.key == bulkReferralKeyValue.key ?
                                <UsergroupAddOutlined className='bulk-section-card-img orange-img' />
                                // : whichSection.key == bulkIVRKeyValue.key ?
                                //     <PhoneOutlined className='bulk-section-card-img non-orange-img' />
                                    :
                                    <WhatsAppOutlined className='bulk-section-card-img non-orange-img' />
                        }
                        <div className='d-flex flex-dir-col align-items-start'>
                            <div className={`bulk-referral-card-heading ${whichSection.key == bulkReferralKeyValue.key ? "bulk-referral-orange-txt" : "bulk-referral-black-txt"}`}>
                                {cardTitle}
                            </div>
                            <div className={`bulk-section-card-subtitle`}>
                                {cardSubTitle}
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <div 
                className={`d-flex bulk-section-card align-items-center`}
                onClick={() => { whichSection.key == bulkReferralKeyValue.key ? redirectToBulkActionPage('bulk-referral') : changeTab(whichSection.key, whichSection.value) }}
                >
                    {
                        whichSection.key == bulkReferralKeyValue.key ?
                            <UsergroupAddOutlined className='bulk-section-card-img orange-img' />
                            // : whichSection.key == bulkIVRKeyValue.key ?
                            //     <PhoneOutlined className='bulk-section-card-img non-orange-img' />
                                :
                                <WhatsAppOutlined className='bulk-section-card-img non-orange-img' />
                    }
                    <div className='d-flex flex-dir-col align-items-start'>
                        <div className={`bulk-referral-card-heading ${whichSection.key == bulkReferralKeyValue.key ? "bulk-referral-orange-txt" : "bulk-referral-black-txt"}`}>
                            {cardTitle}
                        </div>
                        <div className={`bulk-section-card-subtitle`}>
                            {cardSubTitle}
                        </div>
                    </div>
                </div>
            )
        }
        

    }

    const changeTab = (tabName = "", tabDisplayName = "") => {
        let newVal = {
            key: tabName,
            value: tabDisplayName
        }
        setCurrentTab({ ...newVal })

        if (tabName !== bulkReferralKeyValue.key) {
            openOtherBulkActionsSidebar(tabName, tabDisplayName)
        }

        // if (tabName == bulkIVRKeyValue.key) {
        //     trackEvent('bulk_ivr_cta_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        // }
        if (tabName == bulkWhatsappKeyValue.key) {
            trackEvent('bulk_wa_cta_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        }
    }

    const changeListingPage = (key) => {
        switch (key) {
            case bulkReferralKeyValue.key:
                setShowBulkUploadListing(false);
                setShowWhatsAppListing(false);
                setShowBulkReferralListing(true);
                // if (!bulkReferralsListingData || (bulkReferralsListingData.data && bulkReferralsListingData.data.length < 1))
                getBulkReferralListingData()
                break
            case bulkUploadsKeyValue.key:
                setLoadingListData(false);
                setShowBulkUploadListing(true);
                setShowWhatsAppListing(false);
                setShowBulkReferralListing(false);
                break
            case bulkWhatsappKeyValue.key:
                setLoadingListData(false);
                setShowBulkUploadListing(false);
                setShowWhatsAppListing(true);
                setShowBulkReferralListing(false);
                break
        }
    }

    const disableDateForBulkReferrals = (current) => {
        if ((current && current > moment().endOf('day').valueOf()) || (current && current < disableDatesBefore.endOf("day"))) {
            return true;
        }
        else {
            return false;
        }
    }

    const changeDateRange = (dateRange) => {
        setStartDate(dateRange[0])
        setEndDate(dateRange[1])
        setDateRangeFilter(dateRange[0], dateRange[1])
        addQueryParams({ startDate: dateRange[0].format("YYYY-MM-DD"), endDate: dateRange[1].format("YYYY-MM-DD") });
        getBulkReferralListingData()
    }

    const setDateRangeFilter = (startDateVal, endDateVal) => {
        let tempFiltersList = filtersList
        if (startDateVal && endDateVal) {
            tempFiltersList.startDate = startDateVal.format("YYYY-MM-DD")
            tempFiltersList.endDate = endDateVal.format("YYYY-MM-DD")
        }
        setFiltersList(JSON.parse(JSON.stringify(tempFiltersList)))
    }

    const addQueryParams = (params) => {
        const searchParams = new URLSearchParams(location.search);
        for (const [key, value] of Object.entries(params)) {
            searchParams.set(key, value);
        }
        history.push({ search: searchParams.toString() });
    };

    const redirectToBulkActionPage = (route, param=null) => {
        trackEvent('bulk_referral_cta_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

        if(param?.state?.enableAIFiltering)  {
            const params = new URLSearchParams();
            params.append('enableAiFiltering', 'true');
            history.push(`${route}?${params.toString()}`)
        }
        else {
            history.push({ pathname: route })
        }
    }

    const returnFilterLabel = (label, arrObj) => {
        return <div className='tag-containers'>
            <div>{label}</div>
            {
                label == clientsFilterKeyValue.value ?
                    <div className='tag-wrapper-container'>
                        {arrObj.map((item, index) => {
                            return <Tag className={item && item.value ? 'tags tag-active' : 'tags'}
                                key={index}
                                onClick={() => { onClickTags(label, index) }}
                            >
                                {getDisplayableClientName(item.label)}
                            </Tag>
                        })}
                    </div>
                    : label == processStatusFilterKeyValue.value ?
                        <div className='tag-wrapper-container'>
                            {arrObj.map((item, index) => {
                                return <Tag className={processStatusFiltered.key == item.key ? 'tags tag-active' : 'tags'}
                                    key={index}
                                    onClick={() => { onClickTags(label, index) }}
                                >
                                    {item.value}
                                </Tag>
                            })}
                        </div> : null
            }
        </div>
    }

    const onClickTags = (label, index) => {
        let allFilterListCopy = filtersList
        setCurrentPage(1)
        if (label == clientsFilterKeyValue.value) {
            clientFilterList[index].value = !clientFilterList[index].value;
            allFilterListCopy.clients = []

            for (let i = 0; i < clientFilterList.length; i++) {
                if (clientFilterList[i].value) {
                    allFilterListCopy.clients.push(clientFilterList[i].key)
                }
            }

            trackEvent("bulk_referral_filter_added", { clients: allFilterListCopy.clients }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        }
        else if (label === processStatusFilterKeyValue.value) {
            if (allFilterListCopy?.processStatus?.key == processStatusFilterList[index]?.key) {
                allFilterListCopy.processStatus = {}
                setProcessStatusFiltered({})
            }
            else {
                allFilterListCopy.processStatus = processStatusFilterList[index]
                setProcessStatusFiltered(processStatusFilterList[index])
                trackEvent("bulk_referral_filter_added", { processStatus: allFilterListCopy.processStatus }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        getBulkReferralListingData()
        forceUpdate()
    }

    const clearFilters = (whichFilter) => {
        let existingClientFilterList = clientFilterList
        let existingFilters = filtersList

        if (whichFilter == clientsFilterKeyValue.key || whichFilter == "all") {
            for (let i = 0; i < existingClientFilterList.length; i++) {
                existingClientFilterList[i].value = false
            }
            setClientFilterList(JSON.parse(JSON.stringify(existingClientFilterList)))
            existingFilters.clients = []
        }

        if (whichFilter == processStatusFilterKeyValue.key || whichFilter == "all") {
            setProcessStatusFiltered({})
            existingFilters.processStatus = {}
        }

        if (whichFilter == processStatusFilterKeyValue.key || whichFilter == "all") {
            setfilterSider(false)
            forceUpdate()
        }

        setFiltersList(JSON.parse(JSON.stringify(existingFilters)));
        getBulkReferralListingData()
    }

    const applyFilterCondition = () => {
        let result = false;
        for (let i = 0; i < clientFilterList.length; i++) {
            if (clientFilterList[i].value === true) {
                result = true;
                break;
            }
        }

        if (processStatusFiltered && Object.keys(processStatusFiltered).length > 0) {
            result = true
        }
        return result;
    }

    const openOtherBulkActionsSidebar = (sideSheetKey, sideSheetHeader) => {
        setShowOtherActionsSideSheet(true)
        setOtherActionsSidesheetHeader(sideSheetHeader)
        setOtherActionsSidesheetKey(sideSheetKey)
    }

    const removeSingleClientFromFilter = (client, clientInd) => {
        let allFilterListCopy = filtersList

        clientFilterList[clientInd].value = !clientFilterList[clientInd].value;

        allFilterListCopy.clients = []
        for (let i = 0; i < clientFilterList.length; i++) {
            if (clientFilterList[i].value) {
                allFilterListCopy.clients.push(clientFilterList[i].key)
            }
        }

        setFiltersList(JSON.parse(JSON.stringify(allFilterListCopy)))
        getBulkReferralListingData()
    }

    const downloadAllClicked = () => {
        try {
            trackEvent('bulk_referral_download_all_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            // Make API Call with all filters, only releveant ones get added to the URL
            let downloadAPIData = {
                startDate: startDate,
                endDate: endDate,
                processStatus: filtersList?.processStatus?.key,
                clients: (filtersList.clients && filtersList.clients.length > 0 ? filtersList.clients : []),
                email: userModifiedEmail ? userModifiedEmail : ""
            }
            downloadAllBulkUploads(downloadAPIData).then((downloadRes) => {
                if (downloadRes && downloadRes.data && downloadRes.data.status) {
                    message.success("File will be sent to your email shortly.", 4)
                }
                else {
                    message.error("Something went wrong, please try again later", 4)
                }
            })
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled');
            } else {
                console.log('Request err', error);
                setLoadingListData(false)
            }
        }
    }

    return (
        <div className='bulk-actions-new-parent mainContentZ'>
            <div className="containerTop">
                <div className="header">
                    <>
                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
                    </>
                </div>

            </div>

            <Divider></Divider>

            <div className='padded-section'>
                {
                    showProcessingHeader ?
                        <div className='ba-processing-header d-flex align-items-center w-100 justify-content-between'>
                            <div className='ba-processing-header-txt'>
                                Please wait while your file is processing
                            </div>
                            <CloseOutlined className='ba-cp' onClick={() => setShowProcessingHeader(false)} />
                        </div>
                        :
                        null
                }
                <div className='bulk-section-cards d-flex justify-content-between show-only-desktop'>
                    <div className='bulk-referrals-section bulk-section'>
                        <div className='bulk-section-title d-flex align-items-start'>
                            Recommended Actions
                        </div>
                        {returnBulkSectionCardForDesktop(bulkReferralKeyValue, "Bulk Upload & Referral", "Upload and refer candidates in bulk for a job")}
                    </div>

                    <div className='vertical-divider' />
                    {
                        mitraReducer?.mitraInfo?.role == "admin" ?
                            <div className='other-bulk-actions-section bulk-section'>
                                <div className='bulk-section-title d-flex align-items-start'>
                                    Other
                                </div>
                                {/* {returnBulkSectionCardForDesktop(bulkIVRKeyValue, "Bulk IVR Calls", "Call candidates in bulk with a pre-recorded voice call")} */}
                                {returnBulkSectionCardForDesktop(bulkWhatsappKeyValue, "Bulk WhatsApp Message", "Send WhatsApp message to candidates in bulk")}
                            </div>
                            :
                            null
                    }

                </div>

                <div className='bulk-section-cards-mobile show-only-mobile'>
                    <div className='bulk-referrals-section w-100 bulk-section'>
                        <div className='bulk-section-title d-flex align-items-start'>
                            Recommended Actions
                        </div>
                        {returnBulkSectionCardForDesktop(bulkReferralKeyValue, "Bulk Upload & Referral", "Upload and refer candidates in bulk for a job")}
                    </div>
                    {
                        mitraReducer?.mitraInfo?.role == "admin" ?
                            <div className='other-bulk-actions-section w-100 bulk-section'>
                                <div className='bulk-section-title d-flex align-items-start'>
                                    Other
                                </div>
                                {/* {returnBulkSectionCardForMobile(bulkIVRKeyValue, "Bulk IVR Calls", "Call candidates in bulk with a pre-recorded voice call")} */}
                                {returnBulkSectionCardForMobile(bulkWhatsappKeyValue, "Bulk WhatsApp Message", "Send WhatsApp message to candidates in bulk")}
                            </div>
                            :
                            null
                    }
                </div>

                <div className='bo-tabs-section show-only-desktop'>
                    <div className='bulk-option-tabs'>
                        <Button onClick={() => changeListingPage(bulkReferralKeyValue.key)} className={showBulkReferralListing ? "selected-tab" : "unselected-tab"}>
                            Bulk Referral
                        </Button>
                        {
                            mitraReducer?.mitraInfo?.role == "admin" ?
                                <Button onClick={() => changeListingPage(bulkUploadsKeyValue.key)} className={showBulkUploadListing ? "selected-tab" : "unselected-tab"}>
                                    Bulk Uploads
                                </Button>
                                :
                                null
                        }
                        {
                            mitraReducer?.mitraInfo?.role == "admin" ?
                                <Button onClick={() => changeListingPage(bulkWhatsappKeyValue.key)} className={showWhatsAppListing ? "selected-tab" : "unselected-tab"}>
                                    Bulk WhatsApp Messages
                                </Button>
                                :
                                null
                        }
                    </div>
                </div>

                <div className='bo-tabs-section-mobile show-only-mobile'>
                    <div className='bulk-option-tabs d-flex'>
                        <Dropdown trigger={['click']} overlay={menu}>
                            <Button className='d-flex align-items-center'>
                                {currentTab.value} <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>

                {
                    (showBulkReferralListing) && <div className='bo-filters-parent'>
                        <div className='d-flex justify-content-between'>
                            <div className='bo-filters-section show-only-mobile d-flex justify-content-between align-items-center'>
                                <Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : null} className='apply-filter-btn-mobile' onClick={() => setfilterSider(true)} icon={<FilterOutlined />} />
                            </div>

                            <div className='bo-date-range-picker'>
                                <RangePicker
                                    defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                    format={dateFormat}
                                    onChange={changeDateRange}
                                    ranges={{
                                        Today: [moment().startOf('day'), moment().endOf('day')],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                    disabledDate={(current) => { return disableDateForBulkReferrals(current) }}
                                    numberOfMonths={1}
                                    value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                    allowClear={false}
                                />
                            </div>

                            <div className='bo-pagination show-only-desktop'>
                                {totalBulkReferralsCount > 0 &&
                                    <Pagination
                                        total={totalBulkReferralsCount > 0 && totalBulkReferralsCount}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        defaultPageSize={10}
                                        defaultCurrent={currentPage ? currentPage : 1}
                                        responsive={true}
                                        showLessItems={true}
                                        onChange={(page, pagesize) => { setCurrentPage(page) }}
                                        showSizeChanger={false}
                                    />
                                }
                            </div>
                        </div>


                        <div className='bo-filters-section bo-filters-section-desktop show-only-desktop d-flex justify-content-between align-items-center'>
                            <Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : null} className='apply-filter-btn' onClick={() => { setfilterSider(true) }} icon={<FilterOutlined />}>Filter</Button>
                            <div className='added-filters d-flex'>
                                {
                                    applyFilterCondition() ?
                                        (clientFilterList && clientFilterList.length > 0 && totalBulkReferralsCount > 0 ?
                                            clientFilterList.map((client, clientInd) => {
                                                return (
                                                    client.value ?
                                                        <div className='d-flex single-filter-item justify-content-center align-items-center'>
                                                            <div className='single-filter-txt'>
                                                                {client.label}
                                                            </div>
                                                            <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "8px" }} onClick={() => removeSingleClientFromFilter(client, clientInd)} />
                                                        </div>
                                                        : null

                                                )
                                            })
                                            : null
                                        )
                                        : null
                                }
                                {
                                    applyFilterCondition() ?
                                        processStatusFiltered && Object.keys(processStatusFiltered).length > 0 && processStatusFiltered.value ?
                                            <div className='d-flex single-filter-item justify-content-center align-items-center'>
                                                <div className='single-filter-txt'>
                                                    {processStatusFiltered.value}
                                                </div>
                                                <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "8px" }} onClick={() => clearFilters(processStatusFilterKeyValue.key)} />
                                            </div> : null
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    !loadingListData && showBulkReferralListing ?
                        <div className='ba-uploads-refresh-download-section d-flex justify-content-between align-items-center'>
                            <div className='ba-total-uploads-txt'>
                                {totalBulkReferralsCount} uploads
                            </div>
                            <div className='ba-listing-above-btns d-flex'>
                                <Button onClick={() => getBulkReferralListingData()} className="ba-listing-above-refresh-btn d-flex align-items-center">
                                    <RedoOutlined />
                                    Refresh
                                </Button>
                                {
                                    hasDownloadAccess ?
                                        <Button onClick={() => setShowAddEmailPopup(true)} className="ba-listing-above-download-btn d-flex align-items-center">
                                            <DownloadOutlined />
                                            Download All
                                        </Button>
                                        :
                                        null
                                }

                            </div>
                        </div> : null
                }

                <div className='bo-listing-section'>
                    {
                        loadingListData ?
                            <div className='ba-list-loader'>
                                <Skeleton active avatar />
                            </div> : null
                    }
                    {
                        showBulkReferralListing ?
                            !loadingListData && bulkReferralsListingData && bulkReferralsListingData.length > 0 ?
                                bulkReferralsListingData.map((item, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className='show-only-desktop'>
                                                <div className='ba-display-block w-100'>
                                                    {
                                                        bulkReferralsListingData && bulkReferralsListingData.length > 0 ? <BulkActionsListing listData={item} /> : null
                                                    }
                                                </div>
                                            </div>

                                            <div className='show-only-mobile'>
                                                {
                                                    bulkReferralsListingData && bulkReferralsListingData.length > 0 ? <BulkActionsListingMobile listData={item} /> : null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                !loadingListData && bulkReferralsListingData && bulkReferralsListingData.length == 0 ?
                                    <div className='ba-list-loader'>
                                        <Empty />
                                    </div>
                                    :
                                    null
                            :
                            showBulkUploadListing ? <BulkUploadsContainer /> : <BulkWhatsAppContainer />
                    }
                </div>
            </div>

            {filterSider && clientFilterList &&
                <SideSheet submit={{
                    disabled: false
                    , onClick: () => setfilterSider(false)
                }}
                    clearAll={{ disabled: false, onClick: () => clearFilters("all") }}
                    sidername={"Filter Referrals"}
                    onDismiss={() => { setfilterSider(false) }}>
                    <div className='filter-home-wrapper'>
                        {returnFilterLabel("Clients", clientFilterList)}
                        {returnFilterLabel("Process Status", processStatusFilterList)}
                    </div>
                </SideSheet>
            }

            {
                showOtherActionsSidesheet &&
                <SideSheet sidername={otherActionsSidesheetHeader} onDismiss={() => { setShowOtherActionsSideSheet(false); setOtherActionsSidesheetHeader("") }}>
                    <BulkActionSidesheet setHeader={otherActionsSidesheetHeader} openPage={otherActionsSidesheetHeader} openPageKey={otherActionsSidesheetKey} submitClickedInSideSheet={(whichSectionKey) => { setShowOtherActionsSideSheet(false); changeListingPage(whichSectionKey) }} />
                </SideSheet>
            }

            {
                showAddEmailPopup &&
                (
                    <DownloadFilePopup mitra={mitraReducer?.mitraInfo} visible={showAddEmailPopup} closePopup={setShowAddEmailPopup} downloadFunction={downloadAllClicked} changeEmail={changeEmail} setChangeEmail={setChangeEmail} addedEmail={(inp) => setUserModifiedEmail(inp)} />
                )
            }
        </div>
    )
}

export default BulkActionsContainer; 