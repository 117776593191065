import React, { useEffect, useMemo , useState } from 'react';
import {Modal, Input, Switch} from 'antd';
import {EditOutlined} from '@ant-design/icons';

  
  const VerifyEmailPopup = ({ mitra, visible, closePopup, downloadFunction, changeEmail, setChangeEmail}) => {
    const [email, setEmail] = useState(mitra.email);
    // const [isDisabled, setIsDisabled] = useState(email && email !== '' ? true : false);

    return (
        <Modal
        centered
        visible={visible}
        onOk={() => { downloadFunction(email); closePopup(false)}}
        onCancel={() => closePopup(false)}
        okText='Confirm'
      >
        <div style={{textAlign: 'center', margin: '20px', fontWeight: 'bold'}}>
            Your report will be sent to the below email id <br />
            Please edit this if not correct 
        </div>
        
        <div style={{display: 'flex', flexDirection: 'row', margin: '10px'}}>
            <Input placeholder="Basic usage" value={email} onChange={(e) => {setEmail(e.target.value)}}/> 
            {/* <div onClick={() => setIsDisabled(!isDisabled)}><EditOutlined style={{margin: 'auto 10px', fontSize: '20px', color: '#4e94ff'}} /></div>  */}
        </div>
        <div style={{padding: '7px 10px'}}>
        <Switch defaultChecked={changeEmail} onChange={() => setChangeEmail(true)} />
        <span style={{marginLeft: '10px'}}>Save as default.</span>
        </div>
      </Modal>
    )
}

export default VerifyEmailPopup;