import axios from 'axios';
import moment from 'moment';
import axiosInstance from './interceptorService';

export const getPremiumJobsCities = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getPremiumJobsCities`;
    return axiosInstance.get(url, { withCredentials: true });
};
export const getClientStatus = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getClientStatuses`;
    return axiosInstance.get(url, { withCredentials: true });
}
export const getWorkLocations = async (category, city, companyName) => {
    const data = new FormData();
    data.append('category', category);
    data.append('city', city);
    data.append('companyName', companyName);
    let url = process.env.REACT_APP_BACKEND_URL + '/getWorkLocations';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getOnboardingLocations = async (category, city, companyName) => {
    const data = new FormData();
    data.append('category', category);
    data.append('city', city);
    data.append('companyName', companyName);
    let url = process.env.REACT_APP_BACKEND_URL + '/getOnboardingLocations';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const getClientsAndCompanies = async (city) => {
    const data = new FormData();
    data.append('city', city);
    let url = process.env.REACT_APP_BACKEND_URL + '/getClientsAndCompanies';
    return axiosInstance.post(url, data, { withCredentials: true });
};

// export const getClientsAndCompaniesForBulkUpload = async (city) => {
//     const data = new FormData();
//     data.append('city', city);
//     let url = process.env.REACT_APP_BACKEND_URL + '/getClientsAndCompaniesForBulkUpload';
//     return axiosInstance.post(url, data, {withCredentials: true});
// };


export const getJobs = async (category, city, companyName, jobLocation) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobs?category=${category}&city=${city}&companyName=${companyName}&jobLocation=${encodeURIComponent(jobLocation)}`;
    return axiosInstance.get(url, { withCredentials: true });
};

export const getJobLocations = async (city, company) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobLocations?city=${city}&company=${company}`;
    return axiosInstance.get(url, { withCredentials: true });
};
export const postBasicInfoForm = async (basicInfo) => {
    const data = new FormData();
    data.append('name', basicInfo.name);
    data.append('phoneNumber', basicInfo.phoneNumber);
    data.append('city', basicInfo.city);
    data.append('category', basicInfo.category);
    data.append('company', basicInfo.company);
    data.append('mitraId', basicInfo.mitraId);
    data.append('jobId', basicInfo.jobId);
    data.append('jobDemandId', basicInfo.jobDemandId);
    data.append('jobLocation', basicInfo.jobLocation);
    data.append('dlNumber', basicInfo.dlNumber);
    let url = process.env.REACT_APP_BACKEND_URL + '/postBasicInfoForm';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const postBulkUploadForm = async (bulkCandidateFile, city, category, company, jobId, jobLocation, jobDemandId) => {
    const data = new FormData();
    data.append('file', bulkCandidateFile);
    data.append('city', city);
    data.append('category', category);
    data.append('company', company);
    data.append('jobId', jobId);
    data.append('jobLocation', jobLocation);
    data.append('jobDemandId', jobDemandId);
    let url = process.env.REACT_APP_BACKEND_URL + '/postCandidateForm';
    return axiosInstance.post(url, data, { withCredentials: true });
}

export const getExtraRequiredFields = async (companyName) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/company/extraRequiredFields?companyName=' + companyName;
    return axiosInstance.get(url, { withCredentials: true });
}
export const checkUberUniqueness = async (phoneNumber, city, clientName) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + '/checkUberUniqueness', { withCredentials: true, params: { "phoneNumber": phoneNumber, "city": city, "clientName": clientName } });
}

export const checkZeptoUniqueness = async (phoneNumbers) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/getZeptoLeadStatus', { "phoneNumbers": phoneNumbers }, { withCredentials: true });
}

export const updateZeptoAPIUniqueness = async (jobDemandId, userPhone, uniqueness) => {
    return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/updateZeptoAPIUniqueness', { jobDemandId, userPhone, uniqueness }, { withCredentials: true });
}

export const checkRapidoUniqueness = async (phoneNumber) => {
    return axiosInstance.get(process.env.REACT_APP_BACKEND_URL + '/checkRapidoUniqueness', { withCredentials: true, params: { "phoneNumber": phoneNumber } });
}       // if (key === "verticalPreference") {
export const submitBasicInfoForm = (data, mitra) => {
    const requestData = {
        submission: {
            data: {
                //sourceName: mitra.name,
                sourceName: "mitra-leader",
                sourceId: mitra.id,
                candidatePhoneNumber: data.phoneNumber,
                phoneNumber: data.phoneNumber,
                // verticalPreference: [],
                name: data.name,
                managerMitraID: mitra.managerMitraID || mitra.id
            },
            currentPageData: {
                duplicateLeadCheck: true,
                candidatePhoneNumber: data.phoneNumber,
                // verticalPreference: []
            }
        }
    }

    for (let key in data) {
        // if (key === "submit") {

        // }
        // else 
        if (key === "verticalPreference") {
            for (let vp in data[key])
                if (data[key][vp]) {
                    requestData.submission.currentPageData.verticalPreference.push(vp)
                    requestData.submission.data.verticalPreference.push(vp)
                }
        }
        else if (data[key] && typeof data[key] === "string") {
            requestData.submission.currentPageData[key] = data[key]
            requestData.submission.data[key] = data[key]
        }
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axiosInstance.post(url, requestData, { withCredentials: true });
}

export const getJobDetails = async (params) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/getJobDetails';
    return axiosInstance.get(url, { params, withCredentials: true });
}

export const getBanners = async () => {
    const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`  };
    let url = `${process.env.REACT_APP_STRAPI_URL}/vahan-leader-banners?filters[expiryDate][$gte]=${moment().toISOString()}&sort=priority`;
    return axiosInstance.get(url, { headers });
};

export const saveFeedback = async (rating, feedbackText) => {
    const data = new FormData();
    data.append('rating', rating);
    data.append('feedbackText', feedbackText);
    let url = process.env.REACT_APP_BACKEND_URL + '/saveFeedback';
    return axiosInstance.post(url, data, { withCredentials: true });
};

export const productUpdates = async (params) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/productUpdates';
    return axiosInstance.get(url, { params, withCredentials: true });
}
